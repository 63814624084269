import * as React from 'react';
import { useMsal } from "@azure/msal-react";
import { LocalMenu } from './LocalMenu';
import { MarkdownPage } from "./MarkdownPage";

export const MarkdownContent = (props) => {
    const { instance } = useMsal();

    return (
        <div style={{ display: "block", width: "100%" }}>
            <LocalMenu
                title={""}
                menuItems={false}
                selectedId={false}
                onSelectChange={false}
                onSearchClicked={false}
            />
            <div>
                <MarkdownPage page={props.page} pca={instance} />
            </div>
        </div>
    );
}
