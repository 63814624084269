import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { withStyles } from "@mui/styles";
import TableSortCol from "./TableSortCol";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import Popover from './Popover';

const defaultToolbarStyles = {
    iconButton: {
    },
};

class SortColButton extends React.Component {
    constructor(props) {
        super(props)
    }

    setActiveIcon = iconName => {
        this.setState(() => ({
            showSearch: true,
            iconActive: iconName
        }));
    };

    // TODO Icon and texts
    render() {
        return (
            <React.Fragment>
                <Popover
                    refExit={this.setActiveIcon.bind(null)}
                    trigger={
                        <Tooltip title="Järjestä sarakkeet" disableFocusListener>
                            <IconButton className={this.props.classes.iconButton}
                                onClick={this.setActiveIcon.bind(null, 'viewcolumns')}>
                                <CompareArrowsIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    content={
                        <TableSortCol columns={this.props.columns} onOrderChange={this.props.onOrderChange} />
                    }
                />
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "SortColButton" })(SortColButton);
