// Julkiset kirjastot
import React, { useState, useEffect, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";

// Sovelluksen komponentit
import SortColButton from "./SortColButton";
import ErrorDialog from "./Common/ErrorDialog";
import KoodiAdd from "./KoodiAdd";
import { KoodiEdit } from "./KoodiEdit";

import { LanguageContext } from './LanguageContext';

// Sovelluksen komponentit

// Entiteettikohtaiset vakiot

// Funktio
export const KoodiToolbarSelect = ({ selectedRows, displayData, setSelectedRows, lookupLists, rowId, rowData, handleItemChange, handleCopy, handleDelete }) => {
    const { dictionary } = useContext(LanguageContext);
    //const { instance } = useMsal();

    const [selRows, setSelRows] = useState((selectedRows?.data || []).map(d => d.dataIndex) || []);

    useEffect(() => {
        setSelRows(selRows);
    }, [selectedRows])
    //onChange(selRows)

    return (
        <div className={"custom-toolbar-select"}>
            {((selRows || []).length < 2) ? (
                <React.Fragment>
                    <div className={"custom-toolbar-select"}>
                        <KoodiEdit
                            onEdit={(newrow) => handleItemChange(newrow, "Update")}
                            lookupLists={lookupLists}
                            data={rowData}
                            rowId={rowId}
                        />
                        <Tooltip title={dictionary.Toolbar.Copy}>
                            <IconButton onClick={(e) => handleCopy(selectedRows, displayData, setSelectedRows)}>
                                <FileCopyIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={dictionary.Toolbar.Delete}>
                            <IconButton onClick={(e) => handleDelete(selectedRows, displayData, setSelectedRows)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Tooltip title={dictionary.Toolbar.Copy}>
                            <IconButton onClick={(e) => handleCopy(selectedRows, displayData, setSelectedRows)}>
                            <FileCopyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={dictionary.Toolbar.Delete}>
                            <IconButton onClick={(e) => handleDelete(selectedRows, displayData, setSelectedRows)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            )}
        </div>
    );
}

