import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from "@mui/styles";

import { LanguageContext } from '../LanguageContext';
import LocalizedDatePicker from "../LocalizedDatePicker";

const defaultToolbarStyles = {
    iconButton: {
    },
};

class KulutusAdd extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            RiviAvain: null,
            EmoKulutusAvain: null,
            KustannuspaikkaAvain: null,
            TapahtumaAika: null,
            Tuoteryhma: null,
            Tuotenimi: null,
            Yritys: null,
            Selite: null,
            Maara: null,
            Euro: null,
            CO2: null,
            KulutusTyyppi: null,
            Yksikko: null,
            Created: null,
            Updated: null,
            Username: null,
            kulutukset: (Object.keys(props.lookupLists.kulutukset).map((d) => ({ value: d, label: props.lookupLists.kulutukset[d] })) || []),
            kustannuspaikat: (Object.keys(props.lookupLists.kustannuspaikat).map((d) => ({ value: d, label: props.lookupLists.kustannuspaikat[d] })) || []),
        };
    }

    handleSave = () => {
        this.props.onAddNewRow({
            RiviAvain: 1,
            EmoKulutusAvain: this.state.EmoKulutusAvain,
            KustannuspaikkaAvain: this.state.KustannuspaikkaAvain,
            TapahtumaAika: this.state.TapahtumaAika.format("DD/MM/YYYY 00:00:00"),
            Tuoteryhma: this.state.Tuoteryhma,
            Tuotenimi: this.state.Tuotenimi,
            Yritys: this.state.Yritys,
            Selite: this.state.Selite,
            Maara: this.state.Maara,
            Euro: this.state.Euro,
            CO2: this.state.CO2,
            KulutusTyyppi: this.state.KulutusTyyppi,
            Yksikko: this.state.Yksikko,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
        });
        this.setState({ open: false });
    };

    closeDialog = (list) => {
        var name = 'open' + list;
        this.setState({ [name]: false })
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleListChange = (event, newValue, item) => {
        if (newValue != null) {
            this.setState({ [item]: newValue.value })
        }
        else {
            this.setState({ [item]: null })
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleChangeDate = (name, value) => {
        this.setState({ [name]: value });
    }

    render() {
        const { dictionary } = this.context;
        const { open, kulutukset, kustannuspaikat } = this.state;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.NewRow}>
                    <IconButton onClick={this.handleClickOpen}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.DataIngestion.Kulutus.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <Box style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                onChange={(event, newValue) => this.handleListChange(event, newValue, 'EmoKulutusAvain')}
                                options={kulutukset}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.DataIngestion.Kulutus.Columns[1]} />}
                            />
                        </Box>
                        <Box style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                onChange={(event, newValue) => this.handleListChange(event, newValue, 'KustannuspaikkaAvain')}
                                options={kustannuspaikat}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.DataIngestion.Kulutus.Columns[2]} />}
                            />
                        </Box>
                        <LocalizedDatePicker
                            label={dictionary.DataIngestion.Kulutus.Columns[3]}
                            value={this.state.TapahtumaAika}
                            onChange={(newValue) => this.handleChangeDate('TapahtumaAika', newValue)}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Kulutus.Columns[4]}
                            type="text"
                            fullWidth
                            value={this.state.Tuoteryhma}
                            onChange={this.handleChange('Tuoteryhma')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Kulutus.Columns[5]}
                            type="text"
                            fullWidth
                            value={this.state.Tuotenimi}
                            onChange={this.handleChange('Tuotenimi')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Kulutus.Columns[6]}
                            type="text"
                            fullWidth
                            value={this.state.Yritys}
                            onChange={this.handleChange('Yritys')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Kulutus.Columns[7]}
                            type="text"
                            fullWidth
                            value={this.state.Selite}
                            onChange={this.handleChange('Selite')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Kulutus.Columns[8]}
                            type="text"
                            fullWidth
                            value={this.state.Maara}
                            onChange={this.handleChange('Maara')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Kulutus.Columns[9]}
                            type="text"
                            fullWidth
                            value={this.state.Euro}
                            onChange={this.handleChange('Euro')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Kulutus.Columns[10]}
                            type="text"
                            fullWidth
                            value={this.state.CO2}
                            onChange={this.handleChange('CO2')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Kulutus.Columns[11]}
                            type="text"
                            fullWidth
                            value={this.state.KulutusTyyppi}
                            onChange={this.handleChange('KulutusTyyppi')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Kulutus.Columns[12]}
                            type="text"
                            fullWidth
                            value={this.state.Yksikko}
                            onChange={this.handleChange('Yksikko')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "KulutusAdd" })(KulutusAdd);
