import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from "@mui/styles";

import { LanguageContext } from '../LanguageContext';

const defaultToolbarStyles = {
    iconButton: {
    },
};


class ProjektiAdd extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            RiviAvain: null,
            AsiakasAvain: null,
            Nimi: null,
            Omistaja: null,
            Tarkoitus: null,
            Tavoitteet: null,
            Tyyppi: null,
            Sijainti: null,
            OlosuhteetEnnen: null,
            ParannustenSaavuttaminen: null,
            ArviotuParannustenMaara: null,
            Riskit: null,
            RoolitJaVastuut: null,
            Ohjelmat: null,
            Vaikutusarvio: null,
            Lopputulemat: null,
            Alkupvm: null,
            Loppupvm: null,
            Created: null,
            Updated: null,
            Username: null,
            asiakkaat: (Object.keys(props.lookupLists.asiakkaat).map((d) => ({ value: d, label: props.lookupLists.asiakkaat[d] })) || []),
        };
    }

    handleSave = () => {
        this.props.onAddNewRow({
            RiviAvain: 1,
            AsiakasAvain: this.state.AsiakasAvain,
            Nimi: this.state.Nimi,
            Omistaja: this.state.Omistaja,
            Tarkoitus: this.state.Tarkoitus,
            Tavoitteet: this.state.Tavoitteet,
            Tyyppi: this.state.Tyyppi,
            Sijainti: this.state.Sijainti,
            OlosuhteetEnnen: this.state.OlosuhteetEnnen,
            ParannustenSaavuttaminen: this.state.ParannustenSaavuttaminen,
            ArviotuParannustenMaara: this.state.ArviotuParannustenMaara,
            Riskit: this.state.Riskit,
            RoolitJaVastuut: this.state.RoolitJaVastuut,
            Ohjelmat: this.state.Ohjelmat,
            Vaikutusarvio: this.state.Vaikutusarvio,
            Lopputulemat: this.state.Lopputulemat,
            Alkupvm: this.state.Alkupvm,
            Loppupvm: this.state.Loppupvm,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
        });
        this.setState({ open: false });
    };

    closeDialog = (list) => {
        var name = 'open' + list;
        this.setState({ [name]: false })
    };

    render() {
        const { dictionary } = this.context;
        const { open, asiakkaat } = this.state;

        const handleChange = name => event => {
            this.setState({ [name]: event.target.value });
        };

        var handleClickOpen = () => {
            this.setState({
                open: true,
            });
        }

        const handleListChange = (event, newValue, item) => {
            if (newValue != null) {
                this.setState({ [item]: newValue.value })
            }
            else {
                this.setState({ [item]: null })
            }
        }

        var handleClose = () => {
            this.setState({ open: false });
        }

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.NewRow}>
                    <IconButton onClick={handleClickOpen}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={open} onClose={handleClose} maxWidth={false} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{dictionary.Inventory.Projekti.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <div style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                onChange={(event, newValue) => { handleListChange(event, newValue, 'AsiakasAvain') }}
                                options={asiakkaat}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField  {...params} required variant="standard" label={dictionary.Inventory.Projekti.Columns[1]} />}
                            />
                        </div>
                        <TextField
                            required
                            margin="dense"
                            id="Nimi"
                            label={dictionary.Inventory.Projekti.Columns[2]}
                            type="text"
                            fullWidth
                            value={this.state.Nimi}
                            onChange={handleChange('Nimi')}
                        />
                        <TextField
                            required
                            margin="dense"
                            id="Omistaja"
                            label={dictionary.Inventory.Projekti.Columns[3]}
                            type="text"
                            fullWidth
                            value={this.state.Omistaja}
                            onChange={handleChange('Omistaja')}
                        />
                        <TextField
                            multiline   
                            margin="dense"
                            id="Tarkoitus"
                            label={dictionary.Inventory.Projekti.Columns[4]}
                            type="text"
                            fullWidth
                            value={this.state.Tarkoitus}
                            onChange={handleChange('Tarkoitus')}
                        />
                        <TextField
                            multiline 
                            margin="dense"
                            id="Tavoitteet"
                            label={dictionary.Inventory.Projekti.Columns[5]}
                            type="text"
                            fullWidth
                            value={this.state.Tavoitteet}
                            onChange={handleChange('Tavoitteet')}
                        />
                        <TextField
                            multiline 
                            margin="dense"
                            id="Tyyppi"
                            label={dictionary.Inventory.Projekti.Columns[6]}
                            type="text"
                            fullWidth
                            value={this.state.Tyyppi}
                            onChange={handleChange('Tyyppi')}
                        />
                        <TextField
                            multiline 
                            margin="dense"
                            id="Sijainti"
                            label={dictionary.Inventory.Projekti.Columns[7]}
                            type="text"
                            fullWidth
                            value={this.state.Sijainti}
                            onChange={handleChange('Sijainti')}
                        />
                        <TextField
                            multiline 
                            margin="dense"
                            id="OlosuhteetEnnen"
                            label={dictionary.Inventory.Projekti.Columns[8]}
                            type="text"
                            fullWidth
                            value={this.state.OlosuhteetEnnen}
                            onChange={handleChange('OlosuhteetEnnen')}
                        />
                        <TextField
                            multiline 
                            margin="dense"
                            id="ParannustenSaavuttaminen"
                            label={dictionary.Inventory.Projekti.Columns[9]}
                            type="text"
                            fullWidth
                            value={this.state.ParannustenSaavuttaminen}
                            onChange={handleChange('ParannustenSaavuttaminen')}
                        />
                        <TextField
                            multiline 
                            margin="dense"
                            id="ArviotuParannustenMaara"
                            label={dictionary.Inventory.Projekti.Columns[10]}
                            type="text"
                            fullWidth
                            value={this.state.ArviotuParannustenMaara}
                            onChange={handleChange('ArviotuParannustenMaara')}
                        />
                        <TextField
                            multiline 
                            margin="dense"
                            id="Riskit"
                            label={dictionary.Inventory.Projekti.Columns[11]}
                            type="text"
                            fullWidth
                            value={this.state.Riskit}
                            onChange={handleChange('Riskit')}
                        />
                        <TextField
                            multiline 
                            margin="dense"
                            id="RoolitJaVastuut"
                            label={dictionary.Inventory.Projekti.Columns[12]}
                            type="text"
                            fullWidth
                            value={this.state.RoolitJaVastuut}
                            onChange={handleChange('RoolitJaVastuut')}
                        />
                        <TextField
                            multiline 
                            margin="dense"
                            id="Ohjelmat"
                            label={dictionary.Inventory.Projekti.Columns[13]}
                            type="text"
                            fullWidth
                            value={this.state.Ohjelmat}
                            onChange={handleChange('Ohjelmat')}
                        />
                        <TextField
                            multiline 
                            margin="dense"
                            id="Vaikutusarvio"
                            label={dictionary.Inventory.Projekti.Columns[14]}
                            type="text"
                            fullWidth
                            value={this.state.Vaikutusarvio}
                            onChange={handleChange('Vaikutusarvio')}
                        />
                        <TextField
                            multiline 
                            margin="dense"
                            id="Lopputulemat"
                            label={dictionary.Inventory.Projekti.Columns[15]}
                            type="text"
                            fullWidth
                            value={this.state.Lopputulemat}
                            onChange={handleChange('Lopputulemat')}
                        />
                        <TextField
                            required
                            margin="dense"
                            id="Alkupvm"
                            label={dictionary.Inventory.Projekti.Columns[16]}
                            type="text"
                            fullWidth
                            value={this.state.Alkupvm}
                            onChange={handleChange('Alkupvm')}
                        />
                        <TextField
                            required
                            margin="dense"
                            id="Loppupvm"
                            label={dictionary.Inventory.Projekti.Columns[17]}
                            type="text"
                            fullWidth
                            value={this.state.Loppupvm}
                            onChange={handleChange('Loppupvm')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "ProjektiAdd" })(ProjektiAdd);
