import { authFetch, authPost } from "../../authProvider";
import { Settings } from '.';

/**
 * Formats a string to capitalized format
 * @param {string} str
 */
const capitalize = (str) => (str || "").toString()[0].toUpperCase() + (str || "").slice(1).toLowerCase();

/**
 * Creates API calls
 * @param {string} entity
 * @param {string} action 
 * @param {object} payload 
 * @param {string} queryParameters
 * @param {function} callback (iserror, data)
 */
export const ApiCall = (pca, entity, action, payload, queryParameters, callback) => {
    if (entity && entity.toString().length > 0) {
        let url = "";

        try {
            url = Settings[capitalize(entity)]["ApiUrl" + capitalize(action)] + (queryParameters ? "/" + queryParameters : "");
        }
        catch {
            callback(true, "Invalid parameters.")
        }

        if (payload) {
            authPost(pca, url, {
                body: JSON.stringify(payload)
            })
                .then((response) => response.json())
                .then((data) => {
                    if ((data || {}).error) {
                        this.handleError(true, data.error);
                    } else {
                        callback(false, data);
                    }
                })
                .catch((error) => {
                    callback(true, error)
                });
        } else {
            authFetch(pca, url)
                .then((response) => response.json())
                .then((data) => {
                    if ((data || {}).error) {
                        this.handleError(true, data.error);
                    } else {
                        callback(false, data);
                    }
                })
                .catch((error) => {
                    callback(true, error)
                });
        }
    } else {
        callback(true, "Invalid entity parameter.")
    }  
};