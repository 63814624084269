// Julkiset kirjastot
import React, { useState, useEffect, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LanguageContext } from './LanguageContext';

// Sovelluksen komponentit
import { BuildOptions } from "./Common";

// Entiteettikohtaiset vakiot

// Funktio
export const KoodiAdd = ({ lookupLists, onAdd }) => {
    // Toistuvat muuttujat
    const { dictionary } = useContext(LanguageContext);
    const [rowData, setRowData] = useState({});
    const [open, setOpen] = useState(false);

    // Entiteettikohtainen
    const [koodiryhmat, setKoodiryhmat] = useState(BuildOptions(lookupLists, "koodiryhmat"));

    useEffect(() => {
        setKoodiryhmat(BuildOptions(lookupLists, "koodiryhmat"));
    }, [lookupLists]);

    const handleSave = () => {
        onAdd(rowData);
        setOpen(false);
    }

    const handleChange = name => event => {
        setRowData({
            ...rowData,
            [name]: event.target.value
        });
    };

    const handleListChange = name => (event, newValue) => {
        if (newValue != null) {
            setRowData({
                ...rowData,
                [name]: newValue.value
            });
        }
        else {
            setRowData({
                ...rowData,
                [event.target.id]: null
            });
        }
    }

    return (
        <React.Fragment>
            <Tooltip title={dictionary.Toolbar.NewRow}>
                <IconButton onClick={() => {
                    setRowData({});
                    setOpen(true);
                }}>
                    <AddIcon />
                </IconButton>
            </Tooltip>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{dictionary.Koodi.Title}</DialogTitle>
                <DialogContent style={{ width: "500px" }}>
                    <div style={{ display: 'flex', paddingTop: "10px" }} >
                        <Autocomplete
                            options={koodiryhmat}
                            style={{ width: '100%' }}
                            onChange={handleListChange("koodiryhmaAvain")}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required={true}
                                    variant="standard"
                                    label={dictionary.Koodi.Columns[1]}
                                    placeholder={dictionary.TextBoxLabel}
                                />
                            )}
                        />
                    </div>
                    <TextField
                        margin="dense"
                        label={dictionary.Koodi.Columns[2]}
                        type="text"
                        fullWidth
                        value={rowData.koodiNimi || ""}
                        onChange={handleChange("koodiNimi")}
                    />
                    <TextField
                        margin="dense"
                        label={dictionary.Koodi.Columns[3]}
                        type="text"
                        fullWidth
                        value={rowData.koodiNimiEN || ""}
                        onChange={handleChange("koodiNimiEN")}
                    />
                    <TextField
                        margin="dense"
                        label={dictionary.Koodi.Columns[4]}
                        type="text"
                        fullWidth
                        value={rowData.koodiKuvaus || ""}
                        onChange={handleChange("koodiKuvaus")}
                    />
                    <TextField
                        margin="dense"
                        label={dictionary.Koodi.Columns[5]}
                        type="text"
                        fullWidth
                        value={rowData.koodiKuvausEN || ""}
                        onChange={handleChange("koodiKuvausEN")}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>
                        {dictionary.Close}
                    </Button>
                    <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={handleSave}>
                        {dictionary.Save}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}