// Julkiset kirjastot
import React, { useState, useEffect, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import MUIDataTable from "mui-datatables";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import cloneDeep from 'lodash.clonedeep';

// Sovelluksen komponentit
import { KoodiAdd } from "./KoodiAdd";
import { KoodiToolbarSelect } from "./KoodiToolbar";
import { ErrorDialog, ChangeColumnOrder, ChangeRowNumber, HideColumn, ApiCall, CustomSort, DeleteRows, CopyRows } from "./Common";
import { LanguageContext } from "./LanguageContext";

// Yleiset funktiot
const handleDateFormat = (value, tableMeta, updateValue) => {
    var date = moment(value).format("D.M.YYYY, HH:mm");
    return moment(value).isValid() ? date : "";
}

// Entiteettikohtaiset vakiot 
const THIS_ENTITY = "Koodi";
const THIS_ENTITY_KEY = "koodiAvain";
const READ_ACTION = "Read";
const COPY_TEXT = "-kopio";

// Entiteettikohtaiset funktiot
const getColumns = (dictionary, colsHidden, lookupLists) => {
    const { koodiryhmat } = lookupLists;
    let cols = [
        {
            label: dictionary[THIS_ENTITY].Columns[0],
            name: THIS_ENTITY_KEY,
            options: {
                filter: false,
                display: false,
                draggable: false
            }
        },
        {
            label: dictionary[THIS_ENTITY].Columns[1],
            name: "koodiryhmaAvain",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => koodiryhmat ? koodiryhmat[value] : "",
                display: true,
                lookuplist: 'koodiryhmat'
            }
        },
        {
            label: dictionary[THIS_ENTITY].Columns[2],
            name: "koodiNimi",
            options: {
                filter: true,
                display: true
            }
        },
        {
            label: dictionary[THIS_ENTITY].Columns[3],
            name: "koodiNimiEN",
            options: {
                filter: true,
                display: true
            }
        },
        {
            label: dictionary[THIS_ENTITY].Columns[4],
            name: "koodiKuvaus",
            options: {
                filter: false,
                display: true
            }
        },
        {
            label: dictionary[THIS_ENTITY].Columns[5],
            name: "koodiKuvausEN",
            options: {
                filter: false,
                display: true
            }
        },
        {
            label: dictionary[THIS_ENTITY].Columns[6],
            name: "created",
            options: {
                filter: false,
                display: false,
                customBodyRender: handleDateFormat,
            }
        },
        {
            label: dictionary[THIS_ENTITY].Columns[7],
            name: "updated",
            options: {
                filter: false,
                display: false,
                customBodyRender: handleDateFormat,
            }
        },
        {
            label: dictionary[THIS_ENTITY].Columns[8],
            name: "username",
            options: {
                filter: false,
                display: false
            }
        }
    ];

    for (let i = 0; i < cols.length; i++) {
        cols[i].options.display =
            colsHidden.indexOf(cols[i].name) > -1 ? false : // Is in hidden columns -> hide
                cols[i].options.display === false ? false : // Or is hidden by default -> hide
                    true; // Or otherwise show
    }

    return cols;
}

const defaultOptions = {
    draggableColumns: {
        enabled: true,
        transitionTime: 300
    },
    selectableRowsHeader: true,
    selectableRowsOnClick: true,
    selectableRows: "multiple",
    filterType: 'multiselect',
    responsive: "vertical",
    tableBodyHeight: "calc(100vh - 180px)",
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    jumpToPage: true
}

const getOptions = (dictionary, rowNum, colsOrder, rowsSelected, lookupLists, rowId, rowData, handleHiddenCols,
    handleRowSelectionChange, handleChangeRowNum, handleColumnOrderChange, handleCustomSort, handleItemChange, handleCopy, handleDelete) => {

    return {
        ...defaultOptions,
        textLabels: dictionary.MuiDataTable.textLabels,
        rowsPerPage: rowNum,
        columnOrder: colsOrder.length > 0 ? colsOrder : null,
        rowsSelected: rowsSelected,
        onViewColumnsChange: handleHiddenCols,
        onRowSelectionChange: handleRowSelectionChange,
        onChangeRowsPerPage: handleChangeRowNum,
        onColumnOrderChange: handleColumnOrderChange,
        customSort: handleCustomSort,
        customToolbarSelect: (selRows, displayData, setSelectedRows) => (
            <KoodiToolbarSelect
                selectedRows={selRows}
                displayData={displayData}
                setSelectedRows={setSelectedRows}
                lookupLists={lookupLists}
                rowId={rowId}
                rowData={rowData}
                handleItemChange={handleItemChange}
                handleCopy={handleCopy}
                handleDelete={handleDelete}
            />
        ),
        customToolbar: ({ displayData }) => (
            <KoodiAdd
                onAdd={(newrow) => handleItemChange(newrow, "Create")}
                lookupLists={lookupLists}
            />
        )
    }
}

// Funktio
export const Koodi = (props) => {
    // Toistuvat muuttujat ja funktiot
    const { dictionary } = useContext(LanguageContext);
    const { instance } = useMsal();
    const [tableData, setTableData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rowId, setRowId] = useState(null);
    const [rowData, setRowData] = useState({});
    const [rowsSelected, setRowsSelected] = useState([]);
    const [rowNum, setRowNum] = useState(25);
    const [colsOrder, setColsOrder] = useState([]);
    const [colsHidden, setColsHidden] = useState([]);
    const [error, setError] = useState(null);

    const handleError = (error) => {
        setError(error === 4 ? {
            title: dictionary.ErrorDialog.Error4.title,
            body: dictionary.ErrorDialog.Error4.body
        } : error === 5 ? {
            title: dictionary.ErrorDialog.Error5.title,
            body: dictionary.ErrorDialog.Error5.body
        } : {
            title: dictionary.ErrorDialog.Error.title,
            body: dictionary.ErrorDialog.Error.body
        });
    }

    const handleErrorClose = () => setError(null);

    const handleColumnOrderChange = (newColumnOrder, columnIndex, newPosition) => ChangeColumnOrder(instance, THIS_ENTITY, newColumnOrder, (isError, data) => {
        if (isError) {
            handleError(data);
        } else {
            setColsOrder(data);
        }
    });

    const handleChangeRowNum = (num) => ChangeRowNumber(instance, THIS_ENTITY, num, (isError, data) => {
        if (isError) {
            handleError(data);
        } else {
            setRowNum(data);
        }
    });

    const handleHiddenCols = (changedColumn, action) => HideColumn(instance, THIS_ENTITY, changedColumn, colsHidden, action, (isError, data) => {
        if (isError) {
            handleError(data);
        } else {
            setColsHidden(data);
        }
    });

    const handleRowSelectionChange = (currentRowsSelected, allRows, rowsSelected) => {
        let rowId = null;
        if (tableData) {
            if (rowsSelected.length > 0) {
                rowId = tableData[rowsSelected[0]][THIS_ENTITY_KEY];
                setRowData(tableData[rowsSelected[0]])
            } else {
                setRowData(null)
            }
        }
        setRowId(rowId);
        setRowsSelected(rowsSelected);
    }

    const handleCustomSort = (data, colIndex, order) => CustomSort(data, lookupLists, colIndex, order);

    const handleDelete = (selRows, displayData, setSelectedRows) => DeleteRows(THIS_ENTITY, tableData, THIS_ENTITY_KEY, selRows, setSelectedRows, setRowsSelected, updateEntityData);

    const handleCopy = (selRows, displayData, setSelectedRows) => CopyRows(THIS_ENTITY, tableData, THIS_ENTITY_KEY, selRows, setSelectedRows, setRowsSelected, handleOnCopy, updateEntityData);

    const handleItemChange = (newrow, action) => {
        // Add new item or change item
        ApiCall(instance, THIS_ENTITY, action, newrow, null, (isError, data) => {
            if (isError) {
                handleError(data);
            } else {
                updateEntityData();
            }
        });
    };

    // Entiteettikohtainen
    const [lookupLists, setLookupLists] = useState({ koodiryhmat: {} });

    const handleOnCopy = (row) => {
        return {
            KoodiAvain: 1,
            KoodiryhmaAvain: row.koodiryhmaAvain,
            KoodiNimi: row.koodiNimi + COPY_TEXT,
            KoodiNimiEN: row.koodiNimiEN + COPY_TEXT,
            KoodiKuvaus: row.koodiKuvaus,
            KoodiKuvausEN: row.koodiKuvausEN,
            Created: row.created,
            Updated: row.updated,
            Username: row.username
        }
    };

    const updateEntityData = () => {
        setLoading(true);
        // Read all items
        ApiCall(instance, THIS_ENTITY, READ_ACTION, null, null, (isError, data) => {
            if (isError) {
                handleError(data);
            } else {
                setTableData(data);
                setRowsSelected([]);
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        //TODO: Latauksen siirto ylemmälle tasolle - kun testattu ensin muutaman entiteetin kanssa
        ApiCall(instance, "Profiili", READ_ACTION, null, null, (isError, data) => {
            if (isError) {
                handleError(data);
            } else {
                let settings;
                try {
                    settings = JSON.parse((data || [])[0].uI_Settings) || {};
                } catch {
                    settings = {}
                }

                // columns order
                const col_order = (settings.columns || {})[THIS_ENTITY] || [];
                if (col_order.length > 0) {
                    const cols = JSON.parse(col_order);
                    setColsOrder(cols);
                }

                // hidden cols
                const hidden_cols = (settings.hiddencolumns || {})[THIS_ENTITY] || [];
                if (hidden_cols.length > 0) {
                    const hidden = JSON.parse(hidden_cols);
                    setColsHidden(hidden);
                }

                // rowNum
                const row_num = parseInt((settings.rownum || {})[THIS_ENTITY]);
                if (row_num) {
                    setRowNum(row_num);
                }
            }
        });

        ApiCall(instance, "Koodiryhma", READ_ACTION, null, null, (isError, data) => {
            if (isError) {
                handleError(data);
            } else {
                const o = {};
                (data || []).forEach(d => {
                    o[d["koodiryhmaAvain"]] = (d["koodiryhmaNimi"] || "ei tietoja"); //TODO: enkku
                });
                setLookupLists({
                    ...lookupLists,
                    koodiryhmat: cloneDeep(o)
                });
            }
        })

        updateEntityData();

    }, []);

    // Output
    // dictionary.<komponentin nimi>.Title
    return (
        <div style={{ padding: 10 }}>
            {loading ? (
                <div style={{ width: "100%", height: "100vh" }}>
                    <CircularProgress />
                </div>
            ) : (
                <MUIDataTable
                    title={dictionary.Koodi.Title}
                    data={tableData}
                    columns={getColumns(dictionary, colsHidden, lookupLists)}
                    options={getOptions(dictionary, rowNum, colsOrder, rowsSelected, lookupLists, rowId, rowData, handleHiddenCols,
                        handleRowSelectionChange, handleChangeRowNum, handleColumnOrderChange, handleCustomSort, handleItemChange, handleCopy, handleDelete)}
                />
            )
            }
            <ErrorDialog
                open={(error ? true : false)}
                title={error?.title}
                body={error?.body}
                onClose={handleErrorClose}
            />
        </div>
    );
}