// API Settings

export const Settings = {
    Profiili: {
        ApiUrlRead: 'api/Profiili/Read',
        ApiUrlCreate: 'api/Profiili/Create',
        ApiUrlUpdate: 'api/Profiili/Update',
        ApiUrlDelete: 'api/Profiili/Delete',
    },
    Koodi: {
        ApiUrlRead: 'api/Koodi/Read',
        ApiUrlCreate: 'api/Koodi/Create',
        ApiUrlUpdate: 'api/Koodi/Update',
        ApiUrlDelete: 'api/Koodi/Delete',
    },
    Koodiryhma: {
        ApiUrlRead: 'api/Koodiryhma/Read',
        ApiUrlCreate: 'api/Koodiryhma/Create',
        ApiUrlUpdate: 'api/Koodiryhma/Update',
        ApiUrlDelete: 'api/Koodiryhma/Delete',
    },
}