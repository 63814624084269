import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import AdminProfiiliAdd from "./AdminProfiiliAdd";
import AdminProfiiliEdit from "./AdminProfiiliEdit";
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import cloneDeep from 'lodash.clonedeep';
import SortColButton from "./SortColButton";
import ErrorDialog from './ErrorDialog'
import { authFetch, authPost } from "./../authProvider";
import { LanguageContext } from './LanguageContext';
import moment from 'moment';

/* Näkymäkohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/Profiili/Admin';
const API_ASIAKKAAT_PREFIX = 'api/Asiakas';

var default_columns = [
    {
        label: "ProfiiliId",
        name: "profiiliId",
        options: {
            filter: false,
            display: false
        }
    },
    {
        label: "Organisaatio",
        name: "asiakasAvain",
        options: {
            filter: false,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                return asiakkaat[value];
            }
        },
        lookuplist: 'asiakkaat'
    },
    {
        label: "Käyttäjä",
        name: "username",
        options: {
            filter: false,
            display: true
        }
    },
    {
        label: "Asetukset",
        name: "uI_Settings",
        options: {
            filter: false,
            display: false
        }
    },
    {
        label: "Kieli",
        name: "kieli",
        options: {
            filter: true,
            display: true
        }
    },
    {
        label: "OletusNakyma",
        name: "oletusNakyma",
        options: {
            filter: true,
            display: false
        }
    },
    {
        label: "FullName",
        name: "fullName",
        options: {
            filter: false,
            display: true
        }
    },
    {
        label: "Email",
        name: "email",
        options: {
            filter: false,
            display: true
        }
    },
    {
        label: "AccountType",
        name: "accountType",
        options: {
            filter: true,
            display: false
        }
    },
    {
        label: "ChangePass",
        name: "changePass",
        options: {
            filter: true,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                var date = moment(value).format("D.M.YYYY, HH:mm");
                return moment(value).isValid() ? date : "";
            },
        }
    },
    {
        label: "AccountLocked",
        name: "accountLocked",
        options: {
            filter: true,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                var date = moment(value).format("D.M.YYYY, HH:mm");
                return moment(value).isValid() ? date : "";
            },
        }
    },
    {
        label: "TermsApproved",
        name: "termsApproved",
        options: {
            filter: true,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                var date = moment(value).format("D.M.YYYY, HH:mm");
                return moment(value).isValid() ? date : "";
            },
        }
    },
];

/* Perusmuuttujat ja vakiot */
const PROFIILI_GET = 'api/Profiili/Read';
const PROFIILI_PUT = 'api/Profiili/Update';

var items = [];
var selectedRows = [];
var rowId;
var asiakkaat = {};

/* Luokka */
export class AdminProfiili extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        // State alustukset
        this.state = {
            lookupLists: {
                asiakkaat: asiakkaat
            },
            hidden: [],
            multiple: false,
            loading: true,
            rowNum: 25,
            errorShow: false,
            errorTitle: '',
            errorBody: '',
            rowsSelected: [],
            organisaatio: 2, //Startecon oletuksena
            sortOrder: { name: 'riviAvain', direction: 'asc' },
        };

        //Bindit
        this.swapCols = this.swapCols.bind(this);
        this.addNewItem = this.addNewItem.bind(this);

        //organisaatiot
        authFetch(this.props.pca, API_ASIAKKAAT_PREFIX + '/Read')
            .then(response => response.json())
            .then(data => {
                data.forEach(function (d, i) {
                    asiakkaat[d.riviAvain] = (d.asiakasNimi || 'ei tietoja');
                });
                //Items
                authFetch(this.props.pca, API_PREFIX + '/Read')
                    .then(response => response.json())
                    .then(data => {
                        items = data

                        //Profiili
                        authFetch(this.props.pca, PROFIILI_GET)
                            .then(response => response.json())
                            .then(data => {
                                //columns
                                var cols = (((JSON.parse((data || [])[0].uI_Settings) || {}).columns || {}).profiili || []); // TODO: muuta avain
                                if (cols.length > 0) cols = JSON.parse(cols);
                                else cols = null;

                                // hidden cols
                                var hidden_cols = (((JSON.parse((data || [])[0].uI_Settings) || {}).hiddencolumns || {}).profiili || []); // TODO: muuta avain
                                if (hidden_cols.length > 0) hidden_cols = JSON.parse(hidden_cols);
                                else hidden_cols = null;

                                // rowNum
                                var rowNum = (((JSON.parse((data || [])[0].uI_Settings) || {}).rownum || {}).profiili || []); // TODO: muuta avain
                                if (rowNum.length > 0) rowNum = JSON.parse(rowNum);
                                else rowNum = 25;

                                var columns = get_ordered_columns(cols, hidden_cols);

                                this.setState({
                                    lookupLists: {
                                        asiakkaat: asiakkaat
                                    },
                                    columns: columns,
                                    data: items,
                                    loading: false,
                                    rowNum: rowNum
                                });

                            });
                    });
            });
    }

    swapCols = (index) => {
        if (index > 1) {
            var cols = cloneDeep(this.state.columns);
            var d = this.state.data;
            [cols[index - 1], cols[index]] = [cols[index], cols[index - 1]];
            this.setState({ columns: cols });
            var setting = { "key": "columns.profiili", "value": JSON.stringify(cols.map(function (d) { return d.name; })).split('"').join('""') };
            authPost(this.props.pca, PROFIILI_PUT, {
                body: JSON.stringify(setting)
            });
        }
    };

    changeRownum = (num) => {
        this.setState({ rowNum: num })

        var setting = { "key": "rownum.profiili", "value": JSON.stringify(num).split('"').join('""') };
        authPost(this.props.pca, PROFIILI_PUT, {
            body: JSON.stringify(setting)
        })
    };

    hiddenCols = (changedColumn, action) => {
        var cols = cloneDeep(this.state.columns);
        var hidden = [];

        for (var i = 0; i < cols.length; i++) {
            if (!(cols[i].options))
                cols[i].options = {};

            if (cols[i].name == changedColumn)
                cols[i].options.display = (action === "add" ? true : false)

            if (cols[i].options.display == false) {
                hidden.push(cols[i].name);
            }
        }

        if (action === "add") {
            hidden.splice(hidden.indexOf(changedColumn), 1);
        }
        else if (action === "remove") {
            hidden.push(changedColumn);
        }

        this.setState({ columns: cols });
        var setting = { "key": "hiddencolumns.profiili", "value": (hidden.length > 0 ? JSON.stringify(hidden).split('"').join('""') : "[]") }; // TODO: muuta avain
        authPost(this.props.pca, PROFIILI_PUT, {
            body: JSON.stringify(setting)
        })
    };

    handleError = (error) => {
        const { onError } = this.props;

        var title = '', body = ''
        if (error === 4) {
            title = this.context.dictionary.ErrorDialog.Error4.title;
            body = this.context.dictionary.ErrorDialog.Error4.body;
        } else if (error === 5) {
            title = this.context.dictionary.ErrorDialog.Error5.title;
            body = this.context.dictionary.ErrorDialog.Error5.body;
        } else {
            title = this.context.dictionary.ErrorDialog.Error.title;
            body = this.context.dictionary.ErrorDialog.Error.body;
        }

        onError(title, body);
    }

    handleErrorClose = () => this.setState({ errorShow: false });

    addNewItem = (newrow) => {
        // Add profile
        authPost(this.props.pca, API_PREFIX + '/Create', {
            body: JSON.stringify(newrow)
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    console.log("Profile created.");
                    authFetch(this.props.pca, API_PREFIX + '/Read')
                        .then(response => response.json())
                        .then(data => {
                            this.setState({ data: data, rowsSelected: [] })
                            this.handleMessage(this.context.dictionary.Message.Add)
                        });
                }
            });
    }

    editItem = (newrow) => {
        // EDIT
        authPost(this.props.pca, API_PREFIX + '/Update', {
            body: JSON.stringify(newrow)
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    console.log("Profile updated.");
                    authFetch(this.props.pca, API_PREFIX + '/Read')
                        .then(response => response.json())
                        .then(data => {
                            this.setState({ data: data, rowsSelected: [] })
                            this.handleMessage(this.context.dictionary.Message.Edit)
                        });
                }
            });
    }

    //linkItems = (newrow) => {
    //    const { organisaatio, data, rowsSelected } = this.state;

    //    // EDIT
    //    this.updateProfile({
    //        org: organisaatio,
    //        users: rowsSelected.map(d => data[d].username).join(","),
    //        newOrg: newrow.newOrg
    //    });
    //}

    updateProfile = (obj) => {
        const { organisaatio } = this.state;

        authPost(this.props.pca, API_PREFIX + "/Set", {
            body: JSON.stringify(obj)
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    console.log("Profile changed.");
                    authFetch(this.props.pca, API_PREFIX + '/Read')
                        .then(response => response.json())
                        .then(data => {
                            this.setState({ data: data, rowsSelected: [] })
                        });
                }
            });
    }

    handleMessage = (msg) => {
        const { onMessage } = this.props;
        onMessage(msg);
    }

    handleSortChange = (changedColumn, direction) => {
        this.setState({
            sortOrder: { name: changedColumn, direction },
        });
    };

    render() {
        const { userLanguage, dictionary } = this.context;
        const { errorShow, errorTitle, errorBody, organisaatio } = this.state;

        const cols = cloneDeep(this.state.columns);
        if ((cols || []).length > 0) {
            for (let i = 0; i < default_columns.length; i++) {
                for (let j = 0; j < cols.length; j++) {
                    if (cols[j].label === default_columns[i].label) {
                        cols[j].label = dictionary.AdminProfiili.Columns[i]
                    }
                }
            }
        }

        const options = {
            textLabels: dictionary.MuiDataTable.textLabels,
            selectableRowsHeader: true,
            selectableRowsOnClick: true,
            selectableRows: "multiple",
            filterType: 'multiselect',
            responsive: "vertical",
            tableBodyHeight: "calc(100vh - 270px)",
            rowsPerPageOptions: [5, 10, 25, 50, 100],
            rowsPerPage: this.state.rowNum,
            jumpToPage: true,
            rowsSelected: this.state.rowsSelected,
            sortOrder: this.state.sortOrder,
            onColumnSortChange: this.handleSortChange,
            onViewColumnsChange: (changedColumn, action) => {
                this.hiddenCols(changedColumn, action);
            },
            onRowSelectionChange: (currentRowsSelected, allRows, rowsSelected) => {
                const { data } = this.state;
                rowId = null;
                if (rowsSelected.length > 0) {
                    rowId = data[rowsSelected[0]]?.profiiliId; //TODO: Muuta avain
                }
                this.setState({ rowsSelected: rowsSelected });
            },
            onChangeRowsPerPage: (num) => {
                this.changeRownum(num);
            },
            // Huomioi samat arvot ja sen jölkeen lajitellaan ensimmäisen sarakkeen (id) mukaan
            customSort: (data, colIndex, order) => {
                const { columns, lookupLists } = this.state;

                return data.sort((a, b) => {
                    if (a.data[colIndex] == b.data[colIndex]) {
                        // samanarvoisissa riveissä otetaan riviavainjärjestys
                        return ((a.data[0] || -9e6) < (b.data[0] || -9e6) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    } else if (columns[colIndex].options.lookuplist) {
                        // lookuplist lajittelu
                        const list = lookupLists[columns[colIndex].options.lookuplist];
                        var aa = (list[a.data[colIndex]] || ''), bb = (list[b.data[colIndex]] || '');

                        return ((parseInt(!/[^0-9]/i.test(aa) ? aa : 'x') || aa.toString()) < (parseInt(!/[^0-9]/i.test(bb) ? bb : 'x') || bb.toString()) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    } else {
                        // normaali lajittelu
                        return ((a.data[colIndex] || -9e6) < (b.data[colIndex] || -9e6) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    }
                });
            },
            customToolbarSelect: (selRows, displayData, setSelectedRows) => {
                const { data } = this.state;

                if ((selRows.data || []).length > 0) {
                    selectedRows = selRows.data.map(d => (d.dataIndex));
                    rowId = data[selectedRows[0]]?.profiiliId; //TODO: Muuta avain
                }

                var handleDelete = () => {
                    var data = cloneDeep(this.state.data);

                    if (window.confirm(dictionary.Toolbar.ConfirmationProfile + " " + selectedRows.map(d => data[d].username).join(", "))) { //TODO: Muuta avain
                        var requests = []
                        for (var i = 0; i < selectedRows.length; i++) {
                            requests.push(
                                authPost(this.props.pca, API_PREFIX + '/Delete/' + data[selectedRows[i]].profiiliId, { method: 'delete' }) //TODO: Muuta avain
                            );
                        }
                        Promise.all(requests)
                            .then(() => {
                                setSelectedRows([]);
                                authFetch(this.props.pca, API_PREFIX + '/Read')
                                    .then(response => response.json())
                                    .then(data => {
                                        this.handleMessage(`(${selectedRows.length}) ${dictionary.Message.Delete}`)
                                        this.setState({ data: data, rowsSelected: [] });
                                    });
                            });
                    }
                }

                //var handleDeleteAccount = () => {
                //    var data = cloneDeep(this.state.data);

                //    if (window.confirm(dictionary.Toolbar.ConfirmationAccount + " " + selectedRows.map(d => data[d].username).join(", "))) { //TODO: Muuta avain
                //        var requests = []
                //        for (var i = 0; i < selectedRows.length; i++) {
                //            requests.push(
                //                authPost(this.props.pca, API_USER_PREFIX + '/Delete/' + data[selectedRows[i]].username, { method: 'delete' }) //TODO: Muuta avain
                //            );
                //        }
                //        Promise.all(requests)
                //            .then(() => {
                //                setSelectedRows([]);
                //                authFetch(this.props.pca, API_PREFIX + '/Read' + (organisaatio ? "/" + organisaatio : ""))
                //                    .then(response => response.json())
                //                    .then(data => {
                //                        this.setState({ data: data, rowsSelected: [] })
                //                    });
                //            });
                //    }
                //}

                //const profiilit = selectedRows && data ? selectedRows.map(d => data[d]?.username).join(",") : null;

                //const handleViewM = () => {
                //    this.updateProfile({
                //        org: organisaatio,
                //        users: profiilit,
                //        view: "M"
                //    });
                //}

                //const handleViewV = () => {
                //    this.updateProfile({
                //        org: organisaatio,
                //        users: profiilit,
                //        view: "V"
                //    });
                //}

                //const handleTermsReset = () => {
                //    this.updateProfile({
                //        org: organisaatio,
                //        users: profiilit,
                //        terms: 1
                //    });
                //}

                //const handlePasswordChange = () => {
                //    authPost(this.props.pca, API_USER_PREFIX + "/ForcePasswordChange", {
                //        body: JSON.stringify({ users: profiilit })
                //    })
                //        .then(response => response.json())
                //        .then(data => {
                //            if ((data || {}).error) {
                //                this.handleError(data.error);
                //            } else {
                //                this.updateProfile({
                //                    org: organisaatio,
                //                    users: profiilit,
                //                    pass: 1
                //                });
                //            }
                //        });
                //}

                //const handleLock = () => {
                //    authPost(this.props.pca, API_USER_PREFIX + "/LockUsers", {
                //        body: JSON.stringify({ users: profiilit })
                //    })
                //        .then(response => response.json())
                //        .then(data => {
                //            if ((data || {}).error) {
                //                this.handleError(data.error);
                //            } else {
                //                this.updateProfile({
                //                    org: organisaatio,
                //                    users: profiilit,
                //                    locking: 1
                //                });
                //            }
                //        });
                //}

                //const handleOpenLock = () => {
                //    authPost(this.props.pca, API_USER_PREFIX + "/UnlockUsers", {
                //        body: JSON.stringify({ users: profiilit })
                //    })
                //        .then(response => response.json())
                //        .then(data => {
                //            if ((data || {}).error) {
                //                this.handleError(data.error);
                //            } else {
                //                this.updateProfile({
                //                    org: organisaatio,
                //                    users: profiilit,
                //                    unlock: 1
                //                });
                //            }
                //        });
                //}

                return (
                    <div className={"custom-toolbar-select"}>
                        <React.Fragment>
                            {(selectedRows.length < 2) ? (<AdminProfiiliEdit onEditRow={this.editItem} lookupLists={this.state.lookupLists} Daatta={this.state.data} rowId={rowId} />) : null}
                            {/*{(selectedRows.filter(d => data[d]?.oletusNakyma == "M").length > 0) ? null : (*/}
                            {/*    <Tooltip title={dictionary.AdminProfiili.ViewM}>*/}
                            {/*        <IconButton onClick={handleViewM}>*/}
                            {/*            <IconMarketInfoFI />*/}
                            {/*        </IconButton>*/}
                            {/*    </Tooltip>*/}
                            {/*)}*/}
                            {/*{(selectedRows.filter(d => data[d]?.oletusNakyma == "V").length > 0) ? null : (*/}
                            {/*    <Tooltip title={dictionary.AdminProfiili.ViewV}>*/}
                            {/*        <IconButton onClick={handleViewV}>*/}
                            {/*            <IconBenchmarkingFI />*/}
                            {/*        </IconButton>*/}
                            {/*    </Tooltip>*/}
                            {/*)}*/}
                            {/*{(selectedRows.filter(d => !data[d]?.termsApproved).length > 0) ? null : (*/}
                            {/*    <Tooltip title={dictionary.AdminProfiili.TermsReset}>*/}
                            {/*        <IconButton onClick={handleTermsReset}>*/}
                            {/*            <AssignmentLateIcon />*/}
                            {/*        </IconButton>*/}
                            {/*    </Tooltip>*/}
                            {/*)}*/}
                            {/*{(selectedRows.filter(d => (data[d]?.accountType || '').toLowerCase() != "azuread").length > 0) ? null : (*/}
                            {/*    <React.Fragment>*/}
                            {/*        <Tooltip title={dictionary.AdminProfiili.PasswordChange}>*/}
                            {/*            <IconButton onClick={handlePasswordChange}>*/}
                            {/*                <PasswordIcon />*/}
                            {/*            </IconButton>*/}
                            {/*        </Tooltip>*/}
                            {/*        {(selectedRows.filter(d => data[d]?.accountLocked).length > 0) ? null : (*/}
                            {/*            <Tooltip title={dictionary.AdminProfiili.Lock}>*/}
                            {/*                <IconButton onClick={handleLock}>*/}
                            {/*                    <LockIcon />*/}
                            {/*                </IconButton>*/}
                            {/*            </Tooltip>*/}
                            {/*        )}*/}
                            {/*        {(selectedRows.filter(d => !data[d]?.accountLocked).length > 0) ? null : (*/}
                            {/*            <Tooltip title={dictionary.AdminProfiili.OpenLock}>*/}
                            {/*                <IconButton onClick={handleOpenLock}>*/}
                            {/*                    <LockOpenIcon />*/}
                            {/*                </IconButton>*/}
                            {/*            </Tooltip>*/}
                            {/*        )}*/}
                            {/*    </React.Fragment>*/}
                            {/*)}*/}
                            {/*<AdminProfiiliLink lookupLists={this.state.lookupLists} onAddLink={this.linkItems} profiilit={profiilit}  />*/}
                            <Tooltip title={dictionary.AdminProfiili.DeleteProfile}>
                                <IconButton onClick={handleDelete}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            {/*<Tooltip title={dictionary.AdminProfiili.DeleteAccount}>*/}
                            {/*    <IconButton onClick={handleDeleteAccount}>*/}
                            {/*        <PersonRemoveIcon />*/}
                            {/*    </IconButton>*/}
                            {/*</Tooltip>*/}
                        </React.Fragment>
                    </div>
                );
            },
            customToolbar: () => {
                return (
                    <React.Fragment>
                        <AdminProfiiliAdd onAddNewRow={this.addNewItem} Daatta={this.state.data} lookupLists={this.state.lookupLists} pca={this.props.pca} />
                        <SortColButton columns={cols} onOrderChange={this.swapCols} />
                    </React.Fragment>
                );
            }
        };

        //dictionary.<komponentin nimi>.Title
        return (
            <div style={{ padding: 10 }}>
                <div>
                    {(this.state.loading) ? (
                        <CircularProgress />
                    ) : (
                        <React.Fragment>
                            {/*<div style={{ paddingLeft: '15px', paddingBottom: '10px' }}>*/}
                            {/*    <form>*/}
                            {/*        <InputLabel>{dictionary.AdminProfiili.Columns[1]}</InputLabel>*/}
                            {/*        <Select style={{ width: '500px' }} value={this.state.organisaatio} onChange={handleChange}>*/}
                            {/*            {organisaatiot}*/}
                            {/*        </Select>*/}
                            {/*    </form>*/}
                            {/*</div>*/}

                            <MUIDataTable
                                title={dictionary.AdminProfiili.Title}
                                data={this.state.data}
                                columns={cols}
                                options={options}
                            />
                        </React.Fragment>
                    )
                    }
                </div>
                
            </div>
        );
    }
}

function get_ordered_columns(list, hidden) {
    let columns = [];

    if (!Array.isArray(list)) {
        list = default_columns.map((c) => (c.name));
    }

    list.forEach(function (el) {
        columns.push((default_columns.filter(function (d) { return d.name == el; }) || [])[0]);
        if (hidden != null) {
            if (!(columns[columns.length - 1].options))
                columns[columns.length - 1].options = {};
            if (hidden.indexOf(columns[columns.length - 1].name) > -1) {
                columns[columns.length - 1].options.display = false;
            } else {
                columns[columns.length - 1].options.display = true;
            }
        }
    });

    return columns;
}