import React from "react";

const IconArrowUp = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            version="1.1"
            viewBox="0 0 30 30"
        >
            <g>
                <polygon points="26.9,19.8 30,19.8 30,0 10.2,0 10.2,3.1 23.4,3.1 24.7,3.1 23.8,4 0,27.8 2.2,30 26,6.2 26.9,5.3 26.9,6.6 " />
            </g>
        </svg>
    );
};

export default IconArrowUp;