import * as React from 'react';
import { useMsal } from "@azure/msal-react";
import { LanguageContext } from './LanguageContext';
import { useNavigate } from "react-router-dom";
import { LocalMenu } from './LocalMenu';
import { MarkdownPage } from "./MarkdownPage";
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { authFetch, logOut } from "./../authProvider";

export const Terms = (props) => {
    const { instance } = useMsal();
    const navigate = useNavigate();
    const context = React.useContext(LanguageContext);
    const page = "/terms/index.md"

    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        authFetch(instance, 'api/Profiili/Terms/0')
            .then(() => {
                setOpen(false);
                navigate(-1);
                logOut(instance);
            });
    };

    const handleApproval = () => {
        authFetch(instance, 'api/Profiili/Terms/1')
            .then(() => {
                setOpen(false);
                navigate(-1);
            });
    };

    return (
        <div style={{ display: "block", width: "100%" }}>
            <LocalMenu
                title={""}
                menuItems={false}
                selectedId={false}
                onSelectChange={false}
                onSearchClicked={false}
            />
            <div>
                <Dialog
                    open={open}
                    scroll="body"
                    maxWidth="md"
                    fullWidth
                >
                    <DialogContent>
                        <MarkdownPage page={page} pca={instance} />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={handleClose}>
                            {context.dictionary.Terms.Disagree}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={handleApproval}>
                            {context.dictionary.Terms.Agree}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}
