import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { LanguageContext } from '../LanguageContext';
import Yrityshaku from "./Yrityshaku";

export default class TilitapahtumaAdd extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            openCompanySearch: false,
            luokittelu: -1,
            toimialaId: null,
            toimiala: null,
            yritysId: null,
            yritys: null,
            toimialat: (Object.keys(props.lookupLists.toimialat).map((d) => ({ value: d, label: props.lookupLists.toimialat[d] })) || [])
        };
    }

    handleSave = () => {
        const { onSave, selected } = this.props;
        const { luokittelu, toimialaId, yritysId } = this.state;

        if (luokittelu === 1 && yritysId) {
            onSave(selected, 1, parseInt(yritysId));
        } else if (luokittelu === 2 && toimialaId) {
            onSave(selected, 2, parseInt(toimialaId));
        }

        this.setState({ open: false });
    };

    closeDialog = (list) => {
        var name = 'open' + list;
        this.setState({ [name]: false })
    };

    handleCompanySelect = (yritys) => {
        //TODO: Luokittelu yritykselle
        this.setState({
            yritysId: yritys.riviAvain,
            yritys: yritys.nimi,
            openCompanySearch: false,
        })
    }

    handleCompanySearchClose = (event) => {
        this.setState({ openCompanySearch: false });
    }

    handleCompanySearchOpen = (event) => {
        this.setState({ openCompanySearch: true });
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleListChange = (event, newValue, item) => {
        if (newValue != null) {
            this.setState({ [item]: newValue.value })
        }
        else {
            this.setState({ [item]: null })
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { dictionary } = this.context;
        const { open, luokittelu, toimialat, openCompanySearch, toimialaId, yritysId, yritys } = this.state;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.DataIngestion.Luokitteluhaku.Title}>
                    <IconButton onClick={this.handleClickOpen}>
                        <LocalOfferIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.DataIngestion.Luokitteluhaku.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <Box style={{ display: "flex", padding: 8 }}>
                            <Select
                                value={luokittelu}
                                fullWidth
                                label="Hanke"
                                onChange={this.handleChange("luokittelu")}
                                style={{ color: "#000" }}
                            >
                                {dictionary.DataIngestion.Luokitteluhaku.Columns.map((d, i) => (<MenuItem key={d} value={(i === 0 ? -1 : i)}>{d}</MenuItem>))}
                            </Select>
                        </Box>

                        {/* YRITYSHAKU */}
                        {luokittelu === 1 && (
                            <React.Fragment>
                                <Box style={{ display: 'flex', padding: 8 }} >
                                    {dictionary.DataIngestion.Luokitteluhaku.CompanyBody}
                                </Box>
                                <Box style={{ display: 'flex', alignItems: "center", padding: 8 }} >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={this.handleCompanySearchOpen}
                                        style={{ heioght: 36, marginRight: 8 }}
                                    >
                                        {dictionary.DataIngestion.Yrityshaku.Title}
                                    </Button>
                                    <TextField
                                        margin="dense"
                                        id="Yritys"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        required
                                        label={dictionary.DataIngestion.Luokitteluhaku.Columns[1]}
                                        type="text"
                                        fullWidth
                                        value={yritys}
                                    />
                                </Box>
                            </React.Fragment>
                        )}

                        {/* TOIMIALAHAKU */}
                        {luokittelu === 2 && (
                            <React.Fragment>
                                <Box style={{ display: 'flex', padding: 8 }} >
                                    {dictionary.DataIngestion.Luokitteluhaku.SectorBody}
                                </Box>
                                <Box style={{ display: 'flex', padding: 8 }} >
                                    <Autocomplete
                                        onChange={(event, newValue) => this.handleListChange(event, newValue, 'toimialaId')}
                                        options={toimialat}
                                        style={{ width: 400 }}
                                        renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.DataIngestion.Luokitteluhaku.Columns[2]} />}
                                    />
                                </Box>
                            </React.Fragment>
                        )}

                        {/* TUORYHMÄHAKU */}
                        {luokittelu === 3 && (
                            <Box style={{ display: 'flex', alignItems: "center" }} >
                                {dictionary.DataIngestion.Luokitteluhaku.Columns[3]}
                            </Box>
                        )}

                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave} disabled={!((luokittelu === 1 && yritysId) || (luokittelu === 2 && toimialaId))}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Yrityshaku
                    open={openCompanySearch}
                    pca={this.props.pca}
                    onSelected={this.handleCompanySelect}
                    onClose={this.handleCompanySearchClose}
                />
            </React.Fragment>
        );
    }
}
