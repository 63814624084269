import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import TiedostoAdd from "./TiedostoAdd";
import TiedostoEdit from "./TiedostoEdit";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import cloneDeep from 'lodash.clonedeep';
import SortColButton from "./SortColButton";
import ErrorDialog from './ErrorDialog'
import { LocalMenu } from './LocalMenu';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IconPowerPoint from './Icons/IconPowerPoint';
import IconWord from './Icons/IconWord';
import IconExcel from './Icons/IconExcel';
import IconPDF from './Icons/IconPDF';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { FeatureTrim } from './FeatureTrim';

import { authFetch, authPost, authPostFile } from "./../authProvider";
import { LanguageContext } from './LanguageContext';

/* Näkymäkohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/Tiedosto';
const API_LIITE_PREFIX = 'api/Tiedosto/Liite';
const API_KOODIT_PREFIX = 'api/Koodi';

const getIcon = (name) => {
    return (
        name == "excel" ? IconExcel :
            name == "word" ? IconWord :
                name == "powerpoint" ? IconPowerPoint :
                    name == "pdf" ? IconPDF :
                        UploadFileIcon
    );
}

const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

const getLiite = (id, pca) => {
    authFetch(pca, API_LIITE_PREFIX + '/Read/' + id)
        .then(response => response.json())
        .then(data => {
            if ((data || {}).error) {
                console.error(data.message);
            } else {
                const blob = b64toBlob(data.blobdata, data.contentType);
                const blobUrl = URL.createObjectURL(blob);

                downloadLiite(data.blobname, blobUrl);

                //window.open(blobUrl, "_blank");
            }
        });
}

const downloadLiite = (name, url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

var koodit = {};
var tiedostotyypit = {};

// Query parameters
const searchParams = new URLSearchParams(document.location.search);

/* Luokka */
export class TiedostoLataus extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        // State alustukset
        this.state = {
            open: (props.download ? false : searchParams.get('download') == 1 ? false : true),
            loading: true,
            lookupLists: {
                koodit: koodit,
                tiedostotyypit: tiedostotyypit,
            },
            errorShow: false,
            errorTitle: '',
            errorBody: '',
            selectedId: null,
            lang: "fi",
            id: props.id || searchParams.get('id'),
        };

        //koodit
        authFetch(this.props.pca, API_KOODIT_PREFIX + '/Read')
            .then(response => response.json())
            .then(data => {
                data.forEach(function (d, i) {
                    let nimi = d.koodiNimi; //TODO: fi/en
                    koodit[d.koodiAvain] = (nimi || 'ei tietoja');

                    /*
                    * TODO: Kovakoodatut arvot
                        Id	Koodiryhma
                        3   Tietopalvelu
                        6	Aihe
                        7	Tiedostotyyppi
                    */

                    if (d.koodiryhmaAvain == 7) tiedostotyypit[d.koodiAvain] = koodit[d.koodiAvain];

                });

                authFetch(this.props.pca, API_PREFIX + '/Get/' + this.state.id)
                    .then(response => response.json())
                    .then(data => {
                        if (this.state.open) {
                            this.setState({
                                lookupLists: {
                                    koodit: koodit,
                                    tiedostotyypit: tiedostotyypit,
                                },
                                data: data,
                                loading: false,
                            });
                        } else {
                            if (((data || [])[0] || {}).liiteId) {
                                getLiite(data[0].liiteId, this.props.pca);
                            }
                        }
                    });
            });
    }

    handleLogEvent = (e, target, action, info) => {
        if (e) e.preventDefault();

        authPost(this.props.pca, 'api/EventLog/Create', {
            body: JSON.stringify({
                target: target,
                action: action,
                info: info
            })
        })
    }

    handleError = (error) => {
        var title = '', body = ''
        if (error == 4) {
            title = this.context.dictionary.ErrorDialog.Error4.title;
            body = this.context.dictionary.ErrorDialog.Error4.body;
        } else if (error == 5) {
            title = this.context.dictionary.ErrorDialog.Error5.title;
            body = this.context.dictionary.ErrorDialog.Error5.body;
        } else {
            title = this.context.dictionary.ErrorDialog.Error.title;
            body = this.context.dictionary.ErrorDialog.Error.body;
        }
        this.setState({
            errorShow: true,
            errorTitle: title,
            errorBody: body
        })
    }

    handleErrorClose = () => this.setState({ errorShow: false });

    render() {
        const { userLanguage, dictionary } = this.context;
        const { errorShow, errorTitle, errorBody, selectedId, features } = this.state;
        const fil = (this.state.data || [])[0] || {};
        const Icon = getIcon((tiedostotyypit[fil.tiedostotyyppiAvain] || '').toLowerCase());

        const handleClose = () => {
            this.setState({ open: false });
        }

        const handleDownload = () => {
            getLiite(fil.liiteId, this.props.pca);
            this.setState({ open: false });
        }

        return (
            <React.Fragment>
                <Dialog
                    open={this.state.open}
                    onClose={handleClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>{dictionary.Tiedosto.Load.Title}</DialogTitle>
                    <DialogContent>
                        {(this.state.loading) ? (
                            <div style={{ width: "100%", height: "100%" }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <div style={{ display: "flex" }}>
                                <div style={{ fill: "#fff" }}>
                                    <Icon style={{ color: "#fff", fontSize: 24 }} />
                                </div>
                                <Typography style={{ marginLeft: 8, }} variant="body1" component="div">
                                    {fil.tiedostoNimi}
                                </Typography>
                            </div>
                        )
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="secondary" style={{ color: "#fff" }} onClick={handleDownload}>
                            {dictionary.Download}
                        </Button>
                    </DialogActions>
                </Dialog>
                <ErrorDialog open={errorShow} title={errorTitle} body={errorBody} onClose={this.handleErrorClose} />
            </React.Fragment >
        );
    }
}