import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from "@mui/styles";
import Autocomplete from '@mui/material/Autocomplete';
import { LanguageContext } from './LanguageContext';

const defaultToolbarStyles = {
    iconButton: {
    },
};

var iniRooli;
var iniKayttaja;

class AdminRoolitEdit extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var data = props.Daatta.filter(function (d) {
            return d.roolitId == props.rowId;
        });

        this.state = {
            open: false,
            RoolitId: data[0].roolitId,
            AsiakasAvain: data[0].asiakasAvain,
            Rooli_Id: data[0].rooli_Id,
            Profiili_Id: data[0].profiili_Id,
            Created: data[0].created,
            Updated: data[0].updated,
            Username: data[0].username,
            Active: data[0].active,
            roolit: (Object.keys(props.lookupLists.roolit).map(function (d) { return { value: d, label: props.lookupLists.roolit[d] }; }) || []),
            kayttajat: (Object.keys(props.lookupLists.kayttajat).map(function (d) { return { value: d, label: props.lookupLists.kayttajat[d] }; }) || []),
        };

    }

    UNSAFE_componentWillMount() {
        var r = this.state.Rooli_Id || '';
        var k = this.state.Profiili_Id || '';
        iniRooli = this.state.roolit.find(({ value }) => value === r.toString());
        iniKayttaja = this.state.kayttajat.find(({ value }) => value === k.toString());
    }

    handleSave = () => {
        this.props.onEditRow({
            RoolitId: this.state.RoolitId,
            AsiakasAvain: this.state.AsiakasAvain,
            Rooli_Id: this.state.Rooli_Id,
            Profiili_Id: this.state.Profiili_Id,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
            Active: this.state.Active
        });
        this.setState({ open: false });
    }

    closeDialog = (list) => {
        var name = 'open' + list;
        this.setState({ [name]: false })
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleListChange = (event, newValue, item) => {
        if (newValue != null) {
            this.setState({ [item]: newValue.value })
        }
        else {
            this.setState({ [item]: null })
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { dictionary } = this.context;
        const { roolit, kayttajat } = this.state;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.Edit}>
                    <IconButton onClick={this.handleClickOpen}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.AdminRoolit.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <Autocomplete
                            options={kayttajat}
                            defaultValue={iniKayttaja}
                            getOptionLabel={(option) => option.label}
                            style={{ width: 400 }}
                            onChange={(event, newValue) => this.handleListChange(event, newValue, 'Profiili_Id')}
                            renderInput={(params) => (
                                <TextField {...params} required={true} variant="standard" label={dictionary.AdminRoolit.Columns[3]} placeholder={dictionary.TextBoxLabel} />
                            )}
                        />
                        <Autocomplete
                            options={roolit}
                            defaultValue={iniRooli}
                            getOptionLabel={(option) => option.label}
                            style={{ width: 400 }}
                            onChange={(event, newValue) => this.handleListChange(event, newValue, 'Rooli_Id')}
                            renderInput={(params) => (
                                <TextField {...params} required={true} variant="standard" label={dictionary.AdminRoolit.Columns[2]} placeholder={dictionary.TextBoxLabel} />
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "AdminRoolitEdit" })(AdminRoolitEdit);
