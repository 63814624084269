import React from "react";

const IconPowerPoint = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            version="1.1"
            viewBox="0 0 24 24"
        >
            <g>
				<path style={{ fill:"#FFFFFF" }} d="M9.5,8.9c0,0.3,0,0.6-0.1,1c-0.1,0.6-0.5,1.1-1,1.5c-0.3,0.2-0.5,0.4-0.8,0.5C7.2,12,7,12.1,6.7,12.1 l-0.9,0.1V15l-2.2-0.3V6.2l1.5-0.1C5.6,6,6.1,6,6.7,6C7.4,6,8.2,6.2,8.8,6.8C9.3,7.4,9.5,8.2,9.5,8.9L9.5,8.9z M10.2,3.6H20v13.8 h-9.8L10.2,3.6z"/>
				<g>
					<path style={{ fill:"#D35230" }} d="M13.5,1.5C19.3,1.5,24,6.2,24,12c0,0,0,0,0,0c0,5.8-4.7,10.5-10.5,10.5c0,0,0,0,0,0 C7.7,22.5,3,17.8,3,12c0,0,0,0,0,0C3,6.2,7.7,1.5,13.5,1.5z"/>
					<path style={{ fill: "#ED6C47" }} d="M13.5,1.5C7.7,1.5,3,6.2,3,12h10.5V1.5z" />
					<path style={{ fill: "#FF8F6B" }} d="M13.5,1.5C19.3,1.5,24,6.2,24,12H13.5V1.5z" />
					<g style={{ opacity: 0.67 }}>
						<path style={{ opacity: 0.005, enableBackground: "new" }} d="M5,18.2h7.3c0.4,0,0.8-0.3,0.8-0.7c0,0,0,0,0,0v-11c0-0.4-0.4-0.8-0.8-0.7c0,0,0,0,0,0H5 C4.1,7,3.5,8.4,3.2,9.9v4.2C3.5,15.6,4.1,17,5,18.2L5,18.2z"/>
						<path style={{ opacity: 0.005, enableBackground: "new" }} d="M6.5,19.8h4.4c0.6,0,1-1.3,1-1.8V8.7c0-0.5-0.5-1-1-1h-7C2.1,11.9,3.1,16.8,6.5,19.8L6.5,19.8 z"/>
						<path style={{ opacity: 0.2, enableBackground: "new" }} d="M5.8,19.1h5.1c0.5,0,1-0.4,1-1V8c0-0.5-0.5-1-1-1H4.2C2.1,11,2.8,15.8,5.8,19.1z" />
						<path style={{ opacity: 0.1, enableBackground: "new" }} d="M5.2,18.5H12c0.4,0,0.8-0.3,0.8-0.7v-11C12.8,6.3,12.5,6,12,6H4.9C2.2,9.8,2.4,14.8,5.2,18.5 L5.2,18.5z"/>
						<path style={{ opacity: 0.1, enableBackground: "new" }} d="M5.2,18.7h6.6c0.4,0,0.8-1,0.8-1.4V7.1c0-0.4-0.4-0.8-0.8-0.7c0,0,0,0,0,0H4.5 C2.1,10.2,2.3,15.1,5.2,18.7z"/>
						<path style={{ opacity: 0.001, enableBackground: "new" }} d="M4.3,17l6-0.6c0.4,0,1.6-0.4,1.6-0.8V6.2c0-0.4-0.4-0.8-0.8-0.8H5.3C2.7,8.7,2.2,13.3,4.3,17 L4.3,17z"/>
						<path style={{ opacity: 0.2, enableBackground: "new" }} d="M6.1,19.4h4.8c0.5,0,1-0.4,1-1V8.3c0-0.5-0.5-1-1-1H4.1C2.1,11.4,2.9,16.3,6.1,19.4L6.1,19.4z"/>
						<path style={{ opacity: 0.3, enableBackground: "new" }} d="M5,18.1h5.9c0.5,0,1-0.4,1-1V7.3c0-0.5-0.5-1-1-1H4.7C2.3,10,2.5,14.6,5,18.1L5,18.1z" />
						<path style={{ opacity: 0.3, enableBackground: "new" }} d="M5,18.1h5.9c0.5,0,1-0.4,1-1V7.3c0-0.5-0.5-1-1-1H4.7C2.4,9.9,2.5,14.6,5,18.1L5,18.1z" />
						<path style={{ opacity: 0.3, enableBackground: "new" }} d="M5.4,18.6h5.5c0.6,0,1-0.4,1.1-1V7.6c0-0.5-0.5-1-1-1H4.5C2.2,10.4,2.6,15.2,5.4,18.6z" />
						<path style={{ opacity: 0.2, enableBackground: "new" }} d="M5.6,18.8h5.3c0.5,0,1-0.4,1-1V7.8c0-0.5-0.5-1-1-1H4.4C2.2,10.7,2.6,15.5,5.6,18.8L5.6,18.8z"/>
						<path style={{ opacity: 0.1, enableBackground: "new" }} d="M5,18.4h6.7c0.4,0,0.8-0.7,0.8-1.1V7.5c0-0.4-0.4-0.8-0.8-0.7c0,0,0,0,0,0H4.3 C2.1,10.4,2.4,15,5,18.4z"/>
						<path style={{ opacity: 0.2, enableBackground: "new" }} d="M5,18.1h6.3c0.4,0,0.8-0.3,0.8-0.7c0,0,0,0,0,0v-10c0-0.4-0.4-0.8-0.8-0.7c0,0,0,0,0,0H4.5 C2.3,10.3,2.5,14.8,5,18.1L5,18.1z"/>
						<path style={{ opacity: 0.2, enableBackground: "new" }} d="M4.7,17.7h6.6c0.4,0,0.8-0.3,0.8-0.7c0,0,0,0,0,0V7.1c0-0.4-1.1-0.7-1.2-0.8 C10.6,6.1,10.2,6,9.8,6h-5C2.4,9.5,2.4,14.2,4.7,17.7z"/>
						<path style={{ opacity: 0.1, enableBackground: "new" }} d="M5.6,18.9h6c0.4,0,0.8-0.3,0.8-0.8c0,0,0,0,0,0V8c0-0.4-0.4-0.8-0.8-0.7H4.2 C2.2,11.1,2.8,15.7,5.6,18.9L5.6,18.9z"/>
						<path style={{ opacity: 0.1, enableBackground: "new" }} d="M4.4,17.2l5.9-0.6c0.4,0,1.6-0.4,1.6-0.8V6.4c0-0.4-0.4-0.8-0.8-0.8c0,0,0,0,0,0h-6 C2.6,9,2.3,13.6,4.4,17.2z"/>
						<path style={{ opacity: 0.15, enableBackground: "new" }} d="M4.5,17.4l5.8-0.6c0.4,0,1.6-0.4,1.6-0.8V7.5c0-0.4-0.7-1.7-1.6-1.7H5 C2.5,9.2,2.3,13.8,4.5,17.4L4.5,17.4z"/>
					</g>
					<path style={{ fill: "#C43E1C" }} d="M1,6h10c0.6,0,1,0.4,1,1v10c0,0.6-0.4,1-1,1c0,0,0,0,0,0H1c-0.5,0-1-0.4-1-1c0,0,0,0,0,0 V7C0,6.5,0.4,6,1,6C1,6,1,6,1,6z"/>
					<path style={{ fill: "#F9F7F7" }} d="M6,8.3c0.7-0.1,1.5,0.2,2.1,0.6c0.5,0.4,0.8,1.1,0.7,1.7c0,0.4-0.1,0.9-0.4,1.2 c-0.2,0.4-0.6,0.7-1,0.9C7,12.9,6.5,13,6,13H4.5v2.7H3V8.3H6z M4.5,11.8h1.3c0.4,0,0.8-0.1,1.1-0.3c0.3-0.2,0.4-0.5,0.4-0.8 c0-0.8-0.5-1.2-1.4-1.2H4.5L4.5,11.8z"/>
				</g>
            </g>
        </svg >
    );
};

export default IconPowerPoint;