import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import cloneDeep from 'lodash.clonedeep';
import { LanguageContext } from '../LanguageContext';
import { authFetch, authPost } from "../../authProvider";

const API_PREFIX = 'api/DataIngestion/Yritys';

export default class Yrityshaku extends React.Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            showHtml: false,
            open: false,
            features: null,
            feature: null,
            company: ""
        };

    }

    handleClose = event => {
        const { onClose } = this.props;
        onClose();
    }

    handleSave = event => {

        const { feature } = this.state;

        if (feature) {
            authFetch(this.props.pca, API_PREFIX + '/Search/BusinessId/' + feature.businessId)
                .then(response => response.json())
                .then(data => {
                    if ((data || {}).error) {
                        console.error(data.error);
                    } else {
                        const msg = JSON.parse(data?.message);
                        this.handleSaveCompany((msg?.results || [])[0]);
                    } 
                });
        } else {
            console.error("ERROR: Feature cannot be null.")
        }
    }

    handleSaveCompany = (data) => {
        console.log(data); //TODO
        const { onSelected } = this.props;
        const { name, businessId } = data || {};
        const { city, street, postCode } = (data.addresses || []).sort((a, b) => b?.registrationDate - a?.registrationDate)[0] || {};
        const { code } = (data.businessLines || []).sort((a, b) => b?.registrationDate - a?.registrationDate)[0] || {};

        const newrow = {
            Ytunnus: businessId || null,
            Nimi: name || null,
            Kuvaus: JSON.stringify(data),
            Kunta: city || null,
            Katuosoite: (street || "") + (city ? ", " + city : ""),
            Kadunnimi: street || null,
            Katunumero: null,
            Postinumero: postCode || null,
            Toimialakoodi: code || null,
        }

        //Upsert to DB and get Id
        authPost(this.props.pca, API_PREFIX + '/Upsert', {
            body: JSON.stringify(newrow)
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    console.error(data.error);
                } else {
                    onSelected((data || [])[0]);
                }
            });

    }

    handleSearchTextChange = (event, value) => {
        if ((value || '').length > 4) {
            const str = value.toString().toLowerCase()
                .replace("å", "a")
                .replace("ä", "a")
                .replace("ö", "o");

            authFetch(this.props.pca, API_PREFIX + '/Search/' + str)
                .then(response => response.json())
                .then(data => {
                    if ((data || {}).error) {
                        console.error(data.error);
                    } else {
                        const msg = JSON.parse(data?.message);
                        this.handleNewFeatures(msg?.results);
                    }
                });
        }

        this.setState({
            company: value
        });
    }

    handleNewFeatures = (features) => {
        if ((features || []).length > 0) {
            this.setState({ features });
        }
    }

    handleSearchChange = (event, value) => {
        if (value) {
            const feature = cloneDeep(value);
            this.setState({
                company: feature?.name,
                feature: feature
            });
        }
    }

    handleResetCompany = event => {
        this.setState({
            company: null,
            feature: null
        });
    }

    render() {
        const { dictionary } = this.context;
        const { open } = this.props;
        const { company, features } = this.state;

        return (
            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                >
                    <DialogTitle>{dictionary.DataIngestion.Yrityshaku.Title}</DialogTitle>
                    <DialogContent>
                        <Box>
                            {dictionary.DataIngestion.Yrityshaku.Body}
                        </Box>
                        <Box style={{
                            display: "flex",
                            padding: 0
                        }}>
                            <Autocomplete
                                onChange={this.handleSearchChange}
                                onInputChange={this.handleSearchTextChange}
                                value={(company || '')}
                                options={features || []}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.name || '';
                                }}
                                isOptionEqualToValue={(option, value) => option.name === value}
                                style={{ width: 400 }}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            label={dictionary.DataIngestion.Yrityshaku.Placeholder}
                                            variant="outlined"
                                        />
                                    );
                                }}
                            />
                            <IconButton
                                onClick={this.handleResetCompany}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}