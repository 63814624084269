/**
 * Custom sorting for handling also labels corractly
 * @param {Array} data
 * @param {Array} columns 
 * @param {object} lookupLists 
 * @param {number} colIndex
 * @param {string} order
 */
export const CustomSort = (data, columns, lookupLists, colIndex, order) => {
    return data.sort((a, b) => {
        if (a.data[colIndex] === b.data[colIndex]) {
            // samanarvoisissa riveiss� otetaan riviavainj�rjestys
            return ((a.data[0] || -9e6) < (b.data[0] || -9e6) ? -1 : 1) * (order === 'desc' ? 1 : -1);
        } else if (columns[colIndex].options.lookuplist) {
            // lookuplist lajittelu
            const list = lookupLists[columns[colIndex].options.lookuplist];
            const aa = (list[a.data[colIndex]] || ''), bb = (list[b.data[colIndex]] || '');

            return ((parseInt(!/[^0-9]/i.test(aa) ? aa : 'x') || aa.toString()) < (parseInt(!/[^0-9]/i.test(bb) ? bb : 'x') || bb.toString()) ? -1 : 1) * (order === 'desc' ? 1 : -1);
        } else {
            // normaali lajittelu
            return ((a.data[colIndex] || -9e6) < (b.data[colIndex] || -9e6) ? -1 : 1) * (order === 'desc' ? 1 : -1);
        }
    });
}