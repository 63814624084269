import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import MUIDataTable from 'mui-datatables';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import cloneDeep from 'lodash.clonedeep';
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FolderOffIcon from '@mui/icons-material/FolderOff';
import moment from 'moment';
import dayjs from 'dayjs';

import { authFetch, authPost } from "../../authProvider";
import { LanguageContext } from '../LanguageContext';
import TilitapahtumaAdd from "./TilitapahtumaAdd";
import TilitapahtumaEdit from "./TilitapahtumaEdit";
import Luokitteluhaku from "./Luokitteluhaku";
import LocalizedDatePicker from "../LocalizedDatePicker";

/* Näkymäkohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/DataIngestion/Tilitapahtuma';
const API_LUOKITTELU_PREFIX = 'api/DataIngestion/Luokittelu';
const API_TOIMIALAT_PREFIX = 'api/DataIngestion/Toimiala';
const API_KUSTANNUSPAIKAT_PREFIX = 'api/Inventory/Kustannuspaikka';

const default_columns = [
    {
        label: "RiviAvain",
        name: "riviAvain",
        options: {
            filter: false,
            display: false
        }
    },
    {
        label: "EmoTilitapahtuma",
        name: "emoTilitapahtumaAvain",
        options: {
            filter: true,
            display: true,
            customBodyRender: function (value, tableMeta, updateValue) {
                return tilitapahtumat[value];
            },
            lookuplist: 'tilitapahtumat'
        }
    },
    {
        label: "Kustannuspaikka",
        name: "kustannuspaikkaAvain",
        options: {
            filter: true,
            display: true,
            customBodyRender: function (value, tableMeta, updateValue) {
                return kustannuspaikat[value];
            },
            lookuplist: 'kustannuspaikat'
        }
    },
    {
        label: "TapahtumaAika",
        name: "tapahtumaAika",
        options: {
            filter: false,
            display: true,
            customBodyRender: function (value, tableMeta, updateValue) {
                var date = moment(value).format("D.M.YYYY");
                return moment(value).isValid() ? date : "";
            },
        }
    },
    {
        label: "Tosite",
        name: "tosite",
        options: {
            filter: false,
            display: true
        }
    },
    {
        label: "Tositelaji",
        name: "tositelaji",
        options: {
            filter: true,
            display: true
        }
    },
    {
        label: "Tilinumero",
        name: "tilinumero",
        options: {
            filter: true,
            display: true
        }
    },
    {
        label: "Tilinimi",
        name: "tilinimi",
        options: {
            filter: true,
            display: true
        }
    },
    {
        label: "Yritys",
        name: "yritys",
        options: {
            filter: true,
            display: true
        }
    },
    {
        label: "Selite",
        name: "selite",
        options: {
            filter: false,
            display: true
        }
    },
    {
        label: "Projekti",
        name: "projekti",
        options: {
            filter: true,
            display: true
        }
    },
    {
        label: "Summa",
        name: "summa",
        options: {
            filter: false,
            display: true
        }
    },
    {
        label: "TapahtumaTyyppi",
        name: "tapahtumaTyyppi",
        options: {
            filter: true,
            display: true
        }
    },
    {
        label: "AlvKoodi",
        name: "alvKoodi",
        options: {
            filter: true,
            display: true
        }
    },
    {
        label: "Luokittelu",
        name: "luokitteluId",
        options: {
            filter: true,
            display: false,
        }
    },
    {
        label: "Luokka",
        name: "luokkaId",
        options: {
            filter: true,
            display: false,
        }
    },
    {
        label: "Luokkanimi",
        name: "luokkaNimi",
        options: {
            filter: true,
            display: true
        }
    },
    {
        label: "Päästöt",
        name: "paastot",
        options: {
            filter: false,
            display: true,
        }
    },
    {
        label: "PaastoIntensiteetti",
        name: "paastoIntensiteetti",
        options: {
            filter: false,
            display: true
        }
    },
    {
        label: "Luokiteltu",
        name: "luokiteltu",
        options: {
            filter: true,
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                const pi = tableMeta.rowData[14]; //Luokittelu
                return pi > 0 || pi === -2 ? "Kyllä" : "Ei"; //TODO: kielisyys
            }
        }
    },
    {
        label: "Luotu",
        name: "created",
        options: {
            filter: false,
            display: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                const date = moment(value);
                return date.isValid() ? date.format("D.M.YYYY, HH:mm") : "";
            },
        }
    },
    {
        label: "Muokattu",
        name: "updated",
        options: {
            filter: false,
            display: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                const date = moment(value);
                return date.isValid() ? date.format("D.M.YYYY, HH:mm") : "";
            },
        }
    },
    {
        label: "Muokkaaja",
        name: "username",
        options: {
            filter: false,
            display: false
        }
    }
];


/* Perusmuuttujat ja vakiot */
const PROFIILI_GET = 'api/Profiili/Read';
const PROFIILI_PUT = 'api/Profiili/Update';

var rowId;

var tilitapahtumat = {};
var kustannuspaikat = {};
var toimialat = {}

/* Luokka */
export class Tilitapahtuma extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        const d = dayjs();

        // State alustukset
        this.state = {
            lookupLists: {
                toimialat,
                tilitapahtumat,
                kustannuspaikat,
            },
            rowsSelected: [],
            loading: true,
            rowNum: 25,
            errorShow: false,
            errorTitle: '',
            errorBody: '',
            Alku: d.subtract(1, 'month'),
            Loppu: d,
            tositelajit: [],
            selectedTositelajit: [],
            selectedTilinumerot: [],
            sortOrder: { name: 'riviAvain', direction: 'asc' },
            selectedLuokittelu: 0,
        };

        //Bindit
        this.swapCols = this.swapCols.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
    }

    componentDidMount = () => {

        let valikkoData = [], tositelajit = [], tilinumerot = [];

        //Toimiala
        authFetch(this.props.pca, API_PREFIX + '/Tositelaji/Read')
            .then(response => response.json())
            .then(data => {
                if ((data || []).length > 0) {
                    valikkoData = data;
                    tositelajit = data
                        .map(d => d.tositelaji)
                        .filter((value, index, array) => array.indexOf(value) === index)
                        .map(d => ({ value: d, label: d }));

                    tilinumerot = data
                        .map(d => d.tilinumero)
                        .filter((value, index, array) => array.indexOf(value) === index)
                        .map(d => ({ value: d, label: d }));
                }
                //Toimiala
                authFetch(this.props.pca, API_TOIMIALAT_PREFIX + '/Read')
                    .then(response => response.json())
                    .then(data => {
                        if ((data || []).length > 0) {
                            data.forEach((d, i) => {
                                toimialat[d.riviAvain] = (d.toimialaKoodi + ' ' + d.nimiFi); //TODO: enkku
                            });
                        }
                        //organisaatiot
                        authFetch(this.props.pca, API_KUSTANNUSPAIKAT_PREFIX + '/Read')
                            .then(response => response.json())
                            .then(data => {
                                if ((data || []).length > 0) {
                                    data.forEach((d, i) => {
                                        kustannuspaikat[d.riviAvain] = (d.nimi || 'ei tietoja');
                                    });
                                }
                                //Profiili
                                authFetch(this.props.pca, PROFIILI_GET)
                                    .then(response => response.json())
                                    .then(data => {
                                        var cols = (((JSON.parse((data || [])[0].uI_Settings) || {}).columns || {}).tilitapahtuma || []); // TODO: muuta avain
                                        if (cols.length > 0) cols = JSON.parse(cols);
                                        else cols = null;

                                        // hidden cols
                                        var hidden_cols = (((JSON.parse((data || [])[0].uI_Settings) || {}).hiddencolumns || {}).tilitapahtuma || []); // TODO: muuta avain
                                        if (hidden_cols.length > 0) hidden_cols = JSON.parse(hidden_cols);
                                        else hidden_cols = null;

                                        // rowNum
                                        var rowNum = (((JSON.parse((data || [])[0].uI_Settings) || {}).rownum || {}).tilitapahtuma || []); // TODO: muuta avain
                                        if (rowNum.length > 0) rowNum = JSON.parse(rowNum);
                                        else rowNum = 25;

                                        var columns = get_ordered_columns(cols, hidden_cols);

                                        this.setState({
                                            lookupLists: {
                                                toimialat,
                                                tilitapahtumat,
                                                kustannuspaikat,
                                            },
                                            columns,
                                            data: [],
                                            valikkoData,
                                            tositelajit,
                                            tilinumerot,
                                            loading: false,
                                            rowNum,
                                            searchLoading: true,
                                        });
                                    });
                            });
                    });
            });
    };

    swapCols = (index) => {
        if (index > 1) {
            var cols = cloneDeep(this.state.columns);
            var d = this.state.data;
            [cols[index - 1], cols[index]] = [cols[index], cols[index - 1]];
            this.setState({ columns: cols });
            var setting = { "key": "columns.tilitapahtuma", "value": JSON.stringify(cols.map(function (d) { return d.name; })).split('"').join('""') }; // TODO: muuta avain
            authPost(this.props.pca, PROFIILI_PUT, {
                body: JSON.stringify(setting)
            });
        }
    };

    changeRownum = (num) => {
        this.setState({ rowNum: num })

        var setting = { "key": "rownum.tilitapahtuma", "value": JSON.stringify(num).split('"').join('""') }; // TODO: muuta avain
        authPost(this.props.pca, PROFIILI_PUT, {
            body: JSON.stringify(setting)
        })
    };

    hiddenCols = (changedColumn, action) => {
        var cols = cloneDeep(this.state.columns);
        var hidden = [];

        for (var i = 0; i < cols.length; i++) {
            if (!(cols[i].options))
                cols[i].options = {};

            if (cols[i].name == changedColumn)
                cols[i].options.display = (action === "add" ? true : false)

            if (cols[i].options.display == false) {
                hidden.push(cols[i].name);
            }
        }

        if (action === "add") {
            hidden.splice(hidden.indexOf(changedColumn), 1);
        }
        else if (action === "remove") {
            hidden.push(changedColumn);
        }

        this.setState({ columns: cols });
        var setting = { "key": "hiddencolumns.tilitapahtuma", "value": (hidden.length > 0 ? JSON.stringify(hidden).split('"').join('""') : "[]") }; // TODO: muuta avain
        authPost(this.props.pca, PROFIILI_PUT, {
            body: JSON.stringify(setting)
        })
    };

    updateData = () => {
        const { Alku, Loppu, selectedTositelajit, selectedTilinumerot, selectedLuokittelu } = this.state;

        //Items
        authPost(this.props.pca, API_PREFIX + "/Read", {
            body: JSON.stringify({
                alku: Alku.format('YYYY-MM-DD'),
                loppu: Loppu.format('YYYY-MM-DD'),
                tositelajit: JSON.stringify((selectedTositelajit || []).map(d => d.value)),
                tilinumerot: JSON.stringify((selectedTilinumerot || []).map(d => d.value)),
                luokittelu: selectedLuokittelu > -1 ? selectedLuokittelu : 0,
            })
        })
            .then(response => response.json())
            .then(data => {
                const { lookupLists } = this.state;

                let items = [];
                let tilitapahtumat = {};

                if ((data || []).length > 0) {
                    data.forEach((d, i) => {
                        tilitapahtumat[d.riviAvain] = (d.nimi);
                    });
                    items = data;
                    lookupLists.tilitapahtumat = tilitapahtumat;
                }

                this.setState({
                    lookupLists: lookupLists,
                    data: items,
                    searchLoading: false,
                    rowsSelected: []
                });
            });
    }

    handleError = (error) => {
        const { onError } = this.props;

        var title = '', body = ''
        if (error === 4) {
            title = this.context.dictionary.ErrorDialog.Error4.title;
            body = this.context.dictionary.ErrorDialog.Error4.body;
        } else if (error === 5) {
            title = this.context.dictionary.ErrorDialog.Error5.title;
            body = this.context.dictionary.ErrorDialog.Error5.body;
        } else {
            title = this.context.dictionary.ErrorDialog.Error.title;
            body = this.context.dictionary.ErrorDialog.Error.body;
        }

        onError(title, body);
    }

    handleErrorClose = () => this.setState({ errorShow: false });

    addNewItem = (newrow) => {
        // ADD
        authPost(this.props.pca, API_PREFIX + '/Create', {
            body: JSON.stringify(newrow)
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    this.updateData();
                    this.handleMessage(this.context.dictionary.Message.Add)
                }
            });
    }

    editItem = (newrow) => {
        // EDIT
        authPost(this.props.pca, API_PREFIX + '/Update', {
            body: JSON.stringify(newrow)
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    this.updateData();
                    this.handleMessage(this.context.dictionary.Message.Edit)
                }
            });
    }

    handleLuokittelu = (ids, group, clss) => {
        authPost(this.props.pca, API_LUOKITTELU_PREFIX + '/Update', {
            body: JSON.stringify({
                tilitapahtumaavaimet: (ids || []).join(','),
                luokitteluId: parseInt(group),
                luokkaId: parseInt(clss),
            })
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    this.updateData();
                }
            });
    }

    handleChangeDate = (name, value) => {
        this.setState({ [name]: value });
    }

    handleListChange = (event, newValue, item) => {
        const { valikkoData } = this.state;

        if (item === "selectedTositelajit") {
            const tositelajit = (newValue || []).map(d => d.value);

            const tilinumerot = valikkoData
                .filter(d => tositelajit.indexOf(d.tositelaji) > -1 || newValue.length === 0)
                .map(d => d.tilinumero)
                .filter((value, index, array) => array.indexOf(value) === index)
                .map(d => ({ value: d, label: d }));

            this.setState({
                [item]: newValue,
                tilinumerot
            });

        } else if (item === "selectedTilinumerot") {
            //this.setState({ [item]: newValue });
            const tilinumerot = (newValue || []).map(d => d.value);

            const tositelajit = valikkoData
                .filter(d => tilinumerot.indexOf(d.tilinumero) > -1 || newValue.length === 0)
                .map(d => d.tositelaji)
                .filter((value, index, array) => array.indexOf(value) === index)
                .map(d => ({ value: d, label: d }));

            this.setState({
                [item]: newValue,
                tositelajit
            });
        } else if (item === "selectedLuokittelu") {
            this.setState({
                [item]: newValue?.value
            });
        }
    }

    handleMessage = (msg) => {
        const { onMessage } = this.props;
        onMessage(msg);
    }

    handleSortChange = (changedColumn, direction) => {
        this.setState({
            sortOrder: { name: changedColumn, direction },
        });
    };

    render() {
        const { dictionary } = this.context;
        const { loading, searchLoading, data, tositelajit, tilinumerot } = this.state;

        const cols = cloneDeep(this.state.columns);
        if ((cols || []).length > 0) {
            for (var i = 0; i < default_columns.length; i++) {
                for (var j = 0; j < cols.length; j++) {
                    if (cols[j].label === default_columns[i].label) {
                        cols[j].label = dictionary.DataIngestion.Tilitapahtuma.Columns[i] //TODO: Muuta avain
                    }
                }
            }
        }

        const options = {
            textLabels: dictionary.MuiDataTable.textLabels,
            selectableRowsHeader: true,
            selectableRowsOnClick: true,
            selectableRows: "multiple",
            filterType: 'multiselect',
            responsive: "vertical",
            tableBodyHeight: "calc(100vh - 316px)",
            rowsPerPageOptions: [5, 10, 25, 50, 100],
            rowsPerPage: this.state.rowNum,
            jumpToPage: true,
            rowsSelected: this.state.rowsSelected,
            sortOrder: this.state.sortOrder,
            onColumnSortChange: this.handleSortChange,
            onViewColumnsChange: (changedColumn, action) => {
                this.hiddenCols(changedColumn, action);
            },
            onRowSelectionChange: (currentRowsSelected, allRows, rowsSelected) => {
                const { data } = this.state;
                rowId = null;
                if (rowsSelected.length > 0) {
                    rowId = data[rowsSelected[0]].riviAvain; //TODO: Muuta avain
                }
                this.setState({ rowsSelected: rowsSelected });
            },
            onChangeRowsPerPage: (num) => {
                this.changeRownum(num);
            },
            // Huomioi samat arvot ja sen jölkeen lajitellaan ensimmäisen sarakkeen (id) mukaan
            customSort: (data, colIndex, order) => {
                const { columns, lookupLists } = this.state;

                return data.sort((a, b) => {
                    if (a.data[colIndex] == b.data[colIndex]) {
                        // samanarvoisissa riveissä otetaan riviavainjärjestys
                        return ((a.data[0] || -9e6) < (b.data[0] || -9e6) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    } else if (columns[colIndex].options.lookuplist) {
                        // lookuplist lajittelu
                        const list = lookupLists[columns[colIndex].options.lookuplist];
                        var aa = (list[a.data[colIndex]] || ''), bb = (list[b.data[colIndex]] || '');

                        return ((parseInt(!/[^0-9]/i.test(aa) ? aa : 'x') || aa.toString()) < (parseInt(!/[^0-9]/i.test(bb) ? bb : 'x') || bb.toString()) ? 1 : -1) * (order === 'desc' ? 1 : -1);
                    } else {
                        // normaali lajittelu
                        return ((a.data[colIndex] || -9e6) < (b.data[colIndex] || -9e6) ? 1 : -1) * (order === 'desc' ? 1 : -1);
                    }
                });
            },
            customToolbarSelect: (selRows, displayData, setSelectedRows) => {
                const { data } = this.state;

                let selectedRows = [];

                if ((selRows.data || []).length > 0) {
                    selectedRows = selRows.data.map(d => (d.dataIndex));
                    rowId = data[selectedRows[0]]?.riviAvain; //TODO: Muuta avain
                }

                var handleDelete = () => {
                    var data = cloneDeep(this.state.data);

                    if (window.confirm("Haluatko varmasti poistaa seuraavat kohteet: " + selectedRows.map(d => data[d].riviAvain).join(", "))) {
                        var requests = []
                        for (var i = 0; i < selectedRows.length; i++) {
                            requests.push(
                                authPost(this.props.pca, API_PREFIX + '/Delete/' + data[selectedRows[i]].riviAvain, { method: 'delete' }) //TODO: Muuta avain
                            );
                        }
                        Promise.all(requests)
                            .then(() => {
                                setSelectedRows([]);
                                this.updateData();
                                this.handleMessage(`(${selectedRows.length}) ${dictionary.Message.Delete}`)
                            });
                    }
                }

                var handleCopy = () => {
                    const { data } = this.state;
                    if (window.confirm("Haluatko varmasti kopioida seuraavat kohteet: " + selectedRows.map(d => data[d].riviAvain).join(", "))) {
                        var requests = []
                        for (var i = 0; i < selectedRows.length; i++) {
                            const row = data[selectedRows[i]];
                            var newrow = {
                                RiviAvain: 1, //dummy-arvo
                                EmoTilitapahtumaAvain: row.emoTilitapahtumaAvain,
                                KustannuspaikkaAvain: row.kustannuspaikkaAvain,
                                TapahtumaAika: row.tapahtumaAika,
                                Tosite: row.tosite,
                                Tositelaji: row.tositelaji,
                                Tilinumero: row.tilinumero,
                                Tilinimi: row.tilinimi,
                                Yritys: row.yritys,
                                Selite: row.selite,
                                Projekti: row.projekti,
                                Summa: row.summa,
                                TapahtumaTyyppi: row.tapahtumaTyyppi,
                                AlvKoodi: row.alvKoodi,
                                Created: row.created,
                                Updated: row.updated,
                                Username: row.username,
                            };
                            requests.push(
                                authPost(this.props.pca, API_PREFIX + '/Create', { body: JSON.stringify(newrow) })
                            );
                        }
                        Promise.all(requests)
                            .then(() => {
                                setSelectedRows([]);
                                this.updateData();
                                this.handleMessage(`(${selectedRows.length}) ${dictionary.Message.Copy}`)
                            });
                    }
                }

                return (
                    <div className={"custom-toolbar-select"}>
                        {(selectedRows.length < 2) ? (
                            <React.Fragment>
                                <Tooltip title={"Nollaus"}>
                                    <IconButton onClick={() => { this.handleLuokittelu((selectedRows || []).map(d => data[d].riviAvain), -2, -1) }}>
                                        <FolderOffIcon />
                                    </IconButton>
                                </Tooltip>
                                <Luokitteluhaku pca={this.props.pca} selected={(selectedRows || []).map(d => data[d].riviAvain)} onSave={this.handleLuokittelu} lookupLists={this.state.lookupLists} />
                                <TilitapahtumaEdit pca={this.props.pca} onEditRow={this.editItem} lookupLists={this.state.lookupLists} Daatta={this.state.data} rowId={rowId} />
                                <Tooltip title={dictionary.Toolbar.Copy}>
                                    <IconButton onClick={handleCopy}>
                                        <FileCopyIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={dictionary.Toolbar.Delete}>
                                    <IconButton onClick={handleDelete}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>

                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Tooltip title={"Nollaus"}>
                                    <IconButton onClick={() => { this.handleLuokittelu((selectedRows || []).map(d => data[d].riviAvain), -2, -1) }}>
                                        <FolderOffIcon />
                                    </IconButton>
                                </Tooltip>
                                <Luokitteluhaku pca={this.props.pca} selected={(selectedRows || []).map(d => data[d].riviAvain)} onSave={this.handleLuokittelu} lookupLists={this.state.lookupLists} />
                                <Tooltip title={dictionary.Toolbar.Copy}>
                                    <IconButton onClick={handleCopy}>
                                        <FileCopyIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={dictionary.Toolbar.Delete}>
                                    <IconButton onClick={handleDelete}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </React.Fragment>
                        )}
                    </div>
                );
            },
            customToolbar: () => {
                return (
                    <React.Fragment>
                        <TilitapahtumaAdd pca={this.props.pca} onAddNewRow={this.addNewItem} lookupLists={this.state.lookupLists} />
                    </React.Fragment>
                );
            }

        };

        //dictionary.<komponentin nimi>.Title
        return (
            <div style={{ padding: 10 }}>
                <div>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Box style={{ display: 'flex', paddingTop: "10px" }} >
                                    <Autocomplete
                                        multiple
                                        filterSelectedOptions
                                        onChange={(event, newValue) => this.handleListChange(event, newValue, 'selectedTositelajit')}
                                        options={tositelajit}
                                        style={{ width: 400 }}
                                        renderInput={(params) => <TextField  {...params} variant="outlined" label={dictionary.DataIngestion.Tilitapahtuma.Columns[5]} />}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box style={{ display: 'flex', paddingTop: "10px" }} >
                                    <Autocomplete
                                        multiple
                                        filterSelectedOptions
                                        onChange={(event, newValue) => this.handleListChange(event, newValue, 'selectedTilinumerot')}
                                        options={tilinumerot}
                                        style={{ width: 400 }}
                                        renderInput={(params) => <TextField  {...params} variant="outlined" label={dictionary.DataIngestion.Tilitapahtuma.Columns[6]} />}
                                    />
                                </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box style={{ display: 'flex', paddingTop: "10px" }} >
                                        <Autocomplete
                                            onChange={(event, newValue) => this.handleListChange(event, newValue, 'selectedLuokittelu')}
                                            options={dictionary.DataIngestion.Tilitapahtuma.Search.Classified}
                                            style={{ width: 400 }}
                                            renderInput={(params) => <TextField  {...params} variant="outlined" label={dictionary.DataIngestion.Tilitapahtuma.Search.ClassifiedTitle} />}
                                        />
                                    </Box>
                                </Grid>
                            <Grid item xs={2}>
                                <LocalizedDatePicker
                                    label={dictionary.DataIngestion.Tilitapahtuma.Search.Start}
                                    value={this.state.Alku}
                                    onChange={(newValue) => this.handleChangeDate('Alku', newValue)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <LocalizedDatePicker
                                    label={dictionary.DataIngestion.Tilitapahtuma.Search.End}
                                    value={this.state.Loppu}
                                    onChange={(newValue) => this.handleChangeDate('Loppu', newValue)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" color="primary" style={{ color: "#fff", marginTop: 16 }} onClick={this.updateData}>
                                    {dictionary.DataIngestion.Tilitapahtuma.Search.Get}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                {!searchLoading && (
                                    <MUIDataTable
                                        title={dictionary.DataIngestion.Tilitapahtuma.Title}
                                        data={data}
                                        columns={cols}
                                        options={options}
                                    />
                                )}
                            </Grid>
                        </Grid>

                    )
                    }
                </div>
            </div>
        );
    }
}

function get_ordered_columns(list, hidden) {
    const columns = [];

    if (!Array.isArray(list)) {
        list = default_columns.map((c) => (c.name));
    }

    list.forEach(function (el) {
        columns.push((default_columns.filter(function (d) { return d.name == el; }) || [])[0]);
        if (hidden != null) {
            if (!(columns[columns.length - 1].options))
                columns[columns.length - 1].options = {};
            if (hidden.indexOf(columns[columns.length - 1].name) > -1) {
                columns[columns.length - 1].options.display = false;
            } else {
                columns[columns.length - 1].options.display = true;
            }
        }
    });

    return columns;
}