import React from "react";

const IconWord = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            version="1.1"
            viewBox="0 0 24 24"
        >
			<g>
				<g>
					<path style={{ fill: "#41A5EE" }} d="M7,1.7h16c0.5,0,1,0.4,1,1v18.6c0,0.5-0.5,1-1,1c0,0,0,0,0,0H7c-0.3,0-0.5-0.1-0.7-0.3C6.1,21.8,6,21.6,6,21.3V2.7C6,2.5,6.1,2.2,6.3,2C6.5,1.8,6.7,1.7,7,1.7L7,1.7z"/>
					<path style={{ fill: "#103F91" }} d="M24,17.1H6v4.1c0,0.5,0.5,1,1,1c0,0,0,0,0,0h16c0.5,0,1-0.4,1-1c0,0,0,0,0,0L24,17.1z" />
					<path style={{ fill: "#185ABD" }} d="M6,12h18v5.1H6V12z" />
					<path style={{ fill: "#2B7CD3" }} d="M6,6.9h18V12H6V6.9z" />
				</g>
				<g style={{ opacity: 0.67 }}>
					<path style={{ opacity: 0.005, enableBackground: "new" }} d="M12.4,5.9H7.2C7,5.9,6.8,6.1,6.8,6.3c0,0,0,0,0,0v11.4c0,0.2,0.2,0.4,0.4,0.4h5.2c0.4,0,0.7-0.3,0.7-0.7V6.7C13.1,6.3,12.8,6,12.4,5.9C12.4,6,12.4,6,12.4,5.9L12.4,5.9z"/>
					<path style={{ opacity: 0.1, enableBackground: "new"}} d="M11,7.9H6v11.8h5c0.5,0,1-0.4,1-0.9V8.8C12,8.3,11.5,7.8,11,7.9z" />
					<path style={{ opacity: 0.2, enableBackground: "new" }} d="M11,7.2H6V19h5c0.5,0,1-0.4,1-0.9V8.1C12,7.6,11.5,7.2,11,7.2z" />
					<path style={{ opacity: 0.1, enableBackground: "new" }} d="M12.1,6.2H6.9c-0.2,0-0.4,0.2-0.4,0.4c0,0,0,0,0,0v11.4c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0h5.2c0.4,0,0.7-0.3,0.7-0.7V6.9C12.8,6.5,12.5,6.2,12.1,6.2z"/>
					<path style={{ opacity: 0.1, enableBackground: "new" }} d="M12.1,6.5H6.9c-0.2,0-0.4,0.2-0.4,0.4c0,0,0,0,0,0v11.3c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0h5.2c0.4,0,0.7-0.3,0.8-0.7c0,0,0,0,0,0V7.2C12.8,6.8,12.5,6.5,12.1,6.5C12.1,6.5,12.1,6.5,12.1,6.5L12.1,6.5z"/>
					<path style={{ opacity: 0.001, enableBackground: "new" }} d="M11.2,5.6H6v9.6H5.9v1.5c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0l4.2-0.7c0.4,0,1.5-0.3,1.5-0.8V6.3C12,5.9,11.7,5.6,11.2,5.6C11.3,5.6,11.3,5.6,11.2,5.6L11.2,5.6z"/>
					<path style={{ opacity: 0.2, enableBackground: "new" }} d="M11,7.5H6v11.8h5c0.5,0,1-0.4,1-0.9V8.4C12,7.9,11.5,7.5,11,7.5z" />
					<path style={{ opacity: 0.3, enableBackground: "new" }} d="M11,6.5H6V18h5c0.5,0,1-0.4,1-0.9c0,0,0,0,0-0.1V7.4C12,6.9,11.5,6.5,11,6.5C11,6.5,11,6.5,11,6.5z"/>
					<path style={{ opacity: 0.3, enableBackground: "new" }} d="M11,6.5H6v11.5h5c0.5,0,1-0.4,1-0.9V7.4C12,6.9,11.5,6.5,11,6.5C11,6.5,11,6.5,11,6.5z" />
					<path style={{ opacity: 0.3, enableBackground: "new" }} d="M11,6.7H6v11.8h5c0.5,0,1-0.4,1-0.9V7.7C12,7.1,11.5,6.7,11,6.7C11,6.7,11,6.7,11,6.7z" />
					<path style={{ opacity: 0.2, enableBackground: "new" }} d="M11,6.9H6v11.8h5c0.5,0,1-0.4,1-0.9V7.9C12,7.4,11.6,6.9,11,6.9C11,6.9,11,6.9,11,6.9z" />
					<path style={{ opacity: 0.1, enableBackground: "new" }} d="M11.9,6.8H6.7C6.5,6.8,6.4,7,6.4,7.2c0,0,0,0,0,0v10.7c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0h5.2c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-10C12.7,7.1,12.4,6.8,11.9,6.8z"/>
					<path style={{ opacity: 0.2, enableBackground: "new" }} d="M11.4,6.8H6.2c0,0-0.1,0-0.1,0V18c0,0,0.1,0,0.1,0h5.2c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0V7.5C12.1,7.1,11.8,6.8,11.4,6.8C11.4,6.8,11.4,6.8,11.4,6.8z"/>
					<path style={{ opacity: 0.3, enableBackground: "new" }} d="M10.1,6.2H6.3c-0.2,0-0.4,0.2-0.4,0.4v10.7c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0h5.2c0.4,0,0.7-0.3,0.7-0.7V7.2c0-0.4-1-0.7-1.1-0.8C10.8,6.3,10.4,6.2,10.1,6.2z"/>
					<path style={{ opacity: 0.1, enableBackground: "new" }} d="M11.7,7.3H6.5c-0.2,0-0.4,0.2-0.4,0.4v10.7c0,0.2,0.2,0.4,0.4,0.4h5.2c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-10C12.4,7.7,12.1,7.3,11.7,7.3L11.7,7.3z"/>
					<path style={{ opacity: 0.1, enableBackground: "new" }} d="M11.2,5.8H6v9.6H5.9v1.5c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0l4.2-0.7c0.4,0,1.5-0.4,1.5-0.8V6.5C12,6.1,11.7,5.8,11.2,5.8L11.2,5.8z"/>
					<path style={{ opacity: 0.15, enableBackground: "new" }} d="M11.2,6H6v9.6H5.9v1.5c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0l4.2-0.7c0.4,0,1.5-0.4,1.5-0.8V6.7C12,6.3,11.6,6,11.2,6C11.2,6,11.2,6,11.2,6L11.2,6z"/>
				</g>
				<path style={{ fill: "#185ABD" }} d="M1,6.1h10c0.5,0,1,0.4,1,1v9.8c0,0.5-0.5,1-1,1c0,0,0,0,0,0H1c-0.3,0-0.5-0.1-0.7-0.3C0.1,17.4,0,17.2,0,16.9V7.1C0,6.6,0.5,6.1,1,6.1z"/>
				<path style={{ fill: "#FFFFFF" }} d="M9.1,15.7H7.7l-1.6-5.1l-1.6,5.1H3.2L1.6,8.3H3l1,5.1l1.6-5h1.1l1.5,5l1-5.1h1.3L9.1,15.7z" />
            </g>
        </svg >
    );
};

export default IconWord;