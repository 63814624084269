import React from 'react';
import { MsalContext } from "@azure/msal-react";
import { Outlet } from 'react-router-dom';
import { GlobalMenu } from './GlobalMenu';
import { languageOptions, dictionaryList } from './../translations';
import { LanguageContext } from './LanguageContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import { authFetch } from "./../authProvider";

/* User language from local storage */
const defaultLanguage = window.localStorage.getItem('karpon-lang');

/* User Language from browser */
const userLang = 'fi';
const lang = Object.keys(dictionaryList).indexOf(userLang) > -1 ? userLang : 'fi';

// Too small screen
const Content = ({ children, title, description }) => {

    const scaleable = useMediaQuery('(min-height:500px) and (min-width:900px)');
    const showContent = useMediaQuery('(min-height:600px)');
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const resizeHandler = () => {
            setWindowHeight(window.innerHeight);
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", resizeHandler);

        return () => window.removeEventListener("resize", resizeHandler);

    }, [windowWidth, windowHeight]);

    return (
        <div
            style={{
                position: "fixed",
                transform: "scale(" + (showContent ? 1 : (windowHeight / 600)) + ")",
                transformOrigin: "top left",
                minHeight: 600,
                minWidth: windowWidth / (windowHeight / 600),
                width: "100%"
            }}
        >
            {children}
            {scaleable ? null : (
                <Dialog open={!scaleable}>
                    <Alert severity="warning">
                        <DialogTitle>{title}</DialogTitle>
                        <DialogContentText style={{ color: "#000" }}>{description}</DialogContentText>
                        <DialogActions />
                    </Alert>
                </Dialog>
            )}
        </div>
    );
}

export class Layout extends React.Component {
    static contextType = MsalContext;

    constructor(props) {
        super(props);

        this.state = {
            userLanguage: defaultLanguage || lang,
        };
    }

    userLanguageChange = selected => {
        const newLanguage = languageOptions[selected] ? selected : "fi";

        authFetch(this.props.pca, 'api/Profiili/Lang/' + newLanguage)
            .then(() => {
                console.log(newLanguage); //TODO
            });

        window.localStorage.setItem('karpon-lang', newLanguage);

        this.setState({
            userLanguage: newLanguage
        });
    }

    render() {
        const { instance } = this.context;
        const { userLanguage } = this.state;

        return (
            <LanguageContext.Provider value={{
                userLanguage: userLanguage,
                dictionary: dictionaryList[userLanguage],
                userLanguageChange: this.userLanguageChange
            }}>
                <Content title={dictionaryList[userLanguage].SmallScreen.Title} description={dictionaryList[userLanguage].SmallScreen.Description}>
                    <div style={{ display: "flex", height: "100vh" }}>
                        <GlobalMenu selected={this.props.selected} parentId={this.props.parentId} itemId={this.props.itemId} menuId={this.props.menuId} pca={instance} />
                        <div className="Content">
                            <Outlet />
                        </div>
                    </div>
                </Content>
            </LanguageContext.Provider>
        );
    }
}
