import React from 'react';
import { LanguageContext } from './LanguageContext';
import { languageOptions } from '../translations';
import { Content, Page, Sidebar, Navi } from './MarkdownViewer'
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { authFetch, authPost } from "./../authProvider";

/* Näkymäkohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/Markdown';

const headers = (props) => {
    const { level, children } = props;
    const heading = children[0];
    var anchor = (typeof heading === 'string') ? heading.toLowerCase() : '';
    anchor = anchor.replace(/ä/g, 'a');
    anchor = anchor.replace(/ö/g, 'o');
    anchor = anchor.replace(/[^a-zA-Z0-9 ]/g, '');
    anchor = anchor.replace(/ /g, '-');
    return (<div id={anchor}>
        {level == 1 ? (<h1>{children}</h1>) :
            level == 2 ? (<h2>{children}</h2>) :
                level == 3 ? (<h3>{children}</h3>) :
                    (<h4>{children}</h4>)}
    </div>);
}

// Query parameters
const searchParams = new URLSearchParams(document.location.search);

export class MarkdownPage extends React.Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            selected: null,
            loading: true,
            showIndex: false, //oletuksena esitetään sisällysluettelo
            lang: this.context?.userLanguage
        };

        
    }

    componentDidMount() {
        this.updateData();
    }

    updateData = () => {
        const path = searchParams.get('path') || this.props?.page;

        authFetch(this.props.pca, API_PREFIX + '/Index/' + this.context?.userLanguage)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    structure: data,
                    markdown: null,
                    loading: (path ? true : false),
                    showIndex: false, //!(path),
                    lang: this.context?.userLanguage
                }, () => {
                    if (path) {
                        this.handleSelect(null, path, this.context?.userLanguage);
                    }
                });
            });
    }

    // Haetaan markdown tiedosto
    handleSelect = (event, value, lang) => {
        authPost(this.props.pca, API_PREFIX + '/Read', {
            body: JSON.stringify({ path: value, lang: lang })
        })
            .then(response => response.text())
            .then(data => {
                this.setState({
                    markdown: data,
                    selected: value,
                    showIndex: false,
                    loading: false,
                });
            });
    }

    handleHashLink = (event, value, lang) => {
        authPost(this.props.pca, API_PREFIX + '/Read', {
            body: JSON.stringify({ path: value, lang: lang })
        })
            .then(response => response.text())
            .then(data => {
                this.setState({
                    markdown: data
                });
            });
    }

    render() {
        const { userLanguage, userLanguageChange, dictionary } = this.context;
        const { structure } = this.state;

        // Lista blogeista eli sisällysluettelo
        const bloglist = () => {
            return ((structure || {}).children || []).map((d, i) => {
                return (<Paper key={i} style={{ color: "#000", padding: "20px", maxWidth: "750px" }}>
                    <p><img src={d.image} alt={d.name} style={{ width: "100%", textAlign: "center" }} /></p>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p style={{ textAlign: "left", width: "75%", fontWeight: "bold" }}>{d.name}</p>
                        <p style={{ textAlign: "right" }}>{d.date}</p>
                    </div>
                    <p style={{ textAlign: "left" }}>{d.description}</p>
                    <Button style={{ color: "#000", textAlign: "left", fontWeight: "bold" }} variant="outlined" onClick={() => this.handleSelect(null, d.id, userLanguage)}>Nappi</Button>
                </Paper>);
            });
        }

        return (
            <div style={{ minWidth: 48, minHeight: 48 }}>
                {(this.state.loading) ? (
                    <CircularProgress />
                ) :
                    (this.state.showIndex) ? (<Grid container direction="column" justifyContent="flex-start" alignItems="center" >
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'left' }}>
                            {bloglist()}
                        </Grid>
                    </Grid>
                    ) :
                        (<Page>
                            {/* Paluu sisällykseen eli esitetään sisällysluettelo 
                            <div style={{ paddingLeft: "25px", paddingTop: "10px" }}>
                                <Button style={{ color: "#000", fontWeight: "bold" }} variant="outlined" onClick={() => this.setState({ showIndex: true })} >Nappi</Button>
                            </div>
*/}
                            <Content>
                                <ReactMarkdown
                                    children={this.state.markdown}
                                    remarkPlugins={[remarkGfm]}
                                    rehypePlugins={[rehypeRaw]}
                                    components={{
                                        a: (props) => {
                                            const href = props.href;
                                            return props.href.startsWith('http') ?
                                                (<a style={props.style} target="_blank" href={props.href}>{props.children}</a>) : // External links
                                                props.href.startsWith('#') ?
                                                    (<Link
                                                        component="button"
                                                        variant="p"
                                                        onClick={() => {
                                                            const el = document.getElementById(props.href.slice(1))
                                                            if (el) el.scrollIntoView();
                                                        }}
                                                    >
                                                        {props.children}
                                                    </Link>) : // Same page links
                                                    (<Link
                                                        component="button"
                                                        variant="h3"
                                                        onClick={() => {
                                                            this.handleSelect(null, props.href);
                                                        }}
                                                    >
                                                        {props.children}
                                                    </Link>);  // Render internal links with Link component

                                        },
                                        h2: (props) => {
                                            return (
                                                <h1 style={{ marginBottom: 0 }}>{props.children}</h1>
                                            );
                                        },
                                        p: (props) => {
                                            return (
                                                <p style={{ marginBottom: 0 }}>{props.children}</p>
                                            );
                                        },
                                        img: (props) => {
                                            const src = (props.src.indexOf('ClientApp/public') > -1 ? props.src.substring(props.src.indexOf('ClientApp/public') + 16) : props.src);
                                            return (
                                                <img src={src} style={{ maxWidth: "550px" }} alt={props.alt} />
                                            );
                                        }
                                    }}
                                />
                            </Content>
                        </Page>)
                }
            </div>
        );
    }
}
