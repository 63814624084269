import React from "react";

const IconExcel = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            version="1.1"
            viewBox="0 0 24 24"
		>
            <g>
				<path style={{ fill: "#FFFFFF" }} d="M12.1,2.5H20v15.1h-7.9L12.1,2.5z M10,5.8l-2.3,4.7l2.4,4.8l-2.7-0.2l-1.1-2.6L5.2,15l-2.4-0.1l2.1-4.3l-2-4.3l2.5-0.1l0.9,2.4L7.4,6L10,5.8z"/>
				<g>
					<path style={{ fill: "#21A366" }} d="M7,1h16c0.3,0,0.5,0.1,0.7,0.3C23.9,1.5,24,1.8,24,2.1v19.8c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3H7c-0.6,0-1-0.5-1-1c0,0,0,0,0,0V2.1C6,1.5,6.4,1,7,1L7,1z"/>
					<path style={{ fill: "#185C37" }} d="M6,12v9.9c0,0.6,0.4,1,1,1.1c0,0,0,0,0,0h16c0.6,0,1-0.5,1-1c0,0,0,0,0,0V12L6,12z" />
					<path style={{ fill: "#107C41" }} d="M6,6.5h18V12H6L6,6.5z" />
					<path style={{ fill: "#33C481" }} d="M23,1h-8v16.4h9V2.1c0-0.3-0.1-0.5-0.3-0.7C23.5,1.1,23.3,1,23,1z" />
					<path style={{ fill: "#21A366" }} d="M15,6.5h9V12h-9V6.5z" />
					<path style={{ fill: "#107C41" }} d="M15,12h9v5.5h-9V12z" />
					<g style={{ opacity: 0.67 }} >
						<path style={{ opacity: 0.005, enableBackground: "new" }} d="M12.4,5.5H7.1c-0.2,0-0.4,0.2-0.4,0.4v12.2c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0h5.2c0.4,0,0.8-0.4,0.7-0.8c0,0,0,0,0,0V6.3C13.1,5.9,12.8,5.5,12.4,5.5C12.4,5.5,12.4,5.5,12.4,5.5z"/>
						<path style={{ opacity: 0.1, enableBackground: "new" }} d="M11,7.5H6v12.6h5c0.6,0,1-0.5,1-1V8.5C12,8,11.5,7.6,11,7.5z" />
						<path style={{ opacity: 0.2, enableBackground: "new" }} d="M11,6.8H6v12.6h5c0.6,0,1-0.5,1-1V7.8C12,7.3,11.5,6.8,11,6.8z" />
						<path style={{ opacity: 0.1, enableBackground: "new" }} d="M12.1,5.7H6.9c-0.2,0-0.4,0.2-0.4,0.4v12.2c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0h5.2c0.4,0,0.8-0.4,0.8-0.8c0,0,0,0,0,0V6.5C12.8,6.1,12.5,5.8,12.1,5.7C12.1,5.7,12.1,5.7,12.1,5.7L12.1,5.7z"/>
						<path style={{ opacity: 0.1, enableBackground: "new" }} d="M12.1,6.1H6.8c-0.2,0-0.4,0.2-0.4,0.4c0,0,0,0,0,0v12.1c0,0.2,0.2,0.4,0.4,0.4h5.2c0.4,0,0.7-0.4,0.7-0.8c0,0,0,0,0,0V6.8C12.8,6.4,12.5,6.1,12.1,6.1z"/>
						<path style={{ opacity: 0.001, enableBackground: "new" }} d="M11.2,5.1H6v10.2H5.9V17c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0l4.2-0.8c0.4,0,1.5-0.4,1.5-0.8V5.9C12,5.5,11.7,5.1,11.2,5.1C11.2,5.1,11.2,5.1,11.2,5.1z"/>
						<path style={{ opacity: 0.2, enableBackground: "new" }} d="M11,7.2H6v12.6h5c0.6,0,1-0.5,1-1V8.2C12,7.6,11.5,7.2,11,7.2z" />
						<path style={{ opacity: 0.3, enableBackground: "new" }} d="M11,6.1H6v12.3h5c0.6,0,1-0.5,1-1V7.1C12,6.5,11.6,6.1,11,6.1z" />
						<path style={{ opacity: 0.3, enableBackground: "new" }} d="M11,6.1H6v12.3h5c0.6,0,1-0.5,1-1V7.1C12,6.5,11.5,6.1,11,6.1z" />
						<path style={{ opacity: 0.3, enableBackground: "new" }} d="M11,6.3H6v12.6h5c0.6,0,1-0.4,1-1c0,0,0,0,0,0V7.3C12,6.8,11.5,6.3,11,6.3z" />
						<path style={{ opacity: 0.2, enableBackground: "new" }} d="M11,6.6H6v12.6h5c0.6,0,1-0.5,1-1V7.5C12,7,11.5,6.6,11,6.6z" />
						<path style={{ opacity: 0.1, enableBackground: "new" }} d="M11.9,6.5H6.7c-0.2,0-0.4,0.2-0.4,0.4c0,0,0,0,0,0v11.4c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0h5.2c0.4,0,0.8-0.4,0.7-0.8c0,0,0,0,0,0V7.2C12.7,6.8,12.4,6.5,11.9,6.5L11.9,6.5z"/>
						<path style={{ opacity: 0.2, enableBackground: "new" }} d="M11.4,6.5H6.1c0,0-0.1,0-0.1,0v11.9c0,0,0.1,0,0.1,0h5.2c0.4,0,0.8-0.4,0.7-0.8c0,0,0,0,0,0V7.2C12.1,6.8,11.8,6.4,11.4,6.5C11.4,6.4,11.4,6.4,11.4,6.5L11.4,6.5z"/>
						<path style={{ opacity: 0.3, enableBackground: "new" }} d="M10.1,5.7H6.2C6,5.7,5.9,5.9,5.9,6.1c0,0,0,0,0,0v11.4C5.9,17.8,6,18,6.2,18h5.2c0.4,0,0.8-0.4,0.7-0.8V6.9c0-0.5-1-0.7-1.1-0.9C10.8,5.8,10.4,5.7,10.1,5.7L10.1,5.7z"/>
						<path style={{ opacity: 0.1, enableBackground: "new" }} d="M11.7,7H6.5C6.3,7,6.1,7.2,6.1,7.4v11.4c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0h5.2c0.4,0,0.7-0.4,0.7-0.8V7.8C12.5,7.3,12.1,7,11.7,7L11.7,7z"/>
						<path style={{ opacity: 0.1, enableBackground: "new" }} d="M11.2,5.4H6v10.2H5.9v1.6c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0l4.2-0.8c0.4,0,1.5-0.4,1.5-0.8V6.1C12,5.7,11.7,5.4,11.2,5.4C11.2,5.4,11.2,5.4,11.2,5.4z"/>
						<path style={{ opacity: 0.15, enableBackground: "new" }} d="M11.2,5.6H6v10.2H5.9v1.6c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0l4.2-0.8c0.4,0,1.5-0.4,1.5-0.8V6.3C12,5.9,11.7,5.6,11.2,5.6C11.2,5.6,11.2,5.6,11.2,5.6z"/>
					</g>
					<path style={{ fill: "#107C41" }} d="M1,5.7h10c0.6,0,1,0.5,1,1.1v10.4c0,0.6-0.4,1-1,1.1H1c-0.6,0-1-0.5-1-1c0,0,0,0,0,0V6.8C0,6.2,0.4,5.7,1,5.7z"/>
					<path style={{ fill: "#FFFFFF" }} d="M2.7,15.9L5.1,12L2.9,8.3h1.7l1.2,2.4C5.9,10.9,6,11,6.1,11.2c0.1-0.2,0.2-0.4,0.2-0.5l1.2-2.4h1.6L7,12l2.3,3.8H7.5l-1.4-2.6C6.1,13.1,6,13,6,12.9c0,0.1-0.1,0.2-0.2,0.3l-1.4,2.7L2.7,15.9z"/>
				</g>
            </g>
        </svg >
    );
};

export default IconExcel;