import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { authConfig } from './authProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack'

const themeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#800000',
        },
        secondary: {
            main: '#ffffff',
        },
        error: {
            main: '#ff3100',
        },
        success: {
            main: '#548235',
        },
        warning: {
            main: '#FF6600',
        },
        info: {
            main: '#57B1E3',
        },
        iconText: {
            main: 'rgb(0 0 0 / 65%)',
        },
        white: {
            main: '#fff',
        },
        text: {
            primary: '#303030',
            secondary: '#fff',
            warning: '#000',
        },
    },
    typography: {
        fontFamily: 'Arial Narrow',
    },
    props: {
        MuiTooltip: {
            arrow: true,
        },
    },
    components: {
        MuiTable: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff",
                    color: "#303030ff"
                },
            },
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                sortAction: {
                    '& .MuiTableSortLabel-icon': {
                        color: "#303030ff !important"
                    }
                }
            }
        },
        MUIDataTableToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff",
                    color: "#303030ff"
                },
            }
        },
        MUIDataTableToolbarSelect: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ebebebff",
                    color: "#303030ff"
                },
            }
        },
        //MuiTableCell: {
        //    styleOverrides: {
        //        root: {
        //            backgroundColor: "#fff !important",
        //            color: "#303030ff !important"
        //        }
        //    }
        //},
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "rgb(0 0 0 / 65%) !important"
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    color: "rgb(0 0 0 / 65%) !important"
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "#800000 !important"
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: "#800000 !important"
                }
            }
        },
    }
};

const theme = createTheme(themeOptions);
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const authRequest = {
    scopes: ["openid", "profile"]
};

const initializePca = async (config) => {
    const pca = await PublicClientApplication.createPublicClientApplication(config);
    return pca;
}

initializePca(authConfig)
    .then(authProvider => {
        root.render(
            <BrowserRouter basename={baseUrl}>
                <MsalProvider instance={authProvider}>
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={authRequest}
                    >
                        <ThemeProvider theme={theme}>
                            <SnackbarProvider maxSnack={4} autoHideDuration={4000}>
                                <App />
                            </SnackbarProvider>
                        </ThemeProvider>
                    </MsalAuthenticationTemplate >
                </MsalProvider>
            </BrowserRouter>
        );

        unregister();
    });

