import React from 'react';
import { dictionaryList } from './../translations';

/* User Language from browser */
const userLang = 'fi';
const lang = Object.keys(dictionaryList).indexOf(userLang) > -1 ? userLang : 'fi';

export const LanguageContext = React.createContext({
    userLanguage: lang,
    dictionary: dictionaryList[lang]
});

