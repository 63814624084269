import React from "react";
import Autocomplete from '@mui/lab/Autocomplete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from "@mui/styles";

import { LanguageContext } from './LanguageContext';

const defaultToolbarStyles = {
    iconButton: {
    },
};

class AdminRoolitAdd extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            RoolitId: null,
            AsiakasAvain: null,
            Rooli_Id: null,
            Profiili_Id: null,
            Created: null,
            Updated: null,
            Username: null,
            Active: null,
            OletusRoolit: null,
            roolit: (Object.keys(props.lookupLists.roolit).map(function (d) { return { value: d, label: props.lookupLists.roolit[d] }; }) || []),
            kayttajat: (Object.keys(props.lookupLists.kayttajat).map(function (d) { return { value: d, label: props.lookupLists.kayttajat[d] }; }) || []),
        };
    }

    handleSave = () => {
        this.props.onAddNewRow({
            RoolitId: 1,
            AsiakasAvain: 1,
            Rooli_Id: this.state.Rooli_Id,
            Profiili_Id: this.state.Profiili_Id,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
            Active: this.state.Active,
        });
        this.setState({ open: false });
    }

    closeDialog = (list) => {
        var name = 'open' + list;
        this.setState({ [name]: false })
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleListChange = (event, newValue, item) => {
        if (newValue != null) {
            this.setState({ [item]: newValue.value })
        }
        else {
            this.setState({ [item]: null })
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { dictionary } = this.context;
        const { roolit, kayttajat } = this.state;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.NewRow}>
                    <IconButton onClick={this.handleClickOpen}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.AdminRoolit.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <Autocomplete
                            onChange={(event, newValue) => this.handleListChange(event, newValue, 'Profiili_Id')}
                            options={kayttajat}
                            getOptionLabel={(option) => option.label}
                            style={{ width: 400 }}
                            renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.AdminRoolit.Columns[3]} />}
                        />
                        <Autocomplete
                            onChange={(event, newValue) => this.handleListChange(event, newValue, 'Rooli_Id')}
                            options={roolit}
                            getOptionLabel={(option) => option.label}
                            style={{ width: 400 }}
                            renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.AdminRoolit.Columns[2]} />}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "AdminRoolitAdd" })(AdminRoolitAdd);
