import { SaveProfileSetting } from '.';
/**
 * Formats object to string with double double quotes
 * @param {object} obj
 */
const formatValue = (obj) => JSON.stringify(obj).split('"').join('""');

/**
 * Swap two columns order
 * @param {string} entity
 * @param {Array} cols 
 * @param {Function} callback (iserror, data)
 */
export const ChangeColumnOrder = (pca, entity, cols, callback) => {
    const setting = {
        "key": `columns.${entity}`,
        "value": formatValue(cols)
    };
    SaveProfileSetting(pca, setting, (isError, data) => {
        if (isError) {
            callback(isError, data);
        } else {
            callback(false, cols);
        };
    });
};