import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import { authFetch, authPost } from "../../authProvider";
import ErrorDialog from '../ErrorDialog'
import {
    VictoryChart,
    VictoryScatter,
    VictoryLine,
    VictoryBar,
    VictoryTheme,
    VictoryLegend,
    VictoryTooltip,
    VictoryAxis,
    VictoryArea,
    VictoryPolarAxis,
    VictoryStack
} from "victory";

/* Näkymäkohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/Simulation/SkenaarioData';

//const data1 = [
//    {
//        "id": 1,
//        "name": "Kulutus",
//        "values": [{ "id": "1/-1/-1/-1/-1/2023", "val": "10712143 " }, { "id": "1/-1/-1/-1/-1/2024", "val": 0.000 }],
//        "children": [
//            {
//                "id": 11,
//                "name": "Urheiluhallit Oy",
//                "values": [
//                    { "id": 1, "val": "" },
//                    { "id": 2, "val": -1 },
//                    { "id": 3, "val": 0 },
//                    { "id": 4, "val": 1 }
//                ],
//                "children": [
//                    {
//                        "id": 12,
//                        "name": "Malmi",
//                        "values": [
//                            { "id": 1, "val": "" },
//                            { "id": 2, "val": -1 },
//                            { "id": 3, "val": 0 },
//                            { "id": 4, "val": 1 }
//                        ],
//                        "children": [
//                            {
//                                "id": 121,
//                                "name": "Energia",
//                                "values": [
//                                    { "id": 1, "val": "" },
//                                    { "id": 2, "val": -1 },
//                                    { "id": 3, "val": 0 },
//                                    { "id": 4, "val": 1 }
//                                ],
//                                "children": [
//                                    { "id": 1211, "name": "lämpö", "values": [{ "id": 1, "val": 1000 }, { "id": 2, "val": -1 }, { "id": 3, "val": 0 }, { "id": 4, "val": 1 }] },
//                                    { "id": 1212, "name": "sähkö", "values": [{ "id": 1, "val": 150 }, { "id": 2, "val": -1 }, { "id": 3, "val": 0 }, { "id": 4, "val": 1 }] }
//                                ]
//                            },
//                            { "id": 122, "name": "vesi", "values": [{ "id": 1, "val": 230 }, { "id": 2, "val": -1 }, { "id": 3, "val": 0 }, { "id": 4, "val": 1 }] },
//                            { "id": 123, "name": "jäte", "values": [{ "id": 1, "val": 1000 }, { "id": 2, "val": -1 }, { "id": 3, "val": 0 }, { "id": 4, "val": 1 }] }
//                        ]
//                    }
//                ]
//            }
//        ]
//    },
//    {
//        "id": 2,
//        "name": "Päästöt",
//        "values": [{ "id": "1/-1/-1/-1/-1/2023", "val": "10712143 " }, { "id": "1/-1/-1/-1/-1/2024", "val": 0.000 }],
//        "children": [
//            {
//                "id": 11,
//                "name": "Urheiluhallit Oy",
//                "values": [
//                    { "id": 1, "val": "" },
//                    { "id": 2, "val": -1 },
//                    { "id": 3, "val": 0 },
//                    { "id": 4, "val": 1 }
//                ],
//                "children": [
//                    {
//                        "id": 12,
//                        "name": "Malmi",
//                        "values": [
//                            { "id": 1, "val": "" },
//                            { "id": 2, "val": -1 },
//                            { "id": 3, "val": 0 },
//                            { "id": 4, "val": 1 }
//                        ],
//                        "children": [
//                            {
//                                "id": 121,
//                                "name": "Energia",
//                                "values": [
//                                    { "id": 1, "val": "" },
//                                    { "id": 2, "val": -1 },
//                                    { "id": 3, "val": 0 },
//                                    { "id": 4, "val": 1 }
//                                ],
//                                "children": [
//                                    { "id": 1211, "name": "lämpö", "values": [{ "id": 1, "val": 1000 }, { "id": 2, "val": -1 }, { "id": 3, "val": 0 }, { "id": 4, "val": 1 }] },
//                                    { "id": 1212, "name": "sähkö", "values": [{ "id": 1, "val": 150 }, { "id": 2, "val": -1 }, { "id": 3, "val": 0 }, { "id": 4, "val": 1 }] }
//                                ]
//                            },
//                            { "id": 122, "name": "vesi", "values": [{ "id": 1, "val": 230 }, { "id": 2, "val": -1 }, { "id": 3, "val": 0 }, { "id": 4, "val": 1 }] },
//                            { "id": 123, "name": "jäte", "values": [{ "id": 1, "val": 1000 }, { "id": 2, "val": -1 }, { "id": 3, "val": 0 }, { "id": 4, "val": 1 }] }
//                        ]
//                    }
//                ]
//            }
//        ]
//    }
//];

// Preprocess data for VictoryStack

const preprocessData = (data) => {
    console.log(data);
    const processedData = [];
    data.forEach((parent) => {
        const parentValues = parent.values.map((item) => ({
            x: new Date(item.id.split('/')[5]),
            y: parseFloat(item.val)
        }));
        processedData.push({ name: parent.name, values: parentValues });
    });
    return processedData;
};

export class Testi extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            processedData: [],
            skenaarioId: 11, //TODO: Tulee parametrina tms.
            loading: true
        };
        this.updateData();
    };

    updateData = () => {
        const { skenaarioId } = this.state;

        this.setState({
            loading: true
        });

        authFetch(this.props.pca, API_PREFIX + "/Read/" + skenaarioId)
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    this.setState({
                        data: [JSON.parse(data[0].data)],
                        processedData: preprocessData([JSON.parse(data[0].data)][0]),
                        loading: false
                    });
                }
            });
    }

    handleCellChange = (id, value) => {
        const { skenaarioId } = this.state;

        authPost(this.props.pca, API_PREFIX + '/Upsert', {
            body: JSON.stringify({ skenaarioId, id, value })
        })
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    this.updateData();
                }
            });
    };

    handleError = (error) => {
        var title = '', body = ''
        if (error === 4) {
            title = this.context.dictionary.ErrorDialog.Error4.title;
            body = this.context.dictionary.ErrorDialog.Error4.body;
        } else if (error === 5) {
            title = this.context.dictionary.ErrorDialog.Error5.title;
            body = this.context.dictionary.ErrorDialog.Error5.body;
        } else {
            title = this.context.dictionary.ErrorDialog.Error.title;
            body = this.context.dictionary.ErrorDialog.Error.body;
        }
        this.setState({
            errorShow: true,
            errorTitle: title,
            errorBody: body
        })
    }

    handleErrorClose = () => this.setState({ errorShow: false });

    render() {
        const { dictionary } = this.context;
        const { errorShow, errorTitle, errorBody, data, processedData } = this.state;

        return (
            <div>
                {(this.state.loading) ? (
                    <CircularProgress />
                ) : (
                        <VictoryChart width={600} height={400}>
                            <VictoryLegend x={250} y={10}
                                orientation="horizontal"
                                gutter={20}
                                data={[
                                    { name: 'Kulutus', symbol: { type: 'square' } },
                                    { name: 'Päästöt', symbol: { type: 'circle' } },
                                ]}
                            />
                            <VictoryAxis
                                tickFormat={(x) => new Date(x).getFullYear()}
                            />
                            <VictoryAxis dependentAxis />
                            <VictoryStack colorScale="qualitative">
                                {processedData.map((parent, index) => (
                                    <VictoryBar key={index} data={parent.values} />
                                ))}
                                <VictoryLine
                                    style={{
                                        data: { stroke: "tomato" }
                                    }}
                                    data={processedData.find(parent => parent.name === "Päästöt").values}
                                />
                            </VictoryStack>
                        </VictoryChart>
                )}
            </div>
        );
    }
}