import React from "react";
import PropTypes from 'prop-types';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import { withStyles } from "@mui/styles";
import { LanguageContext } from './LanguageContext';

const defaultToolbarStyles = {
    iconButton: {
    },
};

var iniAsiakas;

class AdminRooliProfiiliEdit extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var data = props.Daatta.filter(function (d) {
            return d.rooliId == props.rowId;
        });

        this.state = {
            open: false,
            RooliId: data[0].rooliId,
            AsiakasAvain: data[0].asiakasAvain,
            RooliTunnus: data[0].rooliTunnus,
            RooliNimi: data[0].rooliNimi,
            Globaali: data[0].globaali,
            Created: data[0].created,
            Updated: data[0].updated,
            Username: data[0].username,
            Active: data[0].active,
            asiakkaat: (Object.keys(props.lookupLists.asiakkaat).map(function (d) { return { value: d, label: props.lookupLists.asiakkaat[d] }; }) || []),
            filteredasiakkaat: (Object.keys(props.lookupLists.asiakkaat).map(function (d) { return { value: d, label: props.lookupLists.asiakkaat[d] }; }) || []),
        };

    }

    UNSAFE_componentWillMount() {
        var a = this.state.AsiakasAvain || '';
        iniAsiakas = this.state.asiakkaat.find(({ value }) => value === a.toString());
    }

    static propTypes = {
        onEditRow: PropTypes.func,
        classes: PropTypes.object,
        blurOnSelect: PropTypes.bool
    };

    handleSave = () => {
        this.props.onEditRow({
            RooliId: this.state.RooliId,
            AsiakasAvain: this.state.AsiakasAvain,
            RooliTunnus: this.state.RooliTunnus,
            RooliNimi: this.state.RooliNimi,
            Globaali: this.state.Globaali,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
            Active: this.state.Active
        });
        this.setState({ open: false });
    }

    render() {
        const { dictionary } = this.context;
        const { lookupLists } = this.props;
        const { asiakkaat } = this.state;

        const handleChange = name => event => {
            this.setState({ [name]: event.target.value });
        };

        var handleClickOpen = () => {
            this.setState({
                open: true,
                filteredasiakkaat: asiakkaat,
            });
        }

        const handleListChange = (event, newValue, item) => {
            if (newValue != null) {
                this.setState({ [item]: newValue.value })
            }
            else {
                this.setState({ [item]: null })
            }
        }

        const handleListStateChange = list => {
            const data = (Object.keys(lookupLists[list]).map(function (d) { return { value: d, label: lookupLists[list][d] }; }) || [])
            this.setState({ [list]: data })
        };

        var handleClose = () => {
            this.setState({ open: false });
        }

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.Edit}>
                    <IconButton onClick={handleClickOpen}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={this.state.open} onClose={handleClose} maxWidth={false} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{dictionary.AdminRooliProfiili.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <input type="hidden" value={this.state.RooliId} />
                        <div style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                defaultValue={iniAsiakas}
                                onChange={(event, newValue) => { handleListChange(event, newValue, 'AsiakasAvain') }}
                                onInputChange={() => { handleListStateChange('asiakkaat') }}
                                options={asiakkaat}
                                getOptionLabel={(option) => option.label}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.AdminRooliProfiili.Columns[1]} />}
                            />
                        </div>
                        <TextField
                            required={true}
                            margin="dense"
                            id="RooliTunnus"
                            label={dictionary.AdminRooliProfiili.Columns[2]}
                            type="text"
                            fullWidth
                            value={this.state.RooliTunnus}
                            onChange={handleChange('RooliTunnus')}
                        />
                        <TextField
                            required={true}
                            margin="dense"
                            id="RooliNimi"
                            label={dictionary.AdminRooliProfiili.Columns[3]}
                            type="text"
                            fullWidth
                            value={this.state.RooliNimi}
                            onChange={handleChange('RooliNimi')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "AdminRooliProfiiliEdit" })(AdminRooliProfiiliEdit);
