// Julkiset kirjastot
import React, { useState, useEffect, useContext } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Sovelluksen komponentit
import { LanguageContext } from "../LanguageContext";

// Funktio
export const ErrorDialog = ({ open, title, body, onClose }) => {
    // Toistuvat muuttujat
    const { dictionary } = useContext(LanguageContext);

    return (
        <React.Fragment>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText color="primary" >
                        {body}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={onClose}>
                        {dictionary.Close}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

