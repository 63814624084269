import { SaveProfileSetting } from '.';

/**
 * Formats number string with double double quotes
 * @param {number} num
 */
const formatValue = (num) => JSON.stringify(num).split('"').join('""');

/**
 * Changes row count of table page
 * @param {string} entity
 * @param {number} num 
 * @param {Function} callback (iserror, data)
 */
export const ChangeRowNumber = (pca, entity, num, callback) => {
    const setting = {
        "key": `rownum.${entity}`,
        "value": formatValue(num)
    };
    SaveProfileSetting(pca, setting, (isError, data) => {
        if (isError) {
            callback(isError, data);
        } else {
            callback(false, num);
        };
    });
}