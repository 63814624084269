import { ApiCall } from '.';

/**
 * Custom sorting for handling also labels corractly
 * @param {string} entity
 * @param {Array} data 
 * @param {string} key 
 * @param {Array} selRows
 * @param {Function} setSelectedRows
 * @param {Function} setRowsSelected
 * @param {Function} onReady
 */
export const DeleteRows = (pca, entity, data, key, selRows, setSelectedRows, setRowsSelected, onReady) => {
    const selectedRows = (selRows?.data || []).map(d => d.dataIndex);

    const handleError = (data) => console.error(data);

    //TODO: Korvataan modaalilla
    if (window.confirm("Haluatko varmasti poistaa seuraavat kohteet: " + selectedRows.map(d => data[d][key]).join(", "))) { 
        let requests = [];
        for (var i = 0; i < selectedRows.length; i++) {
            requests.push(
                ApiCall(pca, entity, "Delete", null, "/" + data[selectedRows[i]][key], (isError, data) => { 
                    if (isError) {
                        handleError(data);
                    }
                })
            );
        }

        Promise.all(requests)
            .then(() => {
                setSelectedRows([]);
                setRowsSelected([]);
                onReady();
            });
    }
}