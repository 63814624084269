import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import AdminRooliProfiiliAdd from "./AdminRooliProfiiliAdd";
import AdminRooliProfiiliEdit from "./AdminRooliProfiiliEdit";
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import cloneDeep from 'lodash.clonedeep';
import SortColButton from "./SortColButton";
import ErrorDialog from './ErrorDialog'
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import { authFetch, authPost } from "./../authProvider";
import { LanguageContext } from './LanguageContext';
import moment from 'moment';

/* Näkymäkohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/Roolit/Rooli';
const API_ROOLIT_PREFIX = 'api/Roolit/Roolit';
const API_PROFIILIT_PREFIX = 'api/Roolit/Profiili';
const API_ASIAKKAAT_PREFIX = 'api/Asiakas';

var default_columns = [
    {
        label: "RooliId",
        name: "rooliId",
        options: {
            filter: false,
            display: false
        }
    },
    {
        label: "Organisaatio",
        name: "asiakasAvain",
        options: {
            filter: false,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                return asiakkaat[value];
            }
        },
        lookuplist: 'asiakkaat'
    },
    {
        label: "RooliTunnus",
        name: "rooliTunnus",
        options: {
            filter: true,
            display: true
        }
    },
    {
        label: "RooliNimi",
        name: "rooliNimi",
        options: {
            filter: true,
            display: true
        }
    },
    {
        label: "Globaali",
        name: "globaali",
        options: {
            filter: false,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                return (<Checkbox checked={value} />);
            },
        }
    },
    {
        label: "Aktivoitu",
        name: "aktivoitu",
        options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                const rooliTunnus = tableMeta.tableData[tableMeta.currentTableData[tableMeta.rowIndex].index].rooliTunnus;
                return (
                    <Switch
                        disabled={!(that.state.profiilit.length > 0)}
                        checked={value}
                        onChange={(e) => {
                            that.aktivoiRooli(rooliTunnus, e.target.checked)
                        }
                        }
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                );
            }
        }
    },
    {
        label: "Luotu",
        name: "created",
        options: {
            filter: false,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                var date = moment(value).format("D.M.YYYY, HH:mm");
                return moment(value).isValid() ? date : "";
            },
        }
    },
    {
        label: "Muokattu",
        name: "updated",
        options: {
            filter: false,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                var date = moment(value).format("D.M.YYYY, HH:mm");
                return moment(value).isValid() ? date : "";
            },
        }
    },
    {
        label: "Muokkaaja",
        name: "username",
        options: {
            filter: false,
            display: false
        }
    },
    {
        label: "Käytössä",
        name: "active",
        options: {
            filter: false,
            display: false
        }
    }
];

/* Perusmuuttujat ja vakiot */
const PROFIILI_GET = 'api/Profiili/Read';
const PROFIILI_PUT = 'api/Profiili/Update';

var items = [];
var users = [];
var selectedRows = [];
var rowId;
var asiakkaat = {};
var that;

/* Luokka */
export class AdminRooliProfiili extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        // State alustukset
        this.state = {
            lookupLists: {
                asiakkaat: asiakkaat
            },
            data: null,
            userdata: null,
            loading: true,
            multiple: false,
            rowNum: 25,
            errorShow: false,
            errorTitle: '',
            errorBody: '',
            rowsSelected: [],
            organisaatio: 2, //Startecon oletuksena
            profiilit: [],
            sortOrder: { name: 'riviAvain', direction: 'asc' },
        };

        //Bindit
        this.swapCols = this.swapCols.bind(this);
        this.addNewItem = this.addNewItem.bind(this);

        that = this;

        //organisaatiot
        authFetch(this.props.pca, API_ASIAKKAAT_PREFIX + '/Read')
            .then(response => response.json())
            .then(data => {
                data.forEach(function (d, i) {
                    asiakkaat[d.riviAvain] = (d.asiakasNimi || 'ei tietoja');
                });

                authFetch(this.props.pca, API_PROFIILIT_PREFIX + '/Read/2') //Startecon oletuksena
                    .then(response => response.json())
                    .then(data => {
                        users = data;

                        const o = {
                            org: 2, //Startecon oletuksena
                            users: null
                        }

                        //Items
                        authPost(this.props.pca, API_PREFIX + "/Read/1", { // Moodi = 1 Org kohtainen
                            body: JSON.stringify(o)
                        })
                            .then(response => response.json())
                            .then(data => {
                                items = data

                                //Profiili
                                authFetch(this.props.pca, PROFIILI_GET)
                                    .then(response => response.json())
                                    .then(data => {
                                        var cols = (((JSON.parse((data || [])[0].uI_Settings) || {}).columns || {}).rooli || []); // TODO: muuta avain
                                        if (cols.length > 0) cols = JSON.parse(cols);
                                        else cols = null;

                                        // hidden cols
                                        var hidden_cols = (((JSON.parse((data || [])[0].uI_Settings) || {}).hiddencolumns || {}).rooli || []); // TODO: muuta avain
                                        if (hidden_cols.length > 0) hidden_cols = JSON.parse(hidden_cols);
                                        else hidden_cols = null;

                                        // rowNum
                                        var rowNum = (((JSON.parse((data || [])[0].uI_Settings) || {}).rownum || {}).rooli || []); // TODO: muuta avain
                                        if (rowNum.length > 0) rowNum = JSON.parse(rowNum);
                                        else rowNum = 25;

                                        var columns = get_ordered_columns(cols, hidden_cols);

                                        this.setState({
                                            lookupLists: {
                                                asiakkaat: asiakkaat
                                            },
                                            columns: columns,
                                            data: items,
                                            userdata: users,
                                            loading: false,
                                            rowNum: rowNum
                                        });
                                    });
                            });
                    });
            });
    }

    swapCols = (index) => {
        if (index > 1) {
            var cols = cloneDeep(this.state.columns);
            var d = this.state.data;
            [cols[index - 1], cols[index]] = [cols[index], cols[index - 1]];
            this.setState({ columns: cols });
            var setting = { "key": "columns.rooli", "value": JSON.stringify(cols.map(function (d) { return d.name; })).split('"').join('""') }; // TODO: muuta avain
            authPost(this.props.pca, PROFIILI_PUT, {
                body: JSON.stringify(setting)
            });
        }
    };

    changeRownum = (num) => {
        this.setState({ rowNum: num })

        var setting = { "key": "rownum.rooli", "value": JSON.stringify(num).split('"').join('""') }; // TODO: muuta avain
        authPost(this.props.pca, PROFIILI_PUT, {
            body: JSON.stringify(setting)
        })
    };

    hiddenCols = (changedColumn, action) => {
        var cols = cloneDeep(this.state.columns);
        var hidden = [];

        for (var i = 0; i < cols.length; i++) {
            if (!(cols[i].options))
                cols[i].options = {};

            if (cols[i].name == changedColumn)
                cols[i].options.display = (action === "add" ? true : false)

            if (cols[i].options.display == false) {
                hidden.push(cols[i].name);
            }
        }

        if (action === "add") {
            hidden.splice(hidden.indexOf(changedColumn), 1);
        }
        else if (action === "remove") {
            hidden.push(changedColumn);
        }

        this.setState({ columns: cols });
        var setting = { "key": "hiddencolumns.rooli", "value": (hidden.length > 0 ? JSON.stringify(hidden).split('"').join('""') : "[]") }; // TODO: muuta avain
        authPost(this.props.pca, PROFIILI_PUT, {
            body: JSON.stringify(setting)
        })
    };

    handleError = (error) => {
        const { onError } = this.props;

        var title = '', body = ''
        if (error === 4) {
            title = this.context.dictionary.ErrorDialog.Error4.title;
            body = this.context.dictionary.ErrorDialog.Error4.body;
        } else if (error === 5) {
            title = this.context.dictionary.ErrorDialog.Error5.title;
            body = this.context.dictionary.ErrorDialog.Error5.body;
        } else {
            title = this.context.dictionary.ErrorDialog.Error.title;
            body = this.context.dictionary.ErrorDialog.Error.body;
        }

        onError(title, body);
    }

    handleErrorClose = () => this.setState({ errorShow: false });

    addNewItem = (newrow) => {
        const { organisaatio, profiilit } = this.state;

        // ADD
        var data = cloneDeep(this.state.data);
        data = data.concat(newrow);
        authPost(this.props.pca, API_PREFIX + '/Create', {
            body: JSON.stringify(newrow)
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    const o = {
                        org: organisaatio,
                        users: (profiilit.length > 0 ? profiilit.join(",") : null)
                    }

                    authPost(this.props.pca, API_PREFIX + "/Read/1", {
                        body: JSON.stringify(o)
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                data: data,
                                loading: false
                            });
                            this.handleMessage(this.context.dictionary.Message.Add)
                        });
                }
            });
    }

    editItem = (newrow) => {
        const { organisaatio, profiilit } = this.state;

        // EDIT
        authPost(this.props.pca, API_PREFIX + '/Update', {
            body: JSON.stringify(newrow)
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    const o = {
                        org: organisaatio,
                        users: (profiilit.length > 0 ? profiilit.join(",") : null)
                    }

                    authPost(this.props.pca, API_PREFIX + "/Read/1", {
                        body: JSON.stringify(o)
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                data: data,
                                loading: false
                            });
                            this.handleMessage(this.context.dictionary.Message.Edit)
                        });
                }
            });
    }

    aktivoiRooli = (id, moodi) => {
        const { organisaatio, profiilit } = this.state;

        const o = {
            org: organisaatio,
            role: id,
            users: (profiilit.length > 0 ? profiilit.join(",") : null)
        }

        // ACTIVATE
        authPost(this.props.pca, API_ROOLIT_PREFIX + '/Activate/' + (moodi ? "1" : "0"), {
            body: JSON.stringify(o)
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    const o = {
                        org: organisaatio,
                        users: (profiilit.length > 0 ? profiilit.join(",") : null)
                    }

                    authPost(this.props.pca, API_PREFIX + "/Read/1", {
                        body: JSON.stringify(o)
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                data: data,
                                loading: false
                            });
                        });
                }
            });
    }

    handleMessage = (msg) => {
        const { onMessage } = this.props;
        onMessage(msg);
    }

    handleSortChange = (changedColumn, direction) => {
        this.setState({
            sortOrder: { name: changedColumn, direction },
        });
    };

    render() {
        const { userLanguage, dictionary } = this.context;
        const { errorShow, errorTitle, errorBody, lookupLists, organisaatio, profiilit, userdata } = this.state;

        var cols = cloneDeep(this.state.columns);
        if ((cols || []).length > 0) {
            for (var i = 0; i < default_columns.length; i++) {
                for (var j = 0; j < cols.length; j++) {
                    if (cols[j].label == default_columns[i].label) {
                        cols[j].label = dictionary.AdminRooliProfiili.Columns[i]
                    }
                }
            }
        }

        const organisaatiot = (Object.keys(lookupLists.asiakkaat).map((d, i) =>
            <MenuItem key={i} value={d}>{lookupLists.asiakkaat[d]}</MenuItem>
        ));

        const kayttajat = (userdata || []).filter(d => d.asiakasAvain == organisaatio).map((d, i) => (
            <MenuItem key={i} value={d.username}>
                <Checkbox checked={profiilit.indexOf(d.username) > -1} />
                <ListItemText primary={d.username} />
            </MenuItem>
        ));

        const handleChangeOrgs = (event) => {
            const val = event.target.value;

            authFetch(this.props.pca, API_PROFIILIT_PREFIX + '/Read/' + val)
                .then(response => response.json())
                .then(data => {
                    users = data;
                    const o = {
                        org: val,
                        users: null
                    }

                    authPost(this.props.pca, API_PREFIX + "/Read/1", {
                        body: JSON.stringify(o)
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                data: data,
                                userdata: users,
                                loading: false,
                                organisaatio: val,
                                profiilit: []
                            });
                        });
                });
        };

        const handleChangeUsers = (event) => {
            let val = typeof value === 'string' ? event.target.value.split(',') : event.target.value;

            if (val[val.length - 1] === "all") {
                val = (profiilit.length == kayttajat.length ? [] : (userdata || []).filter(d => d.asiakasAvain == organisaatio).map(d => (d.username)));
            }

            const o = {
                org: organisaatio,
                users: (val.length > 0 ? val.join(",") : null)
            }

            authPost(this.props.pca, API_PREFIX + "/Read/1", {
                body: JSON.stringify(o)
            })
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        data: data,
                        loading: false,
                        profiilit: val
                    });
                });
        };

        //"columnHeaderTooltip": ((column) => `Lajitteluperuste: ${column.label}`)

        var options = {
            textLabels: dictionary.MuiDataTable.textLabels,
            textLabels: dictionary.MuiDataTable.textLabels,
            selectableRowsHeader: false,
            selectableRowsOnClick: false,
            selectableRows: false,
            filterType: 'multiselect',
            responsive: "vertical",
            tableBodyHeight: "calc(100vh - 270px)",
            rowsPerPageOptions: [5, 10, 25, 50, 100],
            rowsPerPage: this.state.rowNum,
            jumpToPage: true,
            rowsSelected: this.state.rowsSelected,
            sortOrder: this.state.sortOrder,
            onColumnSortChange: this.handleSortChange,
            onViewColumnsChange: (changedColumn, action) => {
                this.hiddenCols(changedColumn, action);
            },
            onRowSelectionChange: (currentRowsSelected, allRows, rowsSelected) => {
                const { data } = this.state;
                rowId = null;
                if (rowsSelected.length > 0) {
                    rowId = data[rowsSelected[0]].rooliId; //TODO: Muuta avain
                }
                this.setState({ rowsSelected: rowsSelected });
            },
            onChangeRowsPerPage: (num) => {
                this.changeRownum(num);
            },
            // Huomioi samat arvot ja sen jölkeen lajitellaan ensimmäisen sarakkeen (id) mukaan
            customSort: (data, colIndex, order) => {
                const { columns, lookupLists } = this.state;

                return data.sort((a, b) => {
                    if (a.data[colIndex] == b.data[colIndex]) {
                        // samanarvoisissa riveissä otetaan riviavainjärjestys
                        return ((a.data[0] || -9e6) < (b.data[0] || -9e6) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    } else if (columns[colIndex].options.lookuplist) {
                        // lookuplist lajittelu
                        const list = lookupLists[columns[colIndex].options.lookuplist];
                        var aa = (list[a.data[colIndex]] || ''), bb = (list[b.data[colIndex]] || '');

                        return ((parseInt(!/[^0-9]/i.test(aa) ? aa : 'x') || aa.toString()) < (parseInt(!/[^0-9]/i.test(bb) ? bb : 'x') || bb.toString()) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    } else {
                        // normaali lajittelu
                        return ((a.data[colIndex] || -9e6) < (b.data[colIndex] || -9e6) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    }
                });
            },
            customToolbarSelect: (selRows, displayData, setSelectedRows) => {
                const { data } = this.state;

                if ((selRows.data || []).length > 0) {
                    selectedRows = selRows.data.map(d => (d.dataIndex));
                    rowId = data[selectedRows[0]].rooliId; //TODO: Muuta avain
                }

                var handleDelete = () => {
                    var data = cloneDeep(this.state.data);

                    if (window.confirm("Haluatko varmasti poistaa seuraavat kohteet: " + selectedRows.map(d => data[d].rooliId).join(", "))) { //TODO: Muuta avain
                        var requests = []
                        for (var i = 0; i < selectedRows.length; i++) {
                            requests.push(
                                authPost(this.props.pca, API_PREFIX + '/Delete/' + data[selectedRows[i]].rooliId, { method: 'delete' }) //TODO: Muuta avain
                            );
                        }
                        const o = {
                            org: 2, //Startecon oletuksena
                            users: null
                        }
                        Promise.all(requests)
                            .then(() => {
                                setSelectedRows([]);
                                authPost(this.props.pca, API_PREFIX + "/Read/1", {
                                    body: JSON.stringify(o)
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        this.handleMessage(`(${selectedRows.length}) ${dictionary.Message.Delete}`)
                                        this.setState({ data: data, rowsSelected: [] });
                                    });
                            });
                    }
                }

                return (
                    <div className={"custom-toolbar-select"}>
                        <React.Fragment>
                            {/*
                            {(selectedRows.length < 2) ? (<AdminRooliProfiiliEdit onEditRow={this.editItem} lookupLists={this.state.lookupLists} Daatta={this.state.data} rowId={rowId} />) : null}
                            <Tooltip title={dictionary.Toolbar.Delete}>
                                <IconButton onClick={handleDelete}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            */}
                        </React.Fragment>
                    </div>
                );
            },
            customToolbar: () => {
                return (
                    <React.Fragment>
                        {/* <AdminRooliProfiiliAdd onAddNewRow={this.addNewItem} lookupLists={this.state.lookupLists} Daatta={this.state.data} orgId={organisaatio} /> */}
                        <SortColButton columns={this.state.columns} onOrderChange={this.swapCols} />
                    </React.Fragment>
                );
            }

        };

        //dictionary.<komponentin nimi>.Title
        return (
            <div style={{ padding: "10px" }}>
                {(this.state.loading) ? (
                    <CircularProgress />
                ) : (
                    <React.Fragment>
                        <div style={{ paddingLeft: "15px", paddingBottom: "10px", display: "flex" }}>
                            <div>
                                <InputLabel>{dictionary.AdminProfiili.Columns[1]}</InputLabel>
                                <Select style={{ width: '500px' }} value={this.state.organisaatio} onChange={handleChangeOrgs}>
                                    {organisaatiot}
                                </Select>
                            </div>
                            <div style={{ marginLeft: 8 }}>
                                <InputLabel>{dictionary.AdminProfiili.Columns[2]}</InputLabel>
                                <Select
                                    style={{ width: '500px' }}
                                    value={this.state.profiilit}
                                    onChange={handleChangeUsers}
                                    renderValue={(selected) => selected.join(', ')}
                                    multiple
                                >
                                    <MenuItem value="all">
                                        <Checkbox checked={kayttajat.length > 0 && profiilit.length === kayttajat.length} />
                                        <ListItemText primary={(userLanguage == "en" ? "Select All" : "Valitse kaikki")} />
                                    </MenuItem>
                                    {kayttajat}
                                </Select>
                            </div>
                        </div>
                        <MUIDataTable
                            title={dictionary.AdminRooliProfiili.Title}
                            data={this.state.data}
                            columns={cols}
                            options={options}
                        />
                    </React.Fragment>
                )
                }
                
            </div>
        );
    }
}

function get_ordered_columns(list, hidden) {
    var columns = [];

    if (!Array.isArray(list)) {
        list = default_columns.map((c) => (c.name));
    }

    list.forEach(function (el) {
        columns.push((default_columns.filter(function (d) { return d.name == el; }) || [])[0]);
        if (hidden != null) {
            if (!(columns[columns.length - 1].options))
                columns[columns.length - 1].options = {};
            if (hidden.indexOf(columns[columns.length - 1].name) > -1) {
                columns[columns.length - 1].options.display = false;
            } else {
                columns[columns.length - 1].options.display = true;
            }
        }
    });

    return columns;
}