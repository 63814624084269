import React, { Component, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, Dialog, DialogContent, DialogTitle, Tooltip, Box, Tab, Button } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight, TableChart, Close, Check } from '@mui/icons-material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import { authFetch, authPost } from "../../authProvider";
import { Graafi } from "./Graafi";
import ErrorDialog from "../ErrorDialog";
import { LanguageContext } from "../LanguageContext";
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

/* Näkymäkohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/Simulation/SkenaarioData';
const API_GRAAFI_PREFIX = 'api/Simulation/SkenaarioGraafi';

const Row = ({ handleValue, row, level = 1 }) => {
    const [open, setOpen] = useState(false);
    const [editingCell, setEditingCell] = useState(null);
    const [editedValue, setEditedValue] = useState('');

    const handleValueChange = (id, val) => {
        handleValue(id, val);
    };

    const handleCheckClick = (id, val) => {
        handleValueChange(id, val);
        setEditingCell(null);
    };

    const handleCellClick = (index, val) => {
        setEditingCell(index);
        setEditedValue(val);
    };

    const handleCellBlur = () => {
        setEditingCell(null);
    };

    return (
        <React.Fragment>
            <TableRow sx={{ backgroundColor: level === 1 ? "#f5f5f5" : "#fff" }}>
                <TableCell style={{ width: "300px", fontWeight: 'bold', paddingLeft: `${level * 25}px` }}>
                    {row.name}
                    {row.children && row.children.length > 0 && (
                        <IconButton
                            aria-label={open ? 'Collapse' : 'Expand'}
                            onClick={() => setOpen(!open)}
                            size="small"
                            style={{ marginLeft: 'auto' }}
                        >
                            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                        </IconButton>
                    )}
                </TableCell>
                {row.values.map((value, index) => (
                    <TableCell key={index} style={{ width: "150px", padding: "4px" }}>
                        {editingCell === index && index !== 0 ? (
                            <React.Fragment>
                                <TextField variant="standard" margin="none" size="small" autoFocus value={editedValue} onChange={(e) => setEditedValue(e.target.value)} style={{ width: "45px" }} />
                                <IconButton onClick={() => handleCheckClick(value.id, editedValue)}><Check style={{ fontSize: "20px" }} /></IconButton>
                                <IconButton onClick={handleCellBlur}><Close style={{ fontSize: "20px" }} /></IconButton>
                            </React.Fragment>
                        ) : (
                            <div onClick={() => handleCellClick(index, value.val)} style={{ cursor: index !== 0 ? 'pointer' : 'auto' }} >{value.val}</div>
                        )}
                    </TableCell>
                ))}
            </TableRow>
            {row.children && row.children.length > 0 && open && (
                row.children.map(child => (
                    <Row key={child.id} row={child} level={level + 1} handleValue={handleValue} />
                ))
            )}
        </React.Fragment>
    );
};

const renderTreeItems = (nodes) => {
    if (!nodes) return null;

    return nodes.map((node) => {
        const currentIndex = ((node.values || [])[0] || {}).id;
        return (
            <TreeItem key={currentIndex} itemId={currentIndex} label={node.name}>
                {renderTreeItems(node.children)}
            </TreeItem>
        );
    });
};

const TreeViewComponent = ({ data, onChange }) => {
    return (
        <SimpleTreeView multiSelect onSelectedItemsChange={onChange} checkboxSelection>
            {renderTreeItems(data)}
        </SimpleTreeView>
    );
};

export default class SkenariointiSisalto extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        const data = props.Daatta.filter(function (d) {
            return d.riviAvain === props.rowId;
        });

        this.state = {
            open: false,
            data: [],
            dataLuokka: [],
            loading: true,
            skenaarioId: data[0].riviAvain,
            SkenaarioNimi: data[0].skenaarioNimi,
            SkenaarioKuvaus: data[0].skenaarioKuvaus,
            Omistaja: data[0].omistaja,
            Alku: data[0].alku,
            Loppu: data[0].loppu,
            Asetukset: data[0].asetukset,
            Jaettu: data[0].jaettu,
            tab: "1",
            selectedItems: [],
            errorShow: false,
            errorTitle: '',
            errorBody: '',
        };

        this.updateData();
        this.updateGraafiData(null);
        this.updateDataLuokka();
    };

    updateData = () => {
        const { skenaarioId } = this.state;

        authFetch(this.props.pca, API_PREFIX + "/Read/" + skenaarioId)
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    this.setState({
                        data: JSON.parse(data[0].data),
                        loading: false
                    });
                }
            });
    }

    updateDataLuokka = () => {
        const { skenaarioId } = this.state;

        authFetch(this.props.pca, API_PREFIX + "/Read/Luokka/" + skenaarioId)
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    this.setState({
                        dataLuokka: JSON.parse(data[0].data),
                        loading: false
                    });
                }
            });
    }

    updateGraafiData = (items) => {
        const { skenaarioId } = this.state;

        authPost(this.props.pca, API_GRAAFI_PREFIX + "/Read", {
            body: JSON.stringify({
                skenaarioId,
                items: items ? (items || []).join(",") : null
            })
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    this.setState({
                        graafidata: data,
                        loading: false
                    });
                }
            });
    }

    handleCellChange = (id, value) => {
        const { skenaarioId } = this.state;

        authPost(this.props.pca, API_PREFIX + "/Upsert", {
            body: JSON.stringify({ skenaarioId, id, value })
        })
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    this.updateData();
                    this.updateDataLuokka();
                    this.updateGraafiData();
                }
            });
    };

    handleError = (error) => {
        var title = '', body = ''
        if (error === 4) {
            title = this.context.dictionary.ErrorDialog.Error4.title;
            body = this.context.dictionary.ErrorDialog.Error4.body;
        } else if (error === 5) {
            title = this.context.dictionary.ErrorDialog.Error5.title;
            body = this.context.dictionary.ErrorDialog.Error5.body;
        } else {
            title = this.context.dictionary.ErrorDialog.Error.title;
            body = this.context.dictionary.ErrorDialog.Error.body;
        }
        this.setState({
            errorShow: true,
            errorTitle: title,
            errorBody: body
        })
    }

    handleErrorClose = () => this.setState({ errorShow: false });

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleChange = (e, newValue) => {
        this.setState({ tab: newValue })
    };

    handleFilter = (event, items) => {
        console.log(JSON.stringify({ items }));

        this.updateGraafiData(items);
    };

    render() {
        const { dictionary } = this.context;
        const { errorShow, errorTitle, errorBody, data, dataLuokka, open, graafidata, SkenaarioNimi, Alku, Loppu } = this.state;

        const alkuVuosi = parseInt(Alku ? Alku.substr(0, 4) : "1970");
        const loppuVuosi = parseInt(Loppu ? Loppu.substr(0, 4) : "9999");
        const timeScale = [alkuVuosi, loppuVuosi];

        return (
            <React.Fragment>
                <Tooltip title="Muokkaa skenaariota">
                    <IconButton onClick={this.handleClickOpen}>
                        <TableChart />
                    </IconButton>
                </Tooltip>

                <Dialog open={open} onClose={this.handleClose} maxWidth={false}>
                    <DialogTitle>{SkenaarioNimi}</DialogTitle>
                    <DialogContent style={{ width: "90vw", height: "90vh" }}>
                        {(this.state.loading) ? (
                            <CircularProgress />
                        ) : (
                            <React.Fragment>
                                <Box style={{ display: "flex" }}>
                                    <Graafi
                                        title={"Skenaario " + alkuVuosi + "-" + loppuVuosi}
                                        barsLegend={"Päästöt (tCO2)"}
                                        linesLegend={"Kulutus (Eur)"}
                                        data={graafidata}
                                        barSeries={"sarja2"}
                                        lineSeries={"sarja1"}
                                        timeScale={timeScale}
                                        width={1000}
                                        height={300}
                                    />
                                    <Box sx={{ height: 360, flexGrow: 1, maxWidth: 400, overflowY: "scroll" }}>
                                        <TreeViewComponent
                                            data={(data || [])[0]?.children}
                                            onChange={this.handleFilter}
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <TableContainer component={Paper}>
                                        <TabContext value={this.state.tab}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: "#f5f5f5" }}>
                                                <TabList onChange={this.handleChange} aria-label="lab API tabs example">
                                                    <Tab label="Kulutuspaikka" value="1" sx={{ color: "#000" }} />
                                                    <Tab label="Luokka" value="2" sx={{ color: "#000" }} />
                                                </TabList>
                                            </Box>
                                            <TabPanel value="1" style={{ padding: "0px" }}>
                                                <Table style={{ overflowX: 'auto', width: (loppuVuosi - alkuVuosi) * 150 + 300, padding: "20px" }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center" colSpan={12}>
                                                                Arvioitu muutos-%
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            {Array.from({ length: loppuVuosi - alkuVuosi + 1 }, (_, index) => alkuVuosi + index).map(d => (<TableCell key={d}>{d}</TableCell>))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(data || []).map(row => (
                                                            <Row key={row.id} row={row} handleValue={this.handleCellChange} />
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TabPanel>
                                            <TabPanel value="2" style={{ padding: "0px" }}>
                                                <Table style={{ overflowX: 'auto', width: (loppuVuosi - alkuVuosi) * 150 + 300, padding: "20px" }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center" colSpan={12}>
                                                                Arvioitu muutos-%
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            {Array.from({ length: loppuVuosi - alkuVuosi + 1 }, (_, index) => alkuVuosi + index).map(d => (<TableCell key={d}>{d}</TableCell>))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(dataLuokka || []).map(row => (
                                                            <Row key={row.id} row={row} handleValue={this.handleCellChange} />
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TabPanel>
                                        </TabContext>
                                    </TableContainer>
                                </Box>
                            </React.Fragment>
                        )}
                        <ErrorDialog open={errorShow} title={errorTitle} body={errorBody} onClose={this.handleErrorClose} />
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}