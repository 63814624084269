import React from "react";

const IconArrowDown = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            version="1.1"
            viewBox="0 0 30 30"
        >
            <g>
                <polygon points="26.9,10.2 30,10.2 30,30 10.2,30 10.2,26.9 23.4,26.9 24.7,26.9 23.8,26 0,2.2 2.2,0 26,23.8 26.9,24.7 26.9,23.4 "/>
            </g>
        </svg>
    );
};

export default IconArrowDown;