import React from "react";

const IconKarpon = ({ color }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 472 472"
            xmlns="http://www.w3.org/2000/svg">
            <g
                
                transform="translate(-273.718,-212.747)">
                <path
                    d="M 504.832,222.747 273.718,448.745 V 222.747 Z"
                    fill={color}/>
                <path
                    d="M 504.832,674.781 H 273.718 V 448.745 Z"
                    fill={color} />
                <path
                    d="M 745.324,222.747 504.831,448.744 745.324,674.781 H 601.028 L 365.224,448.744 601.028,222.747 Z"
                    fill={color} />
            </g>
        </svg>
    );
};

export default IconKarpon;