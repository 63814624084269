import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from "@mui/styles";
import { LanguageContext } from './LanguageContext';

const defaultToolbarStyles = {
    iconButton: {
    },
};

class AdminRooliEdit extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var data = props.Daatta.filter(function (d) {
            return d.rooliId === props.rowId;
        });

        this.state = {
            open: false,
            RooliId: data[0].rooliId,
            AsiakasAvain: data[0].asiakasAvain,
            RooliTunnus: data[0].rooliTunnus,
            RooliNimi: data[0].rooliNimi,
            Globaali: data[0].globaali,
            Created: data[0].created,
            Updated: data[0].updated,
            Username: data[0].username,
            Active: data[0].active,
        };

    }

    handleSave = () => {
        this.props.onEditRow({
            RooliId: this.state.RooliId,
            AsiakasAvain: this.state.AsiakasAvain,
            RooliTunnus: this.state.RooliTunnus,
            RooliNimi: this.state.RooliNimi,
            Globaali: this.state.Globaali,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
            Active: this.state.Active
        });
        this.setState({ open: false });
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { dictionary } = this.context;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.Edit}>
                    <IconButton onClick={this.handleClickOpen}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.AdminRooli.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <TextField
                            margin="dense"
                            id="RooliTunnus"
                            label={dictionary.AdminRooli.Columns[2]}
                            type="text"
                            fullWidth
                            value={this.state.RooliTunnus || ""}
                            onChange={this.handleChange('RooliTunnus')}
                        />
                        <TextField
                            required={true}
                            margin="dense"
                            id="RooliNimi"
                            label={dictionary.AdminRooli.Columns[3]}
                            type="text"
                            fullWidth
                            value={this.state.RooliNimi || ""}
                            onChange={this.handleChange('RooliNimi')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "AdminRooliEdit" })(AdminRooliEdit);
