import { ApiCall } from '.';

/**
 * Custom sorting for handling also labels corractly
 * @param {string} entity
 * @param {Array} data 
 * @param {string} key 
 * @param {Array} selRows
 * @param {Function} setSelectedRows
 * @param {Function} setRowsSelected
 * @param {Function} onReady
 */
export const CopyRows = (pca, entity, data, key, selRows, setSelectedRows, setRowsSelected, onCopy, onReady) => {
    const selectedRows = (selRows?.data || []).map(d => d.dataIndex);

    const handleError = (data) => console.error(data);

    //TODO: Korvataan modaalilla
    if (window.confirm("Haluatko varmasti kopioida seuraavat kohteet: " + selectedRows.map(d => data[d][key]).join(", "))) {
        let requests = []
        for (let i = 0; i < selectedRows.length; i++) {
            const row = data[selectedRows[i]];
            const newrow = onCopy(row);
            requests.push(
                ApiCall(pca, entity, "Create", newrow, null, (isError, data) => {
                    if (isError) {
                        handleError(data);
                    }
                })
            );
        }

        Promise.all(requests)
            .then(() => {
                setSelectedRows([]);
                setRowsSelected([]);
                onReady();
            });
    }
}