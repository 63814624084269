import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from "@mui/styles";
import { LanguageContext } from './LanguageContext';

const defaultToolbarStyles = {
    iconButton: {
    },
};

class AdminRooliAdd extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            RooliId: null,
            AsiakasAvain: null,
            RooliTunnus: null,
            RooliNimi: null,
            Globaali: 1,
            Created: null,
            Updated: null,
            Username: null,
            Active: null
        };
    }

    handleSave = () => {
        this.props.onAddNewRow({
            RooliId: 1, //dummy-arvo
            AsiakasAvain: 1,
            RooliTunnus: this.state.RooliTunnus,
            RooliNimi: this.state.RooliNimi,
            Globaali: 0,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
            Active: this.state.Active
        });
        this.setState({ open: false });
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { dictionary } = this.context;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.NewRow}>
                    <IconButton onClick={this.handleClickOpen}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.AdminRooli.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <TextField
                            margin="dense"
                            id="RooliTunnus"
                            label={dictionary.AdminRooli.Columns[2]}
                            type="text"
                            fullWidth
                            value={this.state.RooliTunnus || ""}
                            onChange={this.handleChange('RooliTunnus')}
                        />
                        <TextField
                            required={true}
                            margin="dense"
                            id="RooliNimi"
                            label={dictionary.AdminRooli.Columns[3]}
                            type="text"
                            fullWidth
                            value={this.state.RooliNimi || ""}
                            onChange={this.handleChange('RooliNimi')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "AdminRooliAdd" })(AdminRooliAdd);
