import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from "@mui/styles";

import { LanguageContext } from '../LanguageContext';
import Osoitehaku from "./Osoitehaku";

const defaultToolbarStyles = {
    iconButton: {
    },
};


class OrganisaatioAdd extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            openAddressSearch: false,
            RiviAvain: null,
            EmoOrganisaatioAvain: null,
            AsiakasAvain: null,
            Ytunnus: null,
            Nimi: null,
            Kuvaus: null,
            OsoiteId: null,
            Katuosoite: null,
            ToimialaId: null,
            Created: null,
            Updated: null,
            Username: null,
            asiakkaat: (Object.keys(props.lookupLists.asiakkaat).map((d) => ({ value: d, label: props.lookupLists.asiakkaat[d] })) || []),
            organisaatiot: (Object.keys(props.lookupLists.organisaatiot).map((d) => ({ value: d, label: props.lookupLists.organisaatiot[d] })) || []),
            toimialat: (Object.keys(props.lookupLists.toimialat).map((d) => ({ value: d, label: props.lookupLists.toimialat[d] })) || []),
        };
    }

    handleSave = () => {
        this.props.onAddNewRow({
            RiviAvain: 1,
            EmoOrganisaatioAvain: this.state.EmoOrganisaatioAvain,
            AsiakasAvain: this.state.AsiakasAvain,
            Ytunnus: this.state.Ytunnus,
            Nimi: this.state.Nimi,
            Kuvaus: this.state.Kuvaus,
            OsoiteId: this.state.OsoiteId,
            Katuosoite: this.state.Katuosoite,
            ToimialaId: this.state.ToimialaId,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
        });
        this.setState({ open: false });
    };

    closeDialog = (list) => {
        var name = 'open' + list;
        this.setState({ [name]: false })
    };

    handleAddressSelect = (osoite) => {
        this.setState({
            OsoiteId: osoite.riviAvain,
            Katuosoite: osoite.katuosoite,
            openAddressSearch: false,
        })
    }

    handleAddressSearchClose = (event) => {
        this.setState({ openAddressSearch: false });
    }

    handleAddressSearchOpen = (event) => {
        this.setState({ openAddressSearch: true });
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleListChange = (event, newValue, item) => {
        if (newValue != null) {
            this.setState({ [item]: newValue.value })
        }
        else {
            this.setState({ [item]: null })
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { dictionary } = this.context;
        const { open, openAddressSearch, asiakkaat, organisaatiot, toimialat } = this.state;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.NewRow}>
                    <IconButton onClick={this.handleClickOpen}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.Inventory.Organisaatio.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <Box style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                onChange={(event, newValue) => this.handleListChange(event, newValue, 'EmoOrganisaatioAvain')}
                                options={organisaatiot}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.Inventory.Organisaatio.Columns[1]} />}
                            />
                        </Box>
                        <Box style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                onChange={(event, newValue) => this.handleListChange(event, newValue, 'AsiakasAvain')}
                                options={asiakkaat}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.Inventory.Organisaatio.Columns[2]} />}
                            />
                        </Box>
                        <TextField
                            margin="dense"
                            id="Ytunnus"
                            label={dictionary.Inventory.Organisaatio.Columns[3]}
                            type="text"
                            fullWidth
                            value={this.state.Ytunnus}
                            onChange={this.handleChange('Ytunnus')}
                        />
                        <TextField
                            margin="dense"
                            id="Nimi"
                            label={dictionary.Inventory.Organisaatio.Columns[4]}
                            type="text"
                            fullWidth
                            value={this.state.Nimi}
                            onChange={this.handleChange('Nimi')}
                        />
                        <TextField
                            margin="dense"
                            id="Kuvaus"
                            label={dictionary.Inventory.Organisaatio.Columns[5]}
                            type="text"
                            fullWidth
                            value={this.state.Kuvaus}
                            onChange={this.handleChange('Kuvaus')}
                        />
                        <Box style={{ display: 'flex', alignItems: "center" }} >
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.handleAddressSearchOpen}
                                style={{ heioght: 36, marginRight: 8 }}
                            >
                                {dictionary.Inventory.Osoitehaku.Title}
                            </Button>
                            <TextField
                                margin="dense"
                                id="Katuosoite"
                                InputProps={{
                                    readOnly: true,
                                }}
                                required
                                label={dictionary.Inventory.Organisaatio.Columns[6]}
                                type="text"
                                fullWidth
                                value={this.state.Katuosoite}
                            />
                        </Box>
                        <Box style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                onChange={(event, newValue) => this.handleListChange(event, newValue, 'ToimialaId')}
                                options={toimialat}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.Inventory.Organisaatio.Columns[8]} />}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Osoitehaku
                    open={openAddressSearch}
                    pca={this.props.pca}
                    onSelected={this.handleAddressSelect}
                    onClose={this.handleAddressSearchClose}
                />
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "OrganisaatioAdd" })(OrganisaatioAdd);
