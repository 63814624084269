import React from "react";
import PropTypes from 'prop-types';
import Autocomplete from '@mui/lab/Autocomplete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { withStyles } from "@mui/styles";
import { LanguageContext } from './LanguageContext';

const defaultToolbarStyles = {
    iconButton: {
    },
};

class KaruselliAdd extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            showHtml: false,
            open: false,
            RiviAvain: null,
            TietopalveluAvain: null,
            Toiminto_Id: null,
            Osio: null,
            Kieli: null,
            RaporttiAvain: null,
            Kieli: null,
            Järjestys: null,
            Otsikko: null,
            Alaotsikko: null,
            Nosto: null,
            Teksti: null,
            Linkki: null,
            LinkkiTeksti: null,
            Pvm: null,
            Created: null,
            Updated: null,
            Username: null,
            Active: null,
            tietopalvelut: (Object.keys(props.lookupLists.tietopalvelut).map(function (d) { return { value: d, label: props.lookupLists.tietopalvelut[d] }; }) || []),
            filteredtietopalvelut: (Object.keys(props.lookupLists.tietopalvelut).map(function (d) { return { value: d, label: props.lookupLists.tietopalvelut[d] }; }) || []),
            toiminnot: (Object.keys(props.lookupLists.toiminnot).map(function (d) { return { value: d, label: props.lookupLists.toiminnot[d] }; }) || []),
            filteredtoiminnot: (Object.keys(props.lookupLists.toiminnot).map(function (d) { return { value: d, label: props.lookupLists.toiminnot[d] }; }) || []),
        };
    }

    static propTypes = {
        onAddNewRow: PropTypes.func,
        classes: PropTypes.object,
        blurOnSelect: PropTypes.bool
    };

    handleSave = () => {
        this.props.onAddNewRow({
            RiviAvain: 1, //dummy-arvo
            TietopalveluAvain: this.state.TietopalveluAvain,
            Toiminto_Id: this.state.Toiminto_Id,
            Osio: this.state.Osio,
            Kieli: this.state.Kieli,
            Jarjestys: this.state.Jarjestys,
            Otsikko: this.state.Otsikko,
            Alaotsikko: this.state.Alaotsikko,
            Nosto: this.state.Nosto,
            Teksti: this.state.Teksti,
            Linkki: this.state.Linkki,
            LinkkiTeksti: this.state.LinkkiTeksti,
            Pvm: this.state.Pvm,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
            Active: this.state.Active
        });
        this.setState({ open: false });
    }

    render() {
        const { dictionary } = this.context;
        const { lookupLists } = this.props;
        const { tietopalvelut, toiminnot } = this.state;

        const handleChange = name => event => {
            this.setState({ [name]: event.target.value });
        };

        const handleClickOpen = () => {
            this.setState({
                open: true,
                filteredtietopalvelut: tietopalvelut,
                filteredtoiminnot: toiminnot,
            });
        }

        const handleListChange = (event, newValue, item) => {
            if (newValue != null) {
                this.setState({ [item]: newValue.value })
            }
            else {
                this.setState({ [item]: null })
            }
        }

        const handleListStateChange = list => {
            const data = (Object.keys(lookupLists[list]).map(function (d) { return { value: d, label: lookupLists[list][d] }; }) || [])
            this.setState({ [list]: data })
        };

        const handleClose = () => {
            this.setState({ open: false });
        }

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.NewRow}>
                    <IconButton onClick={handleClickOpen}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={this.state.open} onClose={handleClose} maxWidth="lg" fullWidth>
                    <DialogTitle>{dictionary.Karuselli.Title}</DialogTitle>

                    <DialogContent>
                        <div style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                onChange={(event, newValue) => { handleListChange(event, newValue, 'TietopalveluAvain') }}
                                onInputChange={() => { handleListStateChange('tietopalvelut') }}
                                options={tietopalvelut}
                                getOptionLabel={(option) => option.label}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.Karuselli.Columns[1]} />}
                            />
                        </div>
                        <div style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                options={toiminnot}
                                getOptionLabel={(option) => option.label}
                                style={{ width: 400 }}
                                onChange={(event, newValue) => { handleListChange(event, newValue, 'Toiminto_Id') }}
                                onInputChange={() => { handleListStateChange('toiminnot') }}
                                renderInput={(params) => (
                                    <TextField {...params} required={true} variant="standard" label={dictionary.Karuselli.Columns[2]} />
                                )}
                            />
                        </div>
                        <TextField
                            margin="dense"
                            id="Osio"
                            label={dictionary.Karuselli.Columns[3]}
                            type="text"
                            fullWidth
                            value={this.state.Osio}
                            onChange={handleChange('Osio')}
                        />
                        <FormControl fullWidth>
                            <InputLabel id="select-label-kieli">{dictionary.Karuselli.Columns[4]}</InputLabel>
                            <Select
                                labelId="select-label-kieli"
                                value={this.state.Kieli}
                                label={dictionary.Karuselli.Columns[4]}
                                onChange={handleChange('Kieli')}
                            >
                                <MenuItem value="fi">fi</MenuItem>
                                <MenuItem value="en">en</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            id="Jarjestys"
                            label={dictionary.Karuselli.Columns[5]}
                            type="text"
                            fullWidth
                            value={this.state.Jarjestys}
                            onChange={handleChange('Jarjestys')}
                        />
                        <TextField
                            margin="dense"
                            id="Otsikko"
                            label={dictionary.Karuselli.Columns[6]}
                            type="text"
                            fullWidth
                            value={this.state.Otsikko}
                            onChange={handleChange('Otsikko')}
                        />
                        <TextField
                            margin="dense"
                            id="Alaotsikko"
                            label={dictionary.Karuselli.Columns[7]}
                            type="text"
                            fullWidth
                            value={this.state.Alaotsikko}
                            onChange={handleChange('Alaotsikko')}
                        />
                        <TextField
                            margin="dense"
                            id="Nosto"
                            label={dictionary.Karuselli.Columns[8]}
                            type="text"
                            fullWidth
                            value={this.state.Nosto}
                            onChange={handleChange('Nosto')}
                        />
                        <TextField
                            margin="dense"
                            id="Teksti"
                            label={dictionary.Karuselli.Columns[9]}
                            type="text"
                            fullWidth
                            multiline
                            value={this.state.Teksti}
                            onChange={handleChange('Teksti')}
                        />
                        <TextField
                            margin="dense"
                            id="Linkki"
                            label={dictionary.Karuselli.Columns[10]}
                            type="text"
                            fullWidth
                            value={this.state.Linkki}
                            onChange={handleChange('Linkki')}
                        />
                        <TextField
                            margin="dense"
                            id="LinkkiTeksti"
                            label={dictionary.Karuselli.Columns[11]}
                            type="text"
                            fullWidth
                            value={this.state.LinkkiTeksti}
                            onChange={handleChange('LinkkiTeksti')}
                        />
                        <TextField
                            margin="dense"
                            id="Pvm"
                            label={dictionary.Karuselli.Columns[12]}
                            type="text"
                            fullWidth
                            value={this.state.Pvm}
                            onChange={handleChange('Pvm')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "KaruselliAdd" })(KaruselliAdd);
