import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import AdminRooliOikeudetAdd from "./AdminRooliOikeudetAdd";
import AdminRooliOikeudetEdit from "./AdminRooliOikeudetEdit";
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import cloneDeep from 'lodash.clonedeep';
import SortColButton from "./SortColButton";
import ErrorDialog from './ErrorDialog'
import FileCopyIcon from "@mui/icons-material/FileCopy";

import { authFetch, authPost } from "./../authProvider";
import { LanguageContext } from './LanguageContext';
import moment from 'moment';

/* Näkymäkohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/Roolit/RooliOikeudet';
const API_TOIMINNOT_PREFIX = 'api/Toiminto/Read';
const API_ROOLIT_PREFIX = 'api/Roolit/Rooli';

var default_columns = [
    {
        label: "RooliOikeudetId",
        name: "rooliOikeudetId",
        options: {
            filter: false
        }
    },
    {
        label: "Rooli",
        name: "rooli_Id",
        options: {
            filter: true,
            customBodyRender: function (value, tableMeta, updateValue) {
                return roolit[value];
            },
            lookuplist: 'roolit'
        }
    },
    {
        label: "Toiminto",
        name: "toiminto_Id",
        options: {
            filter: true,
            customBodyRender: function (value, tableMeta, updateValue) {
                return toiminnot[value];
            },
            lookuplist: 'toiminnot'
        }
    },
    {
        label: "Luotu",
        name: "created",
        options: {
            filter: false,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                var date = moment(value).format("D.M.YYYY, HH:mm");
                return moment(value).isValid() ? date : "";
            },
        }
    },
    {
        label: "Muokattu",
        name: "updated",
        options: {
            filter: false,
            display: false,
            customBodyRender: function (value, tableMeta, updateValue) {
                var date = moment(value).format("D.M.YYYY, HH:mm");
                return moment(value).isValid() ? date : "";
            },
        }
    },
    {
        label: "Muokkaaja",
        name: "username",
        options: {
            filter: true,
            display: false
        }
    },
    {
        label: "Käytössä",
        name: "active",
        options: {
            filter: true,
            display: false
        }
    }
];

/* Perusmuuttujat ja vakiot */
const PROFIILI_GET = 'api/Profiili/Read';
const PROFIILI_PUT = 'api/Profiili/Update';

var items = [];
var toiminnot = {};
var roolit = {};
var selectedRows = [];
var rowId;

/* Luokka */
export class AdminRooliOikeudet extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        // State alustukset
        this.state = {
            lookupLists: {
                roolit: roolit,
                toiminnot: toiminnot
            },
            multiple: false,
            hidden: [],
            loading: true,
            rowNum: 25,
            errorShow: false,
            errorTitle: '',
            errorBody: '',
            rowsSelected: [],
            sortOrder: { name: 'riviAvain', direction: 'asc' },
        };

        //Bindit
        this.swapCols = this.swapCols.bind(this);
        this.addNewItem = this.addNewItem.bind(this);

        //roolit
        authFetch(this.props.pca, API_ROOLIT_PREFIX + '/Read') 
            .then(response => response.json())
            .then(data => {
                data.forEach(function (d, i) {
                    roolit[d.rooliId] = (d.rooliNimi || 'ei tietoja');
                });

                //toiminnot
                authFetch(this.props.pca, API_TOIMINNOT_PREFIX + '/Read')
                    .then(response => response.json())
                    .then(data => {
                        data.forEach(function (d, i) {
                            toiminnot[d.toimintoId] = (d.toiminto || 'ei tietoja');
                        });
                        //Items
                        authFetch(this.props.pca, API_PREFIX + '/Read')
                            .then(response => response.json())
                            .then(data => {
                                items = data

                                //Profiili
                                authFetch(this.props.pca, PROFIILI_GET)
                                    .then(response => response.json())
                                    .then(data => {
                                        var cols = (((JSON.parse((data || [])[0].uI_Settings) || {}).columns || {}).roolioikeudet || []);
                                        if (cols.length > 0) cols = JSON.parse(cols);
                                        else cols = null;

                                        // hidden cols
                                        var hidden_cols = (((JSON.parse((data || [])[0].uI_Settings) || {}).hiddencolumns || {}).roolioikeudet || []);
                                        if (hidden_cols.length > 0) hidden_cols = JSON.parse(hidden_cols);
                                        else hidden_cols = null;

                                        // rowNum
                                        var rowNum = (((JSON.parse((data || [])[0].uI_Settings) || {}).rownum || {}).roolioikeudet || []);
                                        if (rowNum.length > 0) rowNum = JSON.parse(rowNum);
                                        else rowNum = 25;

                                        var columns = get_ordered_columns(cols, hidden_cols);

                                        this.setState({
                                            lookupLists: {
                                                toiminnot: toiminnot,
                                                roolit: roolit
                                            },
                                            columns: columns,
                                            data: items,
                                            loading: false,
                                            rowNum: rowNum
                                        });
                                    });
                            });
                    });
            });
    }

    swapCols = (index) => {
        if (index > 1) {
            var cols = cloneDeep(this.state.columns);
            var d = this.state.data;
            [cols[index - 1], cols[index]] = [cols[index], cols[index - 1]];
            this.setState({ columns: cols });
            var setting = { "key": "columns.roolioikeudet", "value": JSON.stringify(cols.map(function (d) { return d.name; })).split('"').join('""') };
            authPost(this.props.pca, PROFIILI_PUT, {
                body: JSON.stringify(setting)
            });
        }
    };

    changeRownum = (num) => {
        this.setState({ rowNum: num })

        var setting = { "key": "rownum.roolioikeudet", "value": JSON.stringify(num).split('"').join('""') };
        authPost(this.props.pca, PROFIILI_PUT, {
            body: JSON.stringify(setting)
        })
    };

    hiddenCols = (changedColumn, action) => {
        var cols = cloneDeep(this.state.columns);
        var hidden = [];

        for (var i = 0; i < cols.length; i++) {
            if (!(cols[i].options))
                cols[i].options = {};

            if (cols[i].name == changedColumn)
                cols[i].options.display = (action === "add" ? true : false)

            if (cols[i].options.display == false) {
                hidden.push(cols[i].name);
            }
        }

        if (action === "add") {
            hidden.splice(hidden.indexOf(changedColumn), 1);
        }
        else if (action === "remove") {
            hidden.push(changedColumn);
        }

        this.setState({ columns: cols });
        var setting = { "key": "hiddencolumns.roolioikeudet", "value": (hidden.length > 0 ? JSON.stringify(hidden).split('"').join('""') : "[]") }; // TODO: muuta avain
        authPost(this.props.pca, PROFIILI_PUT, {
            body: JSON.stringify(setting)
        })
    };

    handleError = (error) => {
        const { onError } = this.props;

        var title = '', body = ''
        if (error === 4) {
            title = this.context.dictionary.ErrorDialog.Error4.title;
            body = this.context.dictionary.ErrorDialog.Error4.body;
        } else if (error === 5) {
            title = this.context.dictionary.ErrorDialog.Error5.title;
            body = this.context.dictionary.ErrorDialog.Error5.body;
        } else {
            title = this.context.dictionary.ErrorDialog.Error.title;
            body = this.context.dictionary.ErrorDialog.Error.body;
        }

        onError(title, body);
    }

    handleErrorClose = () => this.setState({ errorShow: false });

    addNewItem = (newrow) => {
        // ADD
        authPost(this.props.pca, API_PREFIX + '/Create', {
            body: JSON.stringify(newrow)
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    authFetch(this.props.pca, API_PREFIX + '/Read')
                        .then(response => response.json())
                        .then(data => {
                            this.setState({ data: data, rowsSelected: [] })
                            this.handleMessage(this.context.dictionary.Message.Add)
                        });
                }
            });
    }

    editItem = (newrow) => {
        // EDIT
        authPost(this.props.pca, API_PREFIX + '/Update', {
            body: JSON.stringify(newrow)
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    authFetch(this.props.pca, API_PREFIX + '/Read')
                        .then(response => response.json())
                        .then(data => {
                            this.setState({ data: data, rowsSelected: [] })
                            this.handleMessage(this.context.dictionary.Message.Edit)
                        });
                }
            });
    }

    handleMessage = (msg) => {
        const { onMessage } = this.props;
        onMessage(msg);
    }

    handleSortChange = (changedColumn, direction) => {
        this.setState({
            sortOrder: { name: changedColumn, direction },
        });
    };

    render() {
        const { userLanguage, dictionary } = this.context;
        const { errorShow, errorTitle, errorBody } = this.state;

        const cols = cloneDeep(this.state.columns);
        if ((cols || []).length > 0) {
            for (let i = 0; i < default_columns.length; i++) {
                for (let j = 0; j < cols.length; j++) {
                    if (cols[j].label == default_columns[i].label) {
                        cols[j].label = dictionary.AdminRooliOikeudet.Columns[i]
                    }
                }
            }
        }

        const options = {
			textLabels: dictionary.MuiDataTable.textLabels,
            selectableRowsHeader: true,
            selectableRowsOnClick: true,
            selectableRows: "multiple",
            filterType: 'multiselect',
            responsive: "vertical",
            tableBodyHeight: "calc(100vh - 180px)",
            rowsPerPageOptions: [5, 10, 25, 50, 100],
            rowsPerPage: this.state.rowNum,
            jumpToPage: true,
            rowsSelected: this.state.rowsSelected,
            sortOrder: this.state.sortOrder,
            onColumnSortChange: this.handleSortChange,
            onViewColumnsChange: (changedColumn, action) => {
                this.hiddenCols(changedColumn, action);
            },
            onRowSelectionChange: (currentRowsSelected, allRows, rowsSelected) => {
                const { data } = this.state;
                rowId = null;
                if (rowsSelected.length > 0) {
                    rowId = data[rowsSelected[0]].riviAvain; //TODO: Muuta avain
                }
                this.setState({ rowsSelected: rowsSelected });
            },
            onChangeRowsPerPage: (num) => {
                this.changeRownum(num);
            },
            // Huomioi samat arvot ja sen jölkeen lajitellaan ensimmäisen sarakkeen (id) mukaan
            customSort: (data, colIndex, order) => {
                const { columns, lookupLists } = this.state;

                return data.sort((a, b) => {
                    if (a.data[colIndex] == b.data[colIndex]) {
                        // samanarvoisissa riveissä otetaan riviavainjärjestys
                        return ((a.data[0] || -9e6) < (b.data[0] || -9e6) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    } else if (columns[colIndex].options.lookuplist) {
                        // lookuplist lajittelu
                        const list = lookupLists[columns[colIndex].options.lookuplist];
                        var aa = (list[a.data[colIndex]] || ''), bb = (list[b.data[colIndex]] || '');

                        return ((parseInt(!/[^0-9]/i.test(aa) ? aa : 'x') || aa.toString()) < (parseInt(!/[^0-9]/i.test(bb) ? bb : 'x') || bb.toString()) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    } else {
                        // normaali lajittelu
                        return ((a.data[colIndex] || -9e6) < (b.data[colIndex] || -9e6) ? -1 : 1) * (order === 'desc' ? 1 : -1);
                    }
                });
            },
            customToolbarSelect: (selRows, displayData, setSelectedRows) => {
                const { data } = this.state;

                if ((selRows.data || []).length > 0) {
                    selectedRows = selRows.data.map(d => (d.dataIndex));
                    rowId = data[selectedRows[0]].rooliOikeudetId; //TODO: Muuta avain
                }

                var handleDelete = () => {
                    var data = cloneDeep(this.state.data);

                    if (window.confirm(dictionary.Toolbar.Confirmation + selectedRows.map(d => data[d].rooliOikeudetId).join(", "))) { //TODO: Muuta avain
                        var requests = []
                        for (var i = 0; i < selectedRows.length; i++) {
                            requests.push(
                                authPost(this.props.pca, API_PREFIX + '/Delete/' + data[selectedRows[i]].rooliOikeudetId, { method: 'delete' }) //TODO: Muuta avain
                            );
                        }
                        Promise.all(requests)
                            .then(() => {
                                setSelectedRows([]);
                                authFetch(this.props.pca, API_PREFIX + '/Read')
                                    .then(response => response.json())
                                    .then(data => {
                                        this.handleMessage(`(${selectedRows.length}) ${dictionary.Message.Delete}`)
                                        this.setState({ data: data, rowsSelected: [] });
                                    });
                            });
                    }
                }

                var handleCopy = () => {
                    const { data } = this.state;
                    if (window.confirm(dictionary.Toolbar.Confirmation2 + selectedRows.map(d => data[d].rooliOikeudetId).join(", "))) { //TODO: Muuta avain
                        var requests = []
                        for (var i = 0; i < selectedRows.length; i++) {
                            const row = data[selectedRows[i]];
                            var newrow = {
                                RooliOikeudetId: 1,
                                Rooli_Id: row.rooli_Id,
                                Toiminto_Id: row.toiminto_Id,
                                Created: row.created,
                                Updated: row.updated,
                                Username: row.username,
                                Active: row.active
                            };
                            requests.push(
                                authPost(this.props.pca, API_PREFIX + '/Create', { body: JSON.stringify(newrow) })
                            );
                        }
                        Promise.all(requests)
                            .then(() => {
                                authFetch(this.props.pca, API_PREFIX + '/Read')
                                    .then(response => response.json())
                                    .then(data => {
                                        this.handleMessage(`(${selectedRows.length}) ${dictionary.Message.Copy}`)
                                        this.setState({ data: data, rowsSelected: [] });
                                    });
                            });
                    }
                }
                // TODO: Edit kohtaan oma toiminto
                return (
                    <div className={"custom-toolbar-select"}>
                        {(selectedRows.length < 2) ? (
                            <React.Fragment>
                                <Tooltip title={dictionary.Toolbar.Copy}>
                                    <IconButton onClick={handleCopy}>
                                        <FileCopyIcon />
                                    </IconButton>
                                </Tooltip>
                                <AdminRooliOikeudetEdit onEditRow={this.editItem} lookupLists={this.state.lookupLists} Daatta={this.state.data} rowId={rowId} />
                                <Tooltip title={dictionary.Toolbar.Delete}>
                                    <IconButton onClick={handleDelete}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Tooltip title={dictionary.Toolbar.Copy}>
                                    <IconButton onClick={handleCopy}>
                                        <FileCopyIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={dictionary.Toolbar.Delete}>
                                    <IconButton onClick={handleDelete}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </React.Fragment>
                        )}
                    </div>
                );
            },
            customToolbar: () => {
                return (
                    <React.Fragment>
                        <AdminRooliOikeudetAdd onAddNewRow={this.addNewItem} lookupLists={this.state.lookupLists} />
                        <SortColButton columns={cols} onOrderChange={this.swapCols} />
                    </React.Fragment>
                );
            }
        };
        return (
            <div style={{ padding: "10px" }}>
                {(this.state.loading) ? (
                    <CircularProgress />
                ) : (
                    <MUIDataTable
                        title={dictionary.AdminRooliOikeudet.Title}
                        data={this.state.data}
                        columns={cols}
                        options={options}
                    />
                )
                }
                
            </div>
        );
    }
}

function get_ordered_columns(list, hidden) {
    const columns = [];

    if (!Array.isArray(list)) {
        list = default_columns.map((c) => (c.name));
    }

    list.forEach(function (el) {
        columns.push((default_columns.filter(function (d) { return d.name == el; }) || [])[0]);
        if (hidden != null) {
            if (!(columns[columns.length - 1].options))
                columns[columns.length - 1].options = {};
            if (hidden.indexOf(columns[columns.length - 1].name) > -1) {
                columns[columns.length - 1].options.display = false;
            } else {
                columns[columns.length - 1].options.display = true;
            }
        }
    });

    return columns;
}