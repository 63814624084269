import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from "@mui/styles";

import { LanguageContext } from '../LanguageContext';
import Osoitehaku from "./Osoitehaku";

const defaultToolbarStyles = {
    iconButton: {
    },
};

var iniOrganisaatio;
var iniEmo;

class KustannuspaikkaEdit extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var data = props.Daatta.filter(function (d) {
            return d.riviAvain == props.rowId;
        });

        this.state = {
            open: false,
            openAddressSearch: false,
            RiviAvain: data[0].riviAvain,
            EmoKustannuspaikkaAvain: data[0].emoKustannuspaikkaAvain,
            OrganisaatioAvain: data[0].organisaatioAvain,
            Nimi: data[0].nimi,
            Kuvaus: data[0].kuvaus,
            OsoiteId: data[0].osoiteId,
            Katuosoite: data[0].katuosoite,
            Created: data[0].created,
            Updated: data[0].updated,
            Username: data[0].username,
            organisaatiot: (Object.keys(props.lookupLists.organisaatiot).map((d) => ({ value: d, label: props.lookupLists.organisaatiot[d] })) || []),
            kustannuspaikat: (Object.keys(props.lookupLists.kustannuspaikat).map((d) => ({ value: d, label: props.lookupLists.kustannuspaikat[d] })) || []),
        };

    }

    UNSAFE_componentWillMount() {
        iniOrganisaatio = this.state.organisaatiot.find(({ value }) => value === (this.state.OrganisaatioAvain || '').toString());
        iniEmo = this.state.kustannuspaikat.find(({ value }) => value === (this.state.EmoKustannuspaikkaAvain || '').toString());
    }

    handleSave = () => {
        this.props.onEditRow({
            RiviAvain: this.state.RiviAvain,
            EmoKustannuspaikkaAvain: this.state.EmoKustannuspaikkaAvain,
            OrganisaatioAvain: this.state.OrganisaatioAvain,
            Nimi: this.state.Nimi,
            Kuvaus: this.state.Kuvaus,
            OsoiteId: this.state.OsoiteId,
            Katuosoite: this.state.Katuosoite,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
        });
        this.setState({ open: false });
    }

    closeDialog = (list) => {
        var name = 'open' + list;
        this.setState({ [name]: false })
    };

    handleAddressSelect = (osoite) => {
        this.setState({
            OsoiteId: osoite.riviAvain,
            Katuosoite: osoite.katuosoite,
            openAddressSearch: false,
        })
    }

    handleAddressSearchClose = (event) => {
        this.setState({ openAddressSearch: false });
    }

    handleAddressSearchOpen = (event) => {
        this.setState({ openAddressSearch: true });
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleListChange = (event, newValue, item) => {
        if (newValue != null) {
            this.setState({ [item]: newValue.value })
        }
        else {
            this.setState({ [item]: null })
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { dictionary } = this.context;
        const { open, openAddressSearch, organisaatiot, kustannuspaikat } = this.state;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.Edit}>
                    <IconButton onClick={this.handleClickOpen}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.Inventory.Kustannuspaikka.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <Box style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                defaultValue={iniEmo}
                                onChange={(event, newValue) => this.handleListChange(event, newValue, 'EmoKustannuspaikkaAvain')}
                                options={kustannuspaikat}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.Inventory.Kustannuspaikka.Columns[1]} />}
                            />
                        </Box>
                        <Box style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                defaultValue={iniOrganisaatio}
                                onChange={(event, newValue) => this.handleListChange(event, newValue, 'OrganisaatioAvain')}
                                options={organisaatiot}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.Inventory.Kustannuspaikka.Columns[2]} />}
                            />
                        </Box>
                        <TextField
                            margin="dense"
                            id="Nimi"
                            label={dictionary.Inventory.Kustannuspaikka.Columns[3]}
                            type="text"
                            fullWidth
                            value={this.state.Nimi}
                            onChange={this.handleChange('Nimi')}
                        />
                        <TextField
                            margin="dense"
                            id="Kuvaus"
                            label={dictionary.Inventory.Kustannuspaikka.Columns[4]}
                            type="text"
                            fullWidth
                            value={this.state.Kuvaus}
                            onChange={this.handleChange('Kuvaus')}
                        />
                        <Box style={{ display: 'flex', alignItems: "center" }} >
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.handleAddressSearchOpen}
                                style={{ heioght: 36, marginRight: 8 }}
                            >
                                {dictionary.Inventory.Osoitehaku.Title}
                            </Button>
                            <TextField
                                margin="dense"
                                id="Katuosoite"
                                InputProps={{
                                    readOnly: true,
                                }}
                                required
                                label={dictionary.Inventory.Organisaatio.Columns[6]}
                                type="text"
                                fullWidth
                                value={this.state.Katuosoite}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Osoitehaku
                    open={openAddressSearch}
                    pca={this.props.pca}
                    onSelected={this.handleAddressSelect}
                    onClose={this.handleAddressSearchClose}
                />
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "KustannuspaikkaEdit" })(KustannuspaikkaEdit);
