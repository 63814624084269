import { SaveProfileSetting } from '.';
import cloneDeep from 'lodash.clonedeep';

/**
 * Formats columns array string with double double quotes
 * @param {Array} cols
 */
const columnNames = (cols) => JSON.stringify(cols).split('"').join('""');

/**
 * Swap two columns order
 * @param {string} entity
 * @param {string} changedColumn 
 * @param {Array} cols 
 * @param {string} action
 * @param {Function} callback (iserror, data)
 */
export const HideColumn = (pca, entity, changedColumn, cols, action, callback) => {
    let hidden = cloneDeep(cols);

    if (action === "add") {
        hidden.splice(hidden.indexOf(changedColumn), 1);
    }
    else if (action === "remove") {
        hidden.push(changedColumn);
    }

    const setting = {
        "key": `hiddencolumns.${entity}`,
        "value": columnNames(hidden)
    };

    SaveProfileSetting(pca, setting, (isError, data) => {
        if (isError) {
            callback(isError, data);
        } else {
            callback(false, hidden);
        };
    });
};