import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import cloneDeep from 'lodash.clonedeep';
import { LanguageContext } from '../LanguageContext';
import { authFetch, authPost } from "../../authProvider";

const API_PREFIX = 'api/Inventory/Osoite';

export default class Osoitehaku extends React.Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            showHtml: false,
            open: false,
            features: null,
            feature: null,
            address: ""
        };

    }

    handleClose = event => {
        const { onClose } = this.props;
        onClose();
    }

    handleSave = event => {
        const { onSelected } = this.props;
        const { feature } = this.state;

        if (feature) {
            const latlng = feature.geometry?.coordinates.length === 2 ? feature.geometry.coordinates : [null, null];
            const { localadmin, label, street, housenumber, postalcode } = feature.properties;

            const newrow = {
                RiviAvain: 1, //dummy
                Kunta: localadmin || null,
                Katuosoite: label || null,
                Kadunnimi: street || null,
                Katunumero: housenumber || null,
                Postinumero: postalcode || null,
                Latitude: latlng[1],
                Longitude: latlng[0],
                Created: null,
                Updated: null,
                Username: null,
            }

            //Upsert to DB and get Id
            authPost(this.props.pca, API_PREFIX + '/Upsert', {
                body: JSON.stringify(newrow)
            })
                .then(response => response.json())
                .then(data => {
                    if ((data || {}).error) {
                        console.error(data.error);
                    } else {
                        onSelected((data || [])[0]);
                    }
                });
        } else {
            console.error("ERROR: Feature cannot be null.")
        }
    }

    handleSearchTextChange = (event, value) => {
        if ((value || '').length > 4) {
            const str = value.toString().toLowerCase()
                .replace("å", "a")
                .replace("ä", "a")
                .replace("ö", "o");

            authFetch(this.props.pca, API_PREFIX + '/Search/' + str)
                .then(response => response.json())
                .then(data => {
                    if ((data || {}).error) {
                        console.error(data.error);
                    } else {
                        const msg = JSON.parse(data?.message);

                        if (msg?.error) {
                            console.error(msg.error);
                        } else {
                            const fc = JSON.parse(msg?.message);

                            this.handleNewFeatures(fc?.features);
                        }
                    }
                });
        }

        this.setState({
            address: value
        });
    }

    handleNewFeatures = (features) => {
        if ((features || []).length > 0) {
            this.setState({ features });
        }
    }

    handleSearchChange = (event, value) => {
        if (value) {
            const feature = cloneDeep(value);
            this.setState({
                address: feature?.properties?.label,
                feature: feature
            });
        }
    }

    handleResetAddress = event => {
        this.setState({
            address: null,
            feature: null
        });
    }

    render() {
        const { dictionary } = this.context;
        const { open } = this.props;
        const { address, features } = this.state;

        return (
            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                >
                    <DialogTitle>{dictionary.Inventory.Osoitehaku.Title}</DialogTitle>
                    <DialogContent>
                        <Box>
                            {dictionary.Inventory.Osoitehaku.Body}
                        </Box>
                        <Box style={{
                            display: "flex",
                            padding: 0
                        }}>
                            <Autocomplete
                                onChange={this.handleSearchChange}
                                onInputChange={this.handleSearchTextChange}
                                value={(address || '')}
                                options={features || []}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return (((option || {}).properties || {}).label || '');
                                }}
                                isOptionEqualToValue={(option, value) => option.properties.label === value }
                                style={{ width: 400 }}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            label={dictionary.Inventory.Osoitehaku.Placeholder}
                                            variant="outlined"
                                        />
                                    );
                                }}
                            />
                            <IconButton
                                onClick={this.handleResetAddress}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}