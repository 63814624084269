import React from "react";
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fi';
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const LocalizedDatePicker = (props) => {
    return (
        <Box style={{ width: "100%", marginTop: 10 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi">
                <DatePicker fullWidth {...props} />
            </LocalizationProvider>
        </Box>
    )
}

export default LocalizedDatePicker;