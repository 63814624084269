import React from "react";

const IconPDF = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            version="1.1"
            viewBox="0 0 24 24"
        >
            <g transform="translate(53.548057 -183.975276) scale(1.4843)">
                <path style={{ fill: "#FF2116" }} d="M-32.9,123.9c-0.9,0-1.7,0.7-1.7,1.7v12.8c0,0.9,0.7,1.7,1.7,1.7h9.8c0.9,0,1.7-0.7,1.7-1.7v-10.5c0,0,0-0.3-0.1-0.6c-0.1-0.3-0.3-0.5-0.3-0.5c0,0,0,0,0,0l-2.4-2.4c0,0,0,0,0,0c0,0-0.2-0.2-0.5-0.3c-0.4-0.2-0.7-0.1-0.7-0.1l0,0L-32.9,123.9z"/>
                <path style={{ fill: "#F5F5F5" }} d="M-32.9,124.5h7.4c0,0,0,0,0,0c0,0,0.3,0,0.5,0.1c0.2,0.1,0.4,0.2,0.4,0.2c0,0,0,0,0,0l2.4,2.4c0,0,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.4,0.1,0.4c0,0,0,0,0,0v10.6c0,0.6-0.5,1.1-1.1,1.1h-9.8c-0.6,0-1.1-0.5-1.1-1.1v-12.8C-34,125-33.5,124.5-32.9,124.5L-32.9,124.5z"/>
                <path style={{ fill: "#FF2116" }} d="M-31.3,133.6c-0.4-0.4,0-0.9,1.1-1.4l0.7-0.3l0.3-0.6c0.1-0.3,0.4-0.9,0.5-1.2l0.2-0.6l-0.2-0.4c-0.2-0.5-0.3-1.3-0.1-1.6c0.2-0.4,0.7-0.4,0.9,0.1c0.2,0.3,0.1,0.9,0,1.7l-0.2,0.6l0.1,0.2c0.1,0.1,0.3,0.4,0.5,0.7l0.4,0.5l0.5-0.1c1.5-0.2,2,0.1,2,0.6c0,0.6-1.2,0.6-2.1,0c-0.2-0.2-0.4-0.3-0.4-0.3s-0.6,0.1-0.9,0.2c-0.3,0.1-0.5,0.1-0.9,0.3c0,0-0.2,0.2-0.3,0.4c-0.4,0.6-0.8,1.2-1.2,1.3C-30.7,133.8-31.1,133.8-31.3,133.6z M-30.7,133.4c0.2-0.1,0.6-0.6,0.9-1.1l0.1-0.2l-0.5,0.3c-0.8,0.4-1.2,0.8-1,1.1C-31.1,133.5-31,133.5-30.7,133.4L-30.7,133.4z M-25.2,131.8c0.2-0.1,0.2-0.4-0.1-0.6c-0.2-0.1-0.3-0.1-0.8-0.1c-0.3,0-0.8,0.1-0.8,0.1c0,0,0.3,0.2,0.4,0.2c0.2,0.1,0.5,0.2,0.8,0.3C-25.5,131.9-25.3,131.9-25.2,131.8L-25.2,131.8z M-27.4,130.9c-0.1-0.1-0.3-0.4-0.5-0.6c-0.2-0.2-0.3-0.4-0.3-0.4s-0.1,0.4-0.2,0.7l-0.3,0.8l-0.1,0.2c0,0,0.5-0.2,0.8-0.2c0.3-0.1,0.8-0.2,0.8-0.2L-27.4,130.9z M-28.1,128c0-0.3,0-0.5,0-0.7c-0.2-0.3-0.5,0-0.5,0.6c0,0.2,0.1,0.6,0.1,0.8l0.1,0.4l0.1-0.3C-28.2,128.7-28.2,128.3-28.1,128L-28.1,128z"/>
                <path style={{ fill: "#2C2C2C" }} d="M-30.6,135.3h0.6c0.2,0,0.4,0,0.5,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.1-0.5,0.1h-0.2v1h-0.4L-30.6,135.3z M-30.2,135.6v0.9h0.2c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.3,0L-30.2,135.6z M-28.8,135.3h0.6c0.3,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.5-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0.1-0.4,0.2-0.7,0.2h-0.6V135.3z M-28.4,135.7v1.8h0.2c0.2,0,0.4-0.1,0.5-0.2c0.1-0.2,0.2-0.4,0.2-0.7c0-0.6-0.2-0.9-0.7-0.9H-28.4z M-26.7,135.3h1.4v0.3h-1v0.7h0.8v0.3h-0.8v1.1h-0.4V135.3z"/>
            </g>
        </svg >
    );
};

export default IconPDF;