import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
//import { LanguageContext } from '../LanguageContext';
import {
    VictoryChart,
    VictoryScatter,
    VictoryLine,
    VictoryBar,
    VictoryTheme,
    VictoryLegend,
    VictoryTooltip,
    VictoryAxis,
    VictoryLabel
} from "victory";

/* Näkymäkohtaiset muuttujat ja vakiot */
const formatAxisNumber = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const Graafi = ({ title, barsLegend, linesLegend, data, barSeries, lineSeries, timeScale, width, height }) => {
    //const context = React.useContext(LanguageContext);
    const [bars, setBars] = React.useState([]);
    const [barWidth, setBarWidth] = React.useState(0);
    const [lines, setLines] = React.useState([]);
    const [domain, setDomain] = React.useState([]);
    const [chartWidth, setChartWidth] = React.useState(1000);
    const [chartHeight, setChartHeight] = React.useState(300);
    const [maxBar, setMaxBar] = React.useState(0);
    const [maxLine, setMaxLine] = React.useState(0);

    React.useEffect(() => {
        if ((data || [])[0]) {
            setBars(JSON.parse((data || [])[0][barSeries] || '[]'));
            setLines(JSON.parse((data || [])[0][lineSeries] || '[]'));
        }
    }, [data, barSeries, lineSeries]);

    React.useEffect(() => {
        if (domain.length > 0) {
            const band = domain.length * 2;
            setBarWidth(parseInt(chartWidth / band));
        }
    }, [domain, chartWidth]);

    React.useEffect(() => {
        setChartWidth(width);
    }, [width]);

    React.useEffect(() => {
        setChartHeight(height);
    }, [height]);

    React.useEffect(() => {
        setMaxBar(Math.max(...bars.map(o => o.y)));
    }, [bars]);

    React.useEffect(() => {
        setMaxLine(Math.max(...lines.map(o => o.y)));
    }, [lines]);

    React.useEffect(() => {
        const start = parseInt(timeScale[0])
        const end = parseInt(timeScale[1])
        const d = Array.from({ length: (end - start) + 1 }, (v, i) => start + i);
        setDomain(d);
    }, [timeScale]);

    /*
        Skenaario palkki #800000
        Kulutuksen piste ja viiva #000000
        otsikon tausta  #ddd
    */

    return (
        <Box sx={{ padding: "2px" }} >
            <Box sx={{ maxWidth: "100%", border: "1px solid #ccc" }} >
                <Box
                    sx={{ backgroundColor: "#ddd", color: "#000", padding: "6px" }}
                >
                    <Typography style={{ fontSize: "16px" }}>{title}</Typography>
                </Box>
                <Box
                    sx={{ padding: "6px" }}
                >
                    <VictoryChart
                        animate={false}
                        padding={{ top: 30, bottom: 30, left: 70, right: 70 }}
                        theme={VictoryTheme.material}
                        domainPadding={50}
                        width={chartWidth}
                        height={chartHeight}
                    >
                        <VictoryLegend x={parseInt(chartWidth / 4)} y={5}
                            orientation="horizontal"
                            colorScale={["#800000", "#000"]}
                            data={[
                                { name: barsLegend }, { name: linesLegend }
                            ]}
                        />
                        <VictoryAxis
                            tickValues={domain}
                            fixLabelOverlap
                        />
                        <VictoryAxis
                            dependentAxis
                            orientation="left"
                            label={barsLegend}
                            axisLabelComponent={<VictoryLabel dy={-50} />}
                            tickValues={[0.25, 0.5, 0.75, 1]}
                            tickFormat={(t) => formatAxisNumber(t * maxBar)}
                        />
                        <VictoryAxis
                            dependentAxis
                            orientation="right"
                            label={linesLegend}
                            axisLabelComponent={<VictoryLabel dy={50} />}
                            tickValues={[0.25, 0.5, 0.75, 1]}
                            tickFormat={(t) => formatAxisNumber(t * maxLine)}
                        />
                        {/* Dataset Bars */}
                        <VictoryBar
                            style={{ data: { fill: "#800000" } }}
                            barWidth={barWidth}
                            data={bars}
                            labels={({ datum }) => `Vuosi ${datum.x}\nPäästöt: ${datum.y}`}
                            labelComponent={<VictoryTooltip />}
                            y={(datum) => datum.y / maxBar}
                        />
                        {/* Dataset Lines */}
                        <VictoryLine
                            data={lines}
                            style={{ data: { strokeDasharray: 5 } }}
                            y={(datum) => datum.y / maxLine}
                        />
                        <VictoryScatter
                            style={{ data: { fill: "#000" } }}
                            size={5}
                            data={lines}
                            labels={({ datum }) => `Vuosi ${datum.x}\nKulutus: ${datum.y}`}
                            labelComponent={<VictoryTooltip />}
                            y={(datum) => datum.y / maxLine}
                        />
                    </VictoryChart>
                </Box>
            </Box>
        </Box>
    );
}
