import React from "react";
import PropTypes from 'prop-types';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from "@mui/styles";

import { LanguageContext } from './LanguageContext';

const defaultToolbarStyles = {
    iconButton: {
    },
};

class AdminAsiakasEdit extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        var data = props.Daatta.filter(function (d) {
            return d.riviAvain === props.rowId;
        });

        this.state = {
            open: false,
            RiviAvain: data[0].riviAvain,
            AsiakasNimi: data[0].asiakasNimi,
            TenantId: data[0].tenantId,
            YritysTunnus: data[0].yritysTunnus,
            Created: data[0].created,
            Updated: data[0].updated,
            Username: data[0].username,
            Active: data[0].active
        };

    }

    static propTypes = {
        onEditRow: PropTypes.func,
        classes: PropTypes.object,
        blurOnSelect: PropTypes.bool
    };

    handleSave = () => {
        this.props.onEditRow({
            RiviAvain: this.state.RiviAvain,
            AsiakasNimi: this.state.AsiakasNimi,
            TenantId: this.state.TenantId,
            YritysTunnus: this.state.YritysTunnus,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
            Active: this.state.Active
        });
        this.setState({ open: false });
    }

    closeDialog = (list) => {
        var name = 'open' + list;
        this.setState({ [name]: false })
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { dictionary } = this.context;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.Edit}>
                    <IconButton onClick={this.handleClickOpen}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.AdminAsiakas.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <input type="hidden" value={this.state.RiviAvain} />
                        <TextField
                            margin="dense"
                            id="AsiakasNimi"
                            label={dictionary.AdminAsiakas.Columns[1]}
                            type="text"
                            fullWidth
                            value={this.state.AsiakasNimi}
                            onChange={this.handleChange('AsiakasNimi')}
                        />
                        <TextField
                            margin="dense"
                            id="YritysTunnus"
                            label={dictionary.AdminAsiakas.Columns[4]}
                            type="text"
                            fullWidth
                            value={this.state.YritysTunnus}
                            onChange={this.handleChange('YritysTunnus')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "AdminAsiakasEdit" })(AdminAsiakasEdit);
