import React from 'react';
import { Content, Page, Sidebar, Navi } from './MarkdownViewer'
import { LanguageContext } from './LanguageContext';
import { languageOptions } from '../translations';
import Paper from '@mui/material/Paper';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import { LocalMenu } from './LocalMenu';

import { authFetch, authPost } from "./../authProvider";

/* Näkymäkohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/Markdown/Help';

const headers = (props) => {
    const { level, children } = props;
    const heading = children[0];
    var anchor = (typeof heading === 'string') ? heading.toLowerCase() : '';
    anchor = anchor.replace(/ä/g, 'a');
    anchor = anchor.replace(/ö/g, 'o');
    anchor = anchor.replace(/[^a-zA-Z0-9 ]/g, '');
    anchor = anchor.replace(/ /g, '-');
    return (<div id={anchor}>
        {level == 1 ? (<h1>{children}</h1>) :
            level == 2 ? (<h2>{children}</h2>) :
                level == 3 ? (<h3>{children}</h3>) :
                    (<h4>{children}</h4>)}
    </div>);
}



export class Help extends React.Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        // State alustukset
        this.state = {
            selected: null,
            loading: true,
            structure: null,
            markdown: null,
            showIndex: true,
            lang: this.context?.userLanguage
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData = () => {
        // Query parameters
        const searchParams = new URLSearchParams(document.location.search);

        const path = searchParams.get('path') || this.props?.page;

        authFetch(this.props.pca, API_PREFIX + '/Index/' + (this.context?.userLanguage || "fi"))
            .then(response => response.json())
            .then(data => {
                this.setState({
                    structure: data,
                    markdown: null,
                    loading: false,
                    showIndex: false, //!(path),
                    lang: this.context?.userLanguage
                }, () => {
                    if (path) {
                        this.handleSelect(null, path, this.context?.userLanguage);
                    } else {
                        this.handleSelect(null, 'index.md', this.context?.userLanguage);
                    }
                });
            });
    }

    handleSelect = (event, value, lang) => {
        authPost(this.props.pca, API_PREFIX + '/Read', {
            body: JSON.stringify({ path: value, lang: lang || this.state.lang || 'fi' })
        })
            .then(response => response.text())
            .then(data => {
                this.setState({
                    markdown: data,
                    selected: value
                });
            });
    }

    handleHashLink = (event, value) => {
        authPost(this.props.pca, API_PREFIX + '/Read', {
            body: JSON.stringify({ path: value })
        })
            .then(response => response.text())
            .then(data => {
                this.setState({
                    markdown: data
                });
            });
    }

    render() {
        const { userLanguage, dictionary } = this.context;

        return (
            <React.Fragment>
                <LocalMenu
                    title={(dictionary.Help.Title || "")}
                    onSearchClicked={(d) => alert("Haku " + d)}
                />
                {(this.state.loading) ? (
                    <div style={{ width: "100%", height: "100vh" }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <Page>
                        <Sidebar style={{ padding: 0, background: "#27735C" }}>
                            <Paper elevation={3} style={{ color: "white", background: "#27735C", padding: 20, position: 'relative', overflowY: 'auto', height: '100vh', minHeight: 600 }}>
                                <Navi treeItems={this.state.structure} onSelect={this.handleSelect} Selected={this.state.selected} />
                            </Paper>
                        </Sidebar>
                        <Content style={{ position: 'relative', overflow: "auto", maxHeight: "100vh", minHeight: 600, width: "100%" }}>
                            <ReactMarkdown
                                children={this.state.markdown}
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                                components={{
                                    a: (props) => {
                                        return props.href.startsWith('http') ?
                                            (<a target="_blank" href={props.href}>{props.children}</a>) : // External links
                                            props.href.startsWith('#') ?
                                                (<Link
                                                    component="button"
                                                    variant="subtitle1"
                                                    onClick={() => {
                                                        const el = document.getElementById(props.href.slice(1))
                                                        if (el) el.scrollIntoView();
                                                    }}
                                                >
                                                    {props.children}
                                                </Link>) : // Same page links
                                                (<Link
                                                    component="button"
                                                    variant="subtitle1"
                                                    onClick={() => {
                                                        this.handleSelect(null, props.href);
                                                    }}
                                                >
                                                    {props.children}
                                                </Link>);  // Render internal links with Link component

                                    },
                                    img: (props) => {
                                        const src = (props.src.indexOf('ClientApp/public') > -1 ? props.src.substring(props.src.indexOf('ClientApp/public') + 16) : props.src);
                                        return (
                                            <img src={src} style={{ maxHeight: "100%", maxWidth: "100%" }} alt={props.alt} />
                                        );
                                    },
                                    h1: headers,
                                    h2: headers,
                                    h3: headers,
                                    h4: headers
                                }}
                            />
                        </Content>
                    </Page>
                )
                }
            </React.Fragment>
        );
    }
}
