import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from "@mui/styles";

import { LanguageContext } from './LanguageContext';

const defaultToolbarStyles = {
    iconButton: {
    },
};

class AdminAsiakasAdd extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            RiviAvain: null,
            AsiakasNimi: null,
            TenantId: null,
            Palvelupaketti_Id: 1,
            YritysTunnus: null,
            Created: null,
            Updated: null,
            Username: null,
            Active: null
        };
    }

    handleSave = () => {
        this.props.onAddNewRow({
            RiviAvain: 1,
            AsiakasNimi: this.state.AsiakasNimi,
            TenantId: this.state.TenantId,
            Palvelupaketti_Id: this.state.Palvelupaketti_Id,
            YritysTunnus: this.state.YritysTunnus,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
            Active: this.state.Active
        });
        this.setState({ open: false });
    };

    closeDialog = (list) => {
        var name = 'open' + list;
        this.setState({ [name]: false })
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { dictionary } = this.context;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.NewRow}>
                    <IconButton onClick={this.handleClickOpen}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.AdminAsiakas.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <input type="hidden" value={this.state.RiviAvain} />
                        <TextField
                            margin="dense"
                            id="AsiakasNimi"
                            label={dictionary.AdminAsiakas.Columns[1]}
                            type="text"
                            fullWidth
                            value={this.state.AsiakasNimi}
                            onChange={this.handleChange('AsiakasNimi')}
                        />
                        <TextField
                            margin="dense"
                            id="TenantId"
                            label={dictionary.AdminAsiakas.Columns[2]}
                            type="text"
                            fullWidth
                            value={this.state.TenantId}
                            onChange={this.handleChange('TenantId')}
                        />
                        <TextField
                            margin="dense"
                            id="YritysTunnus"
                            label={dictionary.AdminAsiakas.Columns[4]}
                            type="text"
                            fullWidth
                            value={this.state.YritysTunnus}
                            onChange={this.handleChange('YritysTunnus')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "AdminAsiakasAdd" })(AdminAsiakasAdd);
