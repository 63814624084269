import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from "@mui/styles";

import { LanguageContext } from '../LanguageContext';
import LocalizedDatePicker from "../LocalizedDatePicker";

const defaultToolbarStyles = {
    iconButton: {
    },
};

class TuotantoAdd extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            RiviAvain: null,
            KustannuspaikkaAvain: null,
            TapahtumaAika: null,
            Tuotetyyppi: null,
            Tuotenimi: null,
            Maara: null,
            Yksikko: null,
            Created: null,
            Updated: null,
            Username: null,
            kustannuspaikat: (Object.keys(props.lookupLists.kustannuspaikat).map((d) => ({ value: d, label: props.lookupLists.kustannuspaikat[d] })) || []),
        };
    }

    handleSave = () => {
        this.props.onAddNewRow({
            RiviAvain: 1,
            KustannuspaikkaAvain: this.state.KustannuspaikkaAvain,
            TapahtumaAika: this.state.TapahtumaAika.format("DD/MM/YYYY 00:00:00"),
            Tuotetyyppi: this.state.Tuotetyyppi,
            Tuotenimi: this.state.Tuotenimi,
            Maara: this.state.Maara,
            Yksikko: this.state.Yksikko,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
        });
        this.setState({ open: false });
    };

    closeDialog = (list) => {
        var name = 'open' + list;
        this.setState({ [name]: false })
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleListChange = (event, newValue, item) => {
        if (newValue != null) {
            this.setState({ [item]: newValue.value })
        }
        else {
            this.setState({ [item]: null })
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleChangeDate = (name, value) => {
        this.setState({ [name]: value });
    }

    render() {
        const { dictionary } = this.context;
        const { open, kustannuspaikat } = this.state;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.NewRow}>
                    <IconButton onClick={this.handleClickOpen}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.DataIngestion.Tuotanto.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <Box style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                onChange={(event, newValue) => this.handleListChange(event, newValue, 'KustannuspaikkaAvain')}
                                options={kustannuspaikat}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.DataIngestion.Tuotanto.Columns[2]} />}
                            />
                        </Box>
                        <LocalizedDatePicker
                            label={dictionary.DataIngestion.Tuotanto.Columns[3]}
                            value={this.state.TapahtumaAika}
                            onChange={(newValue) => this.handleChangeDate('TapahtumaAika', newValue)}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tuotanto.Columns[4]}
                            type="text"
                            fullWidth
                            value={this.state.Tuoteryhma || ""}
                            onChange={this.handleChange('Tuotetyyppi')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tuotanto.Columns[5]}
                            type="text"
                            fullWidth
                            value={this.state.Tuotenimi || ""}
                            onChange={this.handleChange('Tuotenimi')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tuotanto.Columns[8]}
                            type="text"
                            fullWidth
                            value={this.state.Maara || ""}
                            onChange={this.handleChange('Maara')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tuotanto.Columns[12]}
                            type="text"
                            fullWidth
                            value={this.state.Yksikko || ""}
                            onChange={this.handleChange('Yksikko')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "TuotantoAdd" })(TuotantoAdd);
