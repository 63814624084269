import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import dayjs from 'dayjs';
import { withStyles } from "@mui/styles";

import { LanguageContext } from '../LanguageContext';
import LocalizedDatePicker from "../LocalizedDatePicker";

const defaultToolbarStyles = {
    iconButton: {
    },
};

var iniKustannuspaikka;
var iniEmo;

class TilitapahtumaEdit extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        const data = props.Daatta.filter(function (d) {
            return d.riviAvain == props.rowId;
        });

        this.state = {
            open: false,
            RiviAvain: data[0].riviAvain,
            EmoTilitapahtumaAvain: data[0].emoTilitapahtumaAvain,
            KustannuspaikkaAvain: data[0].kustannuspaikkaAvain,
            TapahtumaAika: dayjs(data[0].tapahtumaAika),
            Tosite: data[0].tosite,
            Tositelaji: data[0].tositelaji,
            Tilinumero: data[0].tilinumero,
            Tilinimi: data[0].tilinimi,
            Yritys: data[0].yritys,
            Selite: data[0].selite,
            Projekti: data[0].projekti,
            Summa: data[0].summa,
            TapahtumaTyyppi: data[0].tapahtumaTyyppi,
            AlvKoodi: data[0].alvKoodi,
            Created: data[0].created,
            Updated: data[0].updated,
            Username: data[0].username,
            tilitapahtumat: (Object.keys(props.lookupLists.tilitapahtumat).map((d) => ({ value: d, label: props.lookupLists.tilitapahtumat[d] })) || []),
            kustannuspaikat: (Object.keys(props.lookupLists.kustannuspaikat).map((d) => ({ value: d, label: props.lookupLists.kustannuspaikat[d] })) || []),
        };

    }

    UNSAFE_componentWillMount() {
        iniKustannuspaikka = this.state.kustannuspaikat.find(({ value }) => value === (this.state.KustannuspaikkaAvain || '').toString());
        iniEmo = this.state.tilitapahtumat.find(({ value }) => value === (this.state.EmoTilitapahtumaAvain || '').toString());
    }

    handleSave = () => {
        this.props.onEditRow({
            RiviAvain: this.state.RiviAvain,
            EmoTilitapahtumaAvain: this.state.EmoTilitapahtumaAvain,
            KustannuspaikkaAvain: this.state.KustannuspaikkaAvain,
            TapahtumaAika: this.state.TapahtumaAika.format("DD/MM/YYYY 00:00:00"),
            Tosite: this.state.Tosite,
            Tositelaji: this.state.Tositelaji,
            Tilinumero: this.state.Tilinumero,
            Tilinimi: this.state.Tilinimi,
            Yritys: this.state.Yritys,
            Selite: this.state.Selite,
            Projekti: this.state.Projekti,
            Summa: this.state.Summa,
            TapahtumaTyyppi: this.state.TapahtumaTyyppi,
            AlvKoodi: this.state.AlvKoodi,
            Created: this.state.Created,
            Updated: this.state.Updated,
            Username: this.state.Username,
        });
        this.setState({ open: false });
    }

    closeDialog = (list) => {
        var name = 'open' + list;
        this.setState({ [name]: false })
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleListChange = (event, newValue, item) => {
        if (newValue != null) {
            this.setState({ [item]: newValue.value })
        }
        else {
            this.setState({ [item]: null })
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleChangeDate = (name, value) => {
        this.setState({ [name]: value });
    }

    render() {
        const { dictionary } = this.context;
        const { open, tilitapahtumat, kustannuspaikat } = this.state;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.Edit}>
                    <IconButton onClick={this.handleClickOpen}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.DataIngestion.Tilitapahtuma.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <Box style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                defaultValue={iniEmo}
                                onChange={(event, newValue) => this.handleListChange(event, newValue, 'EmoTilitapahtumaAvain')}
                                options={tilitapahtumat}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.DataIngestion.Tilitapahtuma.Columns[1]} />}
                            />
                        </Box>
                        <Box style={{ display: 'flex', paddingTop: "10px" }} >
                            <Autocomplete
                                defaultValue={iniKustannuspaikka}
                                onChange={(event, newValue) => this.handleListChange(event, newValue, 'KustannuspaikkaAvain')}
                                options={kustannuspaikat}
                                style={{ width: 400 }}
                                renderInput={(params) => <TextField  {...params} required={true} variant="standard" label={dictionary.DataIngestion.Tilitapahtuma.Columns[2]} />}
                            />
                        </Box>
                        <LocalizedDatePicker
                            label={dictionary.DataIngestion.Tilitapahtuma.Columns[3]}
                            value={this.state.TapahtumaAika}
                            onChange={(newValue) => this.handleChangeDate('TapahtumaAika', newValue)}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tilitapahtuma.Columns[4]}
                            type="text"
                            fullWidth
                            value={this.state.Tosite}
                            onChange={this.handleChange('Tosite')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tilitapahtuma.Columns[5]}
                            type="text"
                            fullWidth
                            value={this.state.Tositelaji}
                            onChange={this.handleChange('Tositelaji')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tilitapahtuma.Columns[6]}
                            type="text"
                            fullWidth
                            value={this.state.Tilinumero}
                            onChange={this.handleChange('Tilinumero')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tilitapahtuma.Columns[7]}
                            type="text"
                            fullWidth
                            value={this.state.Tilinimi}
                            onChange={this.handleChange('Tilinimi')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tilitapahtuma.Columns[8]}
                            type="text"
                            fullWidth
                            value={this.state.Yritys}
                            onChange={this.handleChange('Yritys')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tilitapahtuma.Columns[9]}
                            type="text"
                            fullWidth
                            value={this.state.Selite}
                            onChange={this.handleChange('Selite')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tilitapahtuma.Columns[10]}
                            type="text"
                            fullWidth
                            value={this.state.Projekti}
                            onChange={this.handleChange('Projekti')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tilitapahtuma.Columns[11]}
                            type="text"
                            fullWidth
                            value={this.state.Summa}
                            onChange={this.handleChange('Summa')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tilitapahtuma.Columns[12]}
                            type="text"
                            fullWidth
                            value={this.state.TapahtumaTyyppi}
                            onChange={this.handleChange('TapahtumaTyyppi')}
                        />
                        <TextField
                            margin="dense"
                            label={dictionary.DataIngestion.Tilitapahtuma.Columns[13]}
                            type="text"
                            fullWidth
                            value={this.state.AlvKoodi}
                            onChange={this.handleChange('AlvKoodi')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "TilitapahtumaEdit" })(TilitapahtumaEdit);
