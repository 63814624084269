import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { withStyles } from "@mui/styles";
import { LanguageContext } from './LanguageContext';

const defaultToolbarStyles = {
    iconButton: {
    },
};

export class AdminProfiiliEdit extends React.Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        var data = props.Daatta.filter(function (d) {
            return d.profiiliId === props.rowId;
        });

        this.state = {
            open: false,
            ProfiiliId: data[0].profiiliId,
            Username: data[0].username,
            UI_Settings: data[0].uI_Settings,
            AsiakasAvain: data[0].asiakasAvain,
            Kieli: data[0].kieli,
            OletusNakyma: data[0].oletusNakyma,
            FullName: data[0].fullName,
            Email: data[0].email,
            AccountType: data[0].accountType
        };

    }

    handleSave = () => {
        this.props.onEditRow({
            ProfiiliId: this.state.ProfiiliId,
            Username: this.state.Username,
            UI_Settings: this.state.UI_Settings,
            AsiakasAvain: this.state.AsiakasAvain,
            Kieli: this.state.Kieli,
            OletusNakyma: this.state.OletusNakyma,
            FullName: this.state.FullName,
            Email: this.state.Email,
            AccountType: this.state.AccountType,
        });
        this.setState({ open: false });
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        const { dictionary } = this.context;

        return (
            <React.Fragment>
                <Tooltip title={dictionary.Toolbar.Edit}>
                    <IconButton onClick={this.handleClickOpen}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <Dialog open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle>{dictionary.AdminProfiili.Title}</DialogTitle>
                    <DialogContent style={{ width: "500px" }}>
                        <TextField
                            required={true}
                            margin="dense"
                            id="Username"
                            label={dictionary.AdminProfiili.Columns[2]}
                            type="text"
                            fullWidth
                            value={this.state.Username || ""}
                            onChange={this.handleChange('Username')}
                        />
                        <TextField
                            margin="dense"
                            id="UI_Settings"
                            label={dictionary.AdminProfiili.Columns[3]}
                            type="text"
                            fullWidth
                            value={this.state.UI_Settings || ""}
                            onChange={this.handleChange('UI_Settings')}
                        />
                        <FormControl fullWidth style={{ marginTop: 8 }}>
                            <InputLabel id="select-label-kieli">{dictionary.AdminProfiili.Columns[4]}</InputLabel>
                            <Select
                                labelId="select-label-kieli"
                                value={this.state.Kieli || ""}
                                label={dictionary.AdminProfiili.Columns[4]}
                                onChange={this.handleChange('Kieli')}
                            >
                                <MenuItem value="fi">fi</MenuItem>
                                <MenuItem value="en">en</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            id="FullName"
                            label={dictionary.AdminProfiili.Columns[6]}
                            type="text"
                            fullWidth
                            value={this.state.FullName || ""}
                            onChange={this.handleChange('FullName')}
                        />
                        <TextField
                            margin="dense"
                            id="Email"
                            label={dictionary.AdminProfiili.Columns[7]}
                            type="text"
                            fullWidth
                            value={this.state.Email || ""}
                            onChange={this.handleChange('Email')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={this.handleClose}>
                            {dictionary.Close}
                        </Button>
                        <Button variant="contained" color="primary" style={{ color: "#fff" }} onClick={this.handleSave}>
                            {dictionary.Save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "AdminProfiiliEdit" })(AdminProfiiliEdit);
