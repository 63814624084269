import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
import cloneDeep from 'lodash.clonedeep';
import { authFetch, authPost } from "../../authProvider";
import { LanguageContext } from '../LanguageContext';
import { VaakaGraafi } from "./VaakaGraafi";
import { PystyGraafi } from "./PystyGraafi";

/* Näkymäkohtaiset muuttujat ja vakiot */
const API_PREFIX = 'api/DataIngestion/PaastoRaportti';
const API_LUOKITTELUT_PREFIX = 'api/DataIngestion/Luokittelu/Arvot';
const API_ORGANISAATIOT_PREFIX = 'api/Inventory/Organisaatio';
const API_PROJEKTIT_PREFIX = 'api/Inventory/Projekti';

//https://stackoverflow.com/questions/2536379/difference-in-months-between-two-dates-in-javascript
const monthDiff = (dateFrom, dateTo) => {
    return dateTo.getMonth() - dateFrom.getMonth() +
        (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

const toLocalISOString = (date) => {
    const tzoffset = (date).getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date(date - tzoffset)).toISOString().slice(0, -1);
}

const dateToYyyymm = (date) => {
    const d = cloneDeep(date);
    return toLocalISOString(date).substr(0, 7);
}

const dateToYyyymmdd = (date) => {
    return toLocalISOString(date).substr(0, 10).replaceAll('-', '');
}

const yyyymmToDate = (yyyymmdd) => {
    const d = yyyymmdd.toString();
    return new Date(d.slice(0, 4), (d[4] + d[5]) - 1, d[6] + d[7]);
}

const dateAddMonths = (date, months) => {
    const d = cloneDeep(date);
    return new Date(d.setMonth(d.getMonth() + months));
}

const FilterGroup = ({ title, items, onChange }) => {
    return (
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel style={{ color: "#800000" }}>{title}</FormLabel>
            <FormGroup>
                {(items || []).map((d, i) => <FormControlLabel key={d.riviAvain} control={<Checkbox onChange={(e) => onChange(d.riviAvain, e.target.checked)} />} label={d.nimi} />)}
            </FormGroup>
        </FormControl>
    );
}

const FilterMonthRange = ({ title, value, minMax, onChange }) => {
    const startDate = dateToYyyymm(value[0]);
    const endDate = dateToYyyymm(value[1]);
    const start = monthDiff(minMax[0], value[0]);
    const end = monthDiff(minMax[0], value[1]);
    const max = monthDiff(minMax[0], minMax[1]);

    const handleSliderChange = (event, newValue) => {
        const s = dateAddMonths(minMax[0], newValue[0]);
        const e = dateAddMonths(minMax[0], newValue[1]);
        onChange([s, e]);
    }

    return (
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" style={{ width: "90%" }}>
            <FormLabel style={{ color: "#800000" }}>{title}</FormLabel>
            <FormGroup>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <FormControl>
                            <Input
                                value={startDate}
                                margin="dense"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl>
                            <Input
                                value={endDate}
                                margin="dense"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={10}>
                        <Slider
                            value={[start, end]}
                            onChange={handleSliderChange}
                            step={1}
                            min={0}
                            max={max}
                        />
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
            </FormGroup>

        </FormControl>
    );
}

const KeyFigures = ({ title, data, valueSeries, labels }) => {
    if (data && labels) {
        const ds1 = JSON.parse(data[valueSeries]) || [];

        if (ds1.length === labels.length) {
            return (
                <FormControl sx={{ m: 3, height: 300 }} component="fieldset" variant="standard">
                    <FormLabel style={{ color: "#800000" }}>{title}</FormLabel>
                    <FormGroup>
                        {(ds1 || []).map((d, i) => (
                            <div key={labels[i].title}>
                                <div key={labels[i].title}>{labels[i].title}</div>
                                <div>
                                    <span style={{ fontSize: 40 }}>{d.y}</span>
                                    <span style={{ fontSize: 12 }}>{labels[i].unit}</span>
                                </div>
                            </div>
                        ))}
                    </FormGroup>
                </FormControl>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
}

/* Luokka */
export class PaastoRaportti extends React.Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);

        // State alustukset
        this.state = {
            projektit: [],
            organisaatiot: [],
            luokittelut: [],
            selectedOrganisaatiot: [],
            selectedLuokittelut: [],
            alkuKk: yyyymmToDate(20230101), //Datasta alku
            loppuKk: yyyymmToDate(20251201), //Datasta loppu
            minKk: yyyymmToDate(20230101), //Datasta alku
            maxKk: yyyymmToDate(20251201), //Datasta loppu
            hankeAvain: null,
            tuotantoYksikko: "Tuote",
            loading: true,
            errorShow: false,
            errorTitle: '',
            errorBody: '',
        };

        let projektit = [], organisaatiot = [], luokittelut = [], hanke = null;

        //projektit
        authFetch(this.props.pca, API_PROJEKTIT_PREFIX + '/Read')
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    projektit = (data || []);
                }
                hanke = projektit[0].riviAvain;

                //organisaatiot
                authFetch(this.props.pca, API_ORGANISAATIOT_PREFIX + '/Read')
                    .then(response => response.json())
                    .then(data => {
                        if ((data || {}).error) {
                            this.handleError(data.error);
                        } else {
                            organisaatiot = (data || []);
                        }

                        //luokittelut
                        authFetch(this.props.pca, API_LUOKITTELUT_PREFIX + '/Read')
                            .then(response => response.json())
                            .then(data => {
                                if ((data || {}).error) {
                                    this.handleError(data.error);
                                } else {
                                    luokittelut = (data || []);
                                }

                                //raportti, init
                                authPost(this.props.pca, API_PREFIX + '/Read', {
                                    body: JSON.stringify({
                                        hankeAvain: hanke,
                                        organisaatiot: null,
                                        kulutusluokat: null,
                                        alku: null,
                                        loppu: null,
                                    })
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        if ((data || {}).error) {
                                            this.handleError(data.error);
                                        } else {
                                            const items = data[0] || {};
                                            const alku = items.alkupvm;
                                            const loppu = items.loppupvm;

                                            this.setState({
                                                hankeAvain: hanke,
                                                projektit: projektit,
                                                organisaatiot: organisaatiot,
                                                luokittelut: luokittelut,
                                                selectedOrganisaatiot: [],
                                                selectedLuokittelut: [],
                                                alkuKk: yyyymmToDate(alku), //Datasta alku
                                                loppuKk: yyyymmToDate(loppu), //Datasta loppu
                                                minKk: yyyymmToDate(alku), //Datasta alku
                                                maxKk: yyyymmToDate(loppu), //Datasta loppu
                                                tuotantoYksikko: items.tuotantoYksikko || "Tuote",
                                                data: items,
                                                loading: false,
                                            });
                                        }
                                    });
                            });
                    });
            });
    };

    handleError = (error) => {
        const { onError } = this.props;

        var title = '', body = ''
        if (error === 4) {
            title = this.context.dictionary.ErrorDialog.Error4.title;
            body = this.context.dictionary.ErrorDialog.Error4.body;
        } else if (error === 5) {
            title = this.context.dictionary.ErrorDialog.Error5.title;
            body = this.context.dictionary.ErrorDialog.Error5.body;
        } else {
            title = this.context.dictionary.ErrorDialog.Error.title;
            body = this.context.dictionary.ErrorDialog.Error.body;
        }

        onError(title, body);
    }

    handleErrorClose = () => this.setState({ errorShow: false });

    handleFilterGroupChange = (name, id, checked) => {
        const { selectedOrganisaatiot, selectedLuokittelut } = this.state;
        let arr = [];

        if (name === "selectedOrganisaatiot") {
            if (checked) {
                arr = selectedOrganisaatiot.concat([id]);
            } else {
                arr = selectedOrganisaatiot.filter(d => d !== id);
            }
        } else {
            if (checked) {
                arr = selectedLuokittelut.concat([id]);
            } else {
                arr = selectedLuokittelut.filter(d => d !== id);
            }
        }

        this.setState({
            [name]: arr
        });
    }

    handleTimeRangeChange = (timescale) => {
        const { alkuKk, loppuKk } = this.state;

        if ((timescale || []).length === 2) {
            this.setState({
                alkuKk: timescale[0] || alkuKk,
                loppuKk: timescale[1] || loppuKk
            });
        }
    }

    handleUpdateData = () => {
        const { selectedOrganisaatiot, selectedLuokittelut, alkuKk, loppuKk, minKk, maxKk, hankeAvain } = this.state;

        //raportti
        authPost(this.props.pca, API_PREFIX + '/Read', {
            body: JSON.stringify({
                hankeAvain: hankeAvain,
                organisaatiot: JSON.stringify(selectedOrganisaatiot),
                kulutusluokat: JSON.stringify(selectedLuokittelut),
                alku: toLocalISOString(alkuKk || minKk).substr(0, 10),
                loppu: toLocalISOString(loppuKk || maxKk).substr(0, 10),
            })
        })
            .then(response => response.json())
            .then(data => {
                if ((data || {}).error) {
                    this.handleError(data.error);
                } else {
                    const items = data[0] || {};
                    const alku = items.alkupvm;
                    const loppu = items.loppupvm;

                    this.setState({
                        alkuKk: yyyymmToDate(alku), //Datasta alku
                        loppuKk: yyyymmToDate(loppu), //Datasta loppu
                        tuotantoYksikko: items.tuotantoYksikko || "Tuote",
                        data: items,
                        loading: false,
                    });
                }
            });
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleMessage = (msg) => {
        const { onMessage } = this.props;
        onMessage(msg);
    }

    render() {
        const { dictionary } = this.context;
        const { data, loading, projektit, organisaatiot, luokittelut,
            alkuKk, loppuKk, minKk, maxKk, tuotantoYksikko, hankeAvain } = this.state;

        //dictionary.<komponentin nimi>.Title
        return (
            <div style={{ padding: 10 }}>
                <div>
                    {(loading) ? (
                        <CircularProgress />
                    ) : (
                        <Grid container spacing={1}>
                            <Grid item xs={3} style={{ paddingTop: 16 }}>
                                <Paper>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            {/* Hankesuodatin */}
                                            <Box style={{ display: "flex", padding: 8 }}>
                                                <Select
                                                    value={hankeAvain}
                                                    fullWidth
                                                    label="Hanke"
                                                    onChange={this.handleChange("hankeAvain")}
                                                    style={{ color: "#000" }}
                                                >
                                                    {(projektit || []).map((d, i) => (<MenuItem key={d.riviAvain} value={d.riviAvain}>{d.nimi}</MenuItem>))}
                                                </Select>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* Organisaatiosuodatin */}
                                            <FilterGroup
                                                title="Organisaatiot"
                                                items={organisaatiot}
                                                onChange={(id, checked) => this.handleFilterGroupChange("selectedOrganisaatiot", id, checked)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* Luokittelusuodatin */}
                                            <FilterGroup
                                                title="Kulutusluokat"
                                                items={luokittelut}
                                                onChange={(id, checked) => this.handleFilterGroupChange("selectedLuokittelut", id, checked)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* Aikasuodatin */}
                                            <FilterMonthRange
                                                title="Aikaväli (vvvv-kk)"
                                                value={[alkuKk, loppuKk]}
                                                minMax={[minKk, maxKk]}
                                                onChange={(timescale) => this.handleTimeRangeChange(timescale)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {/* Päivitys */}
                                            <Box style={{ display: "flex", padding: 8 }}>
                                                <Box style={{ flexGrow: 1 }}></Box>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleUpdateData}
                                                >
                                                    Päivitä
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={9} >
                                <Grid container spacing={1}>
                                    <Grid item xs={9}>
                                        {/* Päästöt ja kulutus kuukausittain */}
                                        <Paper>
                                            <VaakaGraafi
                                                title={"Päästöt ja kulutus kuukausittain"}
                                                barsLegend={"Päästöt (kgCo2)"}
                                                linesLegend={"Kulutus (eur)"}
                                                data={data}
                                                barSeries={"sarja1"}
                                                lineSeries={"sarja2"}
                                                timeScale={[1, 10]}
                                                width={800}
                                                height={300}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={3} >
                                        {/* Avaintunnusluvut */}
                                        <Paper>
                                            <KeyFigures
                                                title={"Avaintunnusluvut"}
                                                data={data}
                                                valueSeries={"sarja3"}
                                                labels={[
                                                    { title: tuotantoYksikko + "määrä yhteensä", unit: "kpl" },
                                                    { title: "Päästöt yhteensä", unit: "tCO2" },
                                                    { title: "Päästöt per käynti", unit: "kgCO2" },
                                                ]}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* Top N Kustannuspaikat päästöjen ja kulutuksen mukaan */}
                                        <Paper>
                                            <PystyGraafi
                                                title={"Kustannuspaikat päästöjen ja kulutuksen mukaan"}
                                                barsLegend={"Päästöt (kgCo2)"}
                                                linesLegend={"Kulutus (eur)"}
                                                data={data}
                                                barSeries={"sarja5"}
                                                lineSeries={"sarja6"}
                                                width={600}
                                                height={500}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6} >
                                        {/* Top N Kulutusluokat päästöjen ja kulutuksen mukaan */}
                                        <Paper>
                                            <PystyGraafi
                                                title={"Kulutusluokat päästöjen ja kulutuksen mukaan"}
                                                barsLegend={"Päästöt (kgCo2)"}
                                                linesLegend={"Kulutus (eur)"}
                                                data={data}
                                                barSeries={"sarja7"}
                                                lineSeries={"sarja8"}
                                                width={600}
                                                height={500}
                                            />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    }
                </div>
            </div>
        );
    }
}