import * as React from 'react';
import { useMsal } from "@azure/msal-react";
import { LanguageContext } from './LanguageContext';
import { useLocation, useNavigate } from "react-router-dom";
import { LocalMenu } from './LocalMenu';
import { MarkdownPage } from "./MarkdownPage";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export const ServiceInfo = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();
    const context = React.useContext(LanguageContext);
    const location = useLocation();
    const { item } = location.state;
    const page = "/ads/" + item.name.replace(/[\u{0080}-\u{FFFF}\r\n]/gu, "") + ".md"

    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        navigate(-1);
    };

    return (
        <div style={{ display: "block", width: "100%", minHeight: 600 }}>
            <LocalMenu
                title={""}
                menuItems={false}
                selectedId={false}
                onSelectChange={false}
                onSearchClicked={false}
            />
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}

                >
                    <Box style={{ padding: 16 }}>
                        <MarkdownPage page={page} pca={instance} />
                        <DialogActions>
                            <Box
                                sx={{ display: "flex" }}
                            >
                                <Box sx={{ paddingTop: "5px", flexGrow: 1 }}>
                                </Box>
                                <Box>
                                    <Button color="primary" variant="outlined" onClick={handleClose}>{context.dictionary.ServiceInfo.Close}</Button>
                                </Box>
                                <Box style={{ marginLeft: "8px" }}>
                                    <Button color="primary" variant="contained" style={{ color: "#fff" }} target="_blank" href={context.dictionary.ServiceInfo.ContactLink}>{context.dictionary.ServiceInfo.Contact}</Button>
                                </Box>
                            </Box>
                        </DialogActions>
                    </Box>
                </Dialog>
            </div>
        </div>
    );
}
